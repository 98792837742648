import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import * as React from 'react';
import { getFilters, getQueueCounter } from '../../../services/api-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';

import { Badge, Box, Chip, Stack, Grid } from '@mui/material';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import '../QueueCounters/QueueCounters.css';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import { mapRowDataWithThreshold, mapRowDataWithThresholdNew, secondsToMinSec } from '../../../utils/util';

let interval: any;

const thresholdFields = [
	{ label: 'Longest wait time', value: '', key: 'LongestWaitTime', condition: 'GREATER_THAN' },
	{ label: 'Calls Waiting', value: '', key: 'QueueCount', condition: 'GREATER_THAN' }
];

export default function QueueCounters(props: any) {
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>([]);
	const [queueCounterFilter, setQueueCounterFilter] = useState<Array<any>>(props?.selectedFilters?.filters || []);
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const downloadContent = useRef<null | any>(null);

	const retrieveTableData = async (filters = '0') => {
		const columns = [
			{ name: 'Skills', key: 'SkillName' },
			{ name: 'Calls waiting', key: 'QueueCount' },
			{ name: 'Agents available', key: 'AgentsAvailable' },
			{ name: 'Longest wait time', key: 'LongestWaitTime', type: 'MIN_SEC' }
		];
		const rows: any = await getQueueCounter({ filters, type: filterType, ChannelType: '1' });
		// const updatedRowData = mapRowDataWithThreshold(thresholdItems, rows);
		const updatedRowData = mapRowDataWithThresholdNew(thresholdItems, rows);
		setRowData(updatedRowData);
		setColumnData(columns);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = queueCounterFilter.filter((item: any) => item.id !== id);
		setQueueCounterFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setQueueCounterFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};
	
	const handleFilterExistingData = (filteredData: any) => {
		setQueueCounterFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setQueueCounterFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const filterItems = queueCounterFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			setThresholdItems(data);
		}
	};

	const updateThresholdItems = async () => {
		let newThresholdItems = [];
		const response: any = await getFilters('Skill');
		newThresholdItems.push({ label: 'Organization Level', fields: thresholdFields, isOrgSkill: true });
		for (const row of response) {
			if (row.name !== 'All') {
				newThresholdItems.push({ label: row.Name, key: 'SkillName', value: row.Name, fields: thresholdFields });
			}
		}
		setThresholdItems(newThresholdItems);
	};

	React.useEffect(() => {
		updateThresholdItems();
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		// const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		const filterIds = (queueCounterFilter.length && !(responseLength === queueCounterFilter.length)) ? queueCounterFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.QUEUE_COUNTER);
	}, [queueCounterFilter, thresholdItems]);


	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				LongestWaitTime: secondsToMinSec(item?.LongestWaitTime),
			};
			return rowObj;
		});
		return row;
	}

	return (
		<Card className="customCard">
			<div className="card-header">
				<div className="card-title">
					Queue Counter <div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={getRowData(rowData)} columns={columnData} name={'Queue Counter'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={queueCounterFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
					<div onClick={() => toggleThresholdDrawer(true)}>
						<WidgetGraph></WidgetGraph>
					</div>
				</div>
			</div>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign', 'Skill']} existingSelectedFilters={queueCounterFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
			<Box sx={{ width: '100%', typography: 'body1' }}>
				{queueCounterFilter.length > 0 && (
					<Box className='customTabPanel'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className="filter-outer">
									<div className="filter-container">
										<Stack direction="row" spacing={1}>
											{filterItems}
										</Stack>
									</div>
									<div className="filter-remove" onClick={clearFilter}>
										Remove
									</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				)}
				<OZTable columns={columnData} rows={rowData} maxHeight={360} downloadContent={downloadContent}></OZTable>
			</Box>
			<ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Queue Counter'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} />
		</Card>
	);
}
