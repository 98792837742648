import { Card, Chip, Grid, ToggleButton, ToggleButtonGroup, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState, useMemo } from 'react';
import ReportsPageHead from '../../../components/ReportsPageHead/ReportsPageHead';
// import ThresholdDrawer from '../../../components/ThresholdDrawer/ThresholdDrawer';
import { getPerformanceMetricsData, getSystemAnalyticsFilters } from '../../../services/api-service';
// import HistoricReportsFilterDrawer from '../../../pages/reports/HistoricReportsFilterDrawer/HistoricReportsFilterDrawer';
import { OZContext } from '../../../contexts/OZContext';
import React from 'react';
// import FilterItems from '../../../components/FilterItems/FilterItems';
// import { createHistoricFilterIds } from '../../../utils/util';
import WidgetDownload from '../../../components/Widget-Download/Widget-Download';
import CardContent from './CardContent';
import { WIDGET_REFRESH_INTERVAL, colorDataForBackground, colorDataForPlot, historicDummyData, historicDummyDataDigital } from '../../../utils/constants';
import SelectPopover from '../../SelectPopover/SelectPopover';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { getLastFetchedDetail } from '../../../services/application-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const useStyles: any = makeStyles(() => ({
	cardTile: { border: '1px solid #E6E5E6 !important', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important', borderRadius: '12px !important' },
	textCard: {
		position: 'relative',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	},
	textCardTitle: {
		fontWeight: '400 !important',
		fontSize: '14px !important',
		color: '#99A0A8 !important'
	},
	textCardSubtitle: {
		fontWeight: '500 !important',
		fontSize: '22px !important',
		color: '#212121 !important'
	},
	legendWrapper: {
		// width: "100%",
		// height: "45px",
		padding: '0 15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginLeft: '500px'
	},
	legend: {
		minWidth: '6rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	legendIcon: {
		margin: '0.5rem 1rem !important'
	},
	legendIconLine: {
		display: 'inline-block',
		minWidth: '27px',
		height: '0px',
		borderTop: '3px solid #008DFF',
		content: "''"
	},
	legendIconDottedLine: {
		width: '27px',
		height: '0px',
		borderTop: '1px dashed #008DFF'
	},
	legendText: {
		whiteSpace: 'nowrap'
	}
}));


const columnTableData = [
	{ label: 'Avg. Talk Time', key: 'AvgTalkTime', id: 'AvgTalkTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Hold Time', key: 'AvgHoldTime', id: 'AvgHoldTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Wait Time', key: 'AvgWaitTime', id: 'AvgWaitTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Wrap Time', key: 'AvgWrapupTime', id: 'AvgWrapupTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Handle Time', key: 'AvgHandlingTime', id: 'AvgHandlingTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Speed to Answer', key: 'AvgSpeedToAnswer', id: 'AvgSpeedToAnswer', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]}, //discuss
	{ label: 'Call Connection Ratio', key: 'CallConnectionRatio', id: 'CallConnectionRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Wrap Time Exceeded', key: 'WrapTimeExceeded', id: 'WrapTimeExceeded', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},//discuss
	{ label: 'Abandoned', key: 'AbandonedCalls', id: 'AbandonedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Abandoned %', key: 'CallAbandonedRatio', id: 'CallAbandonedRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Agent Disconnect Calls', key: 'AgentDisconnects', id: 'AgentDisconnects', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Agent Disconnect %', key: 'AgentDisconnectsRatio', id: 'AgentDisconnectsRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Total Calls', key: 'TotalCalls', id: 'TotalCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'SLA', key: 'SLA', id: 'SLA', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	// { label: 'Avg. Speed Answer', key: 'avgSpeedAnswer', id: 'avgSpeedAnswer', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]}, 
	{ label: 'Calls/Agents', key: 'CallPerAgents', id: 'CallPerAgents', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},

]

const tTableData = [
	{ label: 'Avg. Talk Time', key: 'AvgTalkTime', id: 'AvgTalkTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Hold Time', key: 'AvgHoldTime', id: 'AvgHoldTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Wait Time', key: 'AvgWaitTime', id: 'AvgWaitTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Wrap Time', key: 'AvgWrapupTime', id: 'AvgWrapupTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Handle Time', key: 'AvgHandlingTime', id: 'AvgHandlingTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Avg. Speed to Answer', key: 'AvgSpeedToAnswer', id: 'AvgSpeedToAnswer', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]}, //discuss
	{ label: 'Call Connection Ratio', key: 'CallConnectionRatio', id: 'CallConnectionRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Wrap Time Exceeded', key: 'WrapTimeExceeded', id: 'WrapTimeExceeded', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},//discuss
	{ label: 'Abandoned', key: 'AbandonedCalls', id: 'AbandonedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Abandoned %', key: 'CallAbandonedRatio', id: 'CallAbandonedRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Agent Disconnect Calls', key: 'AgentDisconnects', id: 'AgentDisconnects', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Agent Disconnect %', key: 'AgentDisconnectsRatio', id: 'AgentDisconnectsRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'Total Calls', key: 'TotalCalls', id: 'TotalCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	{ label: 'SLA', key: 'SLA', id: 'SLA', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
	// { label: 'Avg. Speed Answer', key: 'avgSpeedAnswer', id: 'avgSpeedAnswer', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]}, 
	{ label: 'Calls/Agents', key: 'CallPerAgents', id: 'CallPerAgents', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},

]

const columnTableDataDigital = [
    { label: 'Chats Per Agent', key: 'CallPerAgents', id: 'CallPerAgents', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Abandoned', key: 'AbandonedCalls', id: 'AbandonedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Abandoned %', key: 'CallAbandonedRatio', id: 'CallAbandonedRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Avg. Chat Time', key: 'AvgTalkTime', id: 'AvgTalkTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Chats In Queue', key: 'TotalQueuedCalls' , id: 'TotalQueuedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
]

const tTableDataDigital = [
    { label: 'Chats Per Agent', key: 'CallPerAgents', id: 'CallPerAgents', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Abandoned', key: 'AbandonedCalls', id: 'AbandonedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Abandoned %', key: 'CallAbandonedRatio', id: 'CallAbandonedRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Avg. Chat Time', key: 'AvgTalkTime', id: 'AvgTalkTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
    { label: 'Chats In Queue', key: 'TotalQueuedCalls' , id: 'TotalQueuedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]},
]


let interval : any ;

const PerformanceMetrics = (props: any) => {
	const classes = useStyles();
	const donutChartElement: any = useRef(null);
    const chartElement: any = useRef(null);
	const downloadContent = useRef<null | HTMLDivElement>(null);
	const [selectedFilters, setSelectedFilters] = useState<any>(props?.selectedFilters?.filters || []);
    const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
    const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
    const [responseLength, setresponseLength] = useState<Number>(0);
	// const [duration, setDuration] = useState<any>();
	const [filters, setFilters] = useState<any>([]);
	const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean | undefined>(false);
	const { setIsLoaderOpen } = React.useContext(OZContext);
    const headerSection = props?.selectedFilters?.headerSection;
	const [tempRowData, setTempRowData] = useState<any>((headerSection === 'call' && props?.selectedFilters?.filterHeaders) || tTableData);
	const [tempRowDataDigi, setTempRowDataDigi] = useState<any>((headerSection != 'call' && props?.selectedFilters?.filterHeaders)|| tTableDataDigital);
    const [fullGraphData, setFullGraphData] = useState<any>([]);
    const [fullGraphDataDigi, setFullGraphDataDigi] = useState<any>([]);

    const [tabValue, settabValue] = useState<any>(headerSection || 'call');
    let   [dynaGraphData, setDynaGraphData] = useState<any>([]);

    // const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = useState<boolean>(false);
	// const [columnsEleRefPoint, setColumnsElRefPoint] = useState<any>(null);


    const [tempColumnData, setTempColumnData] = useState<any>(columnTableData);
	const [tempColumnDataDigi, setTempColumnDataDigi] = useState<any>(columnTableDataDigital);

    const [columnData, setColumnData] = useState<any>((headerSection === 'call' && props?.selectedFilters?.filterHeaders) || columnTableData);
	const [columnDataDigi, setColumnDataDigi] = useState<any>((headerSection != 'call' && props?.selectedFilters?.filterHeaders) || columnTableDataDigital);
    
    const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');

    const stateRef = useRef<any>(null);

    function convertSecondsToCustomFormat(seconds: any) {
        const totalSeconds = Math.round(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
    
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
        return `${formattedMinutes}m ${formattedSeconds}s`;
    }

    const graphItemStyle = useMemo(
        () => ({
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: '#212121',
            opacity: '0.4'
        }),
        []
    );

    const graphStyle = useMemo(
        () => ({
            height: '40px',
            border: '1px dashed #008DFF',
        }),
        []
    );

    const handletabValueChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue === null || newValue === undefined) return;
        settabValue(newValue);
        setSelectedFilters([]);
        props?.handleFilterChange?.([], filterSection, props?.widgetId, tabValue === 'call' ? columnData : columnDataDigi, tabValue);
        setDynaGraphData([]);
        stateRef.current = null;
    }

    const lineChartOptions = {
		chart: {
			type: 'line',
            height: 200,
            width: 1000,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: '',
			},
            visible: false,
            crosshair: true,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
				'09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
				'18:00','19:00','20:00','21:00','22:00','23:00'
			],
			plotLines: [
				{
					color: '#D42015',
				}
			]
		},
		yAxis: {
			title: {
				text: ''
			},
            visible: false,
		},
		// tooltip: {
		// 	formatter(this: any): any {
		// 		return `Time: ${this.x}<br/>Value: ${this.y}`;
		// 	}
		// },
        tooltip: {
            formatter(this: any) : any {
                return `${this.series.name === 'Avg. Talk Time' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Avg. Hold Time' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Avg. Wait Time' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Avg. Wrap Time' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Avg. Handle Time' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Avg. Speed to Answer' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Call Connection Ratio' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        this.series.name === 'Abandoned' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        this.series.name === 'Abandoned %' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        this.series.name === 'Agent Disconnect Calls' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        this.series.name === 'Agent Disconnect %' ? `Hour: ${this.x}<br/>Value: ${this.y}%`:
                        this.series.name === 'Total Calls' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        this.series.name === 'SLA' ? `Hour: ${this.x}<br/>Value: ${this.y}%` :
                        this.series.name === 'Calls/Agents' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        this.series.name === 'Chats Per Agent' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        this.series.name === 'Avg. Chat Time' ? `Hour: ${this.x}<br/>Value: ${convertSecondsToCustomFormat(this.y || 0)}` :
                        this.series.name === 'Chats In Queue' ? `Hour: ${this.x}<br/>Value: ${this.y}` :
                        `Hour: ${this.x}<br/>Value: ${this.y}`}`
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 10,
            symbol: 'circle',
            symbolRadius: 5,
            symbolWidth: 8,
            symbolHeight: 8,
            useHTML: true,
            itemStyle: {
                // color: '#000'
            },
            itemHoverStyle: {
                // color: '#000'
            },
        },
        plotOptions: {
            series: {
                // color:`${nowCardClicked === '' ? '#006ABF' : colorDataForBackground[nowCardClicked]}`,
                // color:`${nowCardClicked === '' ? '#006ABF' : colorDataForPlot[nowCardClicked]}`,
                cursor: 'pointer',
                lineWidth: 1.5,
                marker:{
                    enabled : true,
                },
            }
        },
		series: dynaGraphData,
    };

    const toggleRefresh = () => {
		const filterIds = (selectedFilters.length && !(responseLength === selectedFilters.length)) ? selectedFilters.map((item: any) => item.id).join(',') : '0';
        const currentState = stateRef.current;
		retrieveGraphData(filterIds, currentState);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

    const handleOnSelectColumnChange = (selected: any) => {

		let selectedIds: string[] = []
		let orderedSelectedCall = [];
        let orderedSelectedDigi = [];

		selected.map((selected: any) => {
			selectedIds.push(selected.id)
		})

        if(tabValue === 'call'){
            if (selectedIds.includes("AvgTalkTime")) {
                orderedSelectedCall.push({ label: 'Avg. Talk Time', key: 'AvgTalkTime', icon: 'AvgTalkTime', id: 'AvgTalkTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AvgHoldTime")) {
                orderedSelectedCall.push({ label: 'Avg. Hold Time', key: 'AvgHoldTime' , icon: 'AvgHoldTime', id: 'AvgHoldTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AvgWaitTime")) {
                orderedSelectedCall.push({ label: 'Avg. Wait Time', key: 'AvgWaitTime', icon: 'AvgWaitTime', id: 'AvgWaitTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AvgWrapupTime")) {
                orderedSelectedCall.push({ label: 'Avg. Wrap Time', key: 'AvgWrapupTime', icon: 'AvgWrapupTime', id: 'AvgWrapupTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AvgHandlingTime")) {
                orderedSelectedCall.push({ label: 'Avg. Handle Time', key: 'AvgHandlingTime', icon: 'AvgHandlingTime', id: 'AvgHandlingTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AvgSpeedToAnswer")) {
                orderedSelectedCall.push({ label: 'Avg. Speed to Answer', key: 'AvgSpeedToAnswer', icon: 'AvgSpeedToAnswer', id: 'AvgSpeedToAnswer', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("CallConnectionRatio")) {
                orderedSelectedCall.push({ label: 'Call Connection Ratio', key: 'CallConnectionRatio', icon: 'CallConnectionRatio', id: 'CallConnectionRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("WrapTimeExceeded")) {
                orderedSelectedCall.push({ label: 'Wrap Time Exceeded', key: 'WrapTimeExceeded', icon: 'WrapTimeExceeded', id: 'WrapTimeExceeded', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AbandonedCalls")) {
                orderedSelectedCall.push({ label: 'Abandoned', key: 'AbandonedCalls', icon: 'AbandonedCalls', id: 'AbandonedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("CallAbandonedRatio")) {
                orderedSelectedCall.push({ label: 'Abandoned %', key: 'CallAbandonedRatio', icon: 'CallAbandonedRatio', id: 'CallAbandonedRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AgentDisconnects")) {
                orderedSelectedCall.push({ label: 'Agent Disconnect Calls', key: 'AgentDisconnects', icon: 'AgentDisconnects', id: 'AgentDisconnects', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AgentDisconnectsRatio")) {
                orderedSelectedCall.push({ label: 'Agent Disconnect %', key: 'AgentDisconnectsRatio', icon: 'AgentDisconnectsRatio', id: 'AgentDisconnectsRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("TotalCalls")) {
                orderedSelectedCall.push({ label: 'Total Calls', key: 'TotalCalls', icon: 'TotalCalls', id: 'TotalCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("SLA")) {
                orderedSelectedCall.push({ label: 'SLA', key: 'SLA', icon: 'SLA', id: 'SLA', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            // if (selectedIds.includes("avgSpeedAnswer")) {
            //     orderedSelectedCall.push({ label: 'Avg. Speed Answer', key: 'avgSpeedAnswer', id: 'avgSpeedAnswer', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            // }
            if (selectedIds.includes("CallPerAgents")) {
                orderedSelectedCall.push({ label: 'Calls/Agents', icon: 'CallPerAgents', key: 'CallPerAgents', id: 'CallPerAgents', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            setTempRowData(orderedSelectedCall);
            setTempColumnData(orderedSelectedCall);
            setColumnData(orderedSelectedCall);
		    props?.handleFilterChange?.(selectedFilters, filterSection, props?.widgetId, orderedSelectedCall, tabValue);

        }else if(tabValue === 'digital'){
            if (selectedIds.includes("CallPerAgents")) {
                orderedSelectedDigi.push({ label: 'Chats Per Agent', key: 'CallPerAgents', icon: 'CallPerAgents', id: 'CallPerAgents', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AbandonedCalls")) {
                orderedSelectedDigi.push({ label: 'Abandoned', key: 'AbandonedCalls', icon: 'AbandonedCalls', id: 'AbandonedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("CallAbandonedRatio")) {
                orderedSelectedDigi.push({ label: 'Abandoned %', key: 'CallAbandonedRatio', icon: 'CallAbandonedRatio', id: 'CallAbandonedRatio', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("AvgTalkTime")) {
                orderedSelectedDigi.push({ label: 'Avg. Chat Time', key: 'AvgTalkTime', icon: 'AvgTalkTime', id: 'AvgTalkTime', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            if (selectedIds.includes("TotalQueuedCalls")) {
                orderedSelectedDigi.push({ label: 'Chats In Queue', key: 'TotalQueuedCalls', icon: 'TotalQueuedCalls', id: 'TotalQueuedCalls', graphData: [1, 2, 3, 4, 5, 6, 7, 8, 9]})
            }
            setTempRowDataDigi(orderedSelectedDigi);
            setTempColumnDataDigi(orderedSelectedDigi);
            setColumnDataDigi(orderedSelectedDigi);
		    props?.handleFilterChange?.(selectedFilters, filterSection, props?.widgetId, orderedSelectedDigi, tabValue);
        }

        const filterIds = (selectedFilters.length && !(responseLength === selectedFilters.length)) ? selectedFilters.map((item: any) => item.id).join(',') : '0';
        const currentState = stateRef.current;
        retrieveGraphData(filterIds, currentState)
	};

    const filterItems = selectedFilters.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveGraphData = async (filters: any, clicked: any) => {
		// if (!duration?.fromTime) return;
		// setIsLoaderOpen(true);
		let tempResponseCall : any = [];
		let tempResponseDigital : any = [];
        let tempRRR : any = [];
        let tempDDD : any = [];
        let dummyApiResponse : any = [];
        let dummyApiResponseDigital : any = [];
        let apiResponseCall : any = [];
        let apiResponseDigi : any = [];
        // let apiResponse: any = [];

        // let apiResponse: any = newDumm;

        let apiResponse: any = await getPerformanceMetricsData({callType : tabValue, type: filterType, filters }); // this is only actual written when data is not coming
        // if(tabValue === 'call'){
		//     apiResponse = await getPerformanceMetricsData({callType : tabValue});
        // }else if(tabValue === 'digital'){
        //     apiResponse = historicOutboundDummyData;
        // }


        function getColor(value: any, label: any) {
            const clickedItem = clicked?.find((item: any) => item?.key === value && item?.label === label);
            if (clickedItem) {
                const updatedItem = { ...clickedItem };

                if(updatedItem?.color === undefined){
                    updatedItem.color = '#ffff';
                }else if(updatedItem?.color === '#ffff'){
                    updatedItem.color = '#ffff';
                }else if(updatedItem?.color !== '#ffff'){
                    updatedItem.color = colorDataForBackground[value];
                }

                return updatedItem.color;
            }
            return '#ffff';
        }


        if (apiResponse.length > 0) {
            if (tabValue === 'call') {
                apiResponseCall = apiResponse;
            } else if (tabValue === 'digital') {
                apiResponseDigi = apiResponse;
            }
        }else{
            if (tabValue === 'call') {
                dummyApiResponse = historicDummyData;
            } else if (tabValue === 'digital') {
                dummyApiResponseDigital = historicDummyDataDigital;
            }
            // dummyApiResponse = historicDummyData;
        }

        if(apiResponse.length > 0 ){
            if(tabValue === 'call'){
                tempRRR = apiResponseCall?.map((data: any) => {
                    const dailyValuesRows = JSON.parse(data?.DailyValues)?.Rows;
                    const dailyValuesHeaders = JSON.parse(data?.DailyValues)?.Headers;

                    const hourlyValuesRows = JSON.parse(data?.HourlyValues)?.Rows;
                    const hourlyValuesHeaders = JSON.parse(data?.HourlyValues)?.Headers;
                
                    const dailyValues = dailyValuesRows?.map((row: any) => {
                        const rowData: any = {};
                        dailyValuesHeaders?.forEach((header: any, index: any) => {
                            if (header === "AvgTalkTime" || header === "AvgHoldTime" || header === "AvgWaitTime"|| header === "AvgWrapupTime" || 
                                header === "AvgHandlingTime" || header === "AvgSpeedToAnswer" || header === "avgChatTime" ) {
                                rowData[header] = convertSecondsToCustomFormat(row[index]);
                            } else {
                                rowData[header] = row[index];
                            }
                        });
                        return rowData;
                    });

                    const structuredData = hourlyValuesRows?.map((row: any) => {
                        let rowData : any = {};
                        hourlyValuesHeaders?.forEach((header: any, index: any) => {
                        rowData[header] = row[index];
                        });
                        return rowData;
                    });


                    let newArray = structuredData.reduce((acc: any, obj: any) => {
                        Object.keys(obj).forEach((key: any) => {
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(obj[key]);
                        });
                        return acc;
                    }, {});

                    const newDataFirst: any = {};
                    for (const key in newArray) {
                        if (newArray.hasOwnProperty(key)) {
                            newDataFirst[key] = newArray[key].map((value: any) => (value !== undefined && value !== null) ? value : 0);
                        }
                    }
                    // Find the maximum index in the "CallHour" array, for trial purpose
                    const maxIndex = Math.max(...newDataFirst.CallHour);

                    const newData : any = {};
                    for (const key in newDataFirst) {
                        if (newDataFirst.hasOwnProperty(key) && key !== "CallHour") {
                            newData[key] = new Array(maxIndex + 1).fill(0);
                        }
                    }

                    for (const key in newDataFirst) {
                        if (newDataFirst.hasOwnProperty(key) && key !== "CallHour") {
                            newDataFirst[key].forEach((value: any, index: any) => {
                                const newIndex = newDataFirst.CallHour[index];
                                newData[key][newIndex] = value !== null ? value : 0;
                            });
                        }
                    }

                    return {
                        "SNO": data?.SNO,
                        "HourlyValues": JSON.parse(data?.HourlyValues),
                        "DailyValues": dailyValues,
                        // "newArray": newArray,
                        "newArray": newData,
                    };
                });
                setFullGraphData(tempRRR[0]?.newArray);
            }else if(tabValue === 'digital'){
                tempDDD = apiResponseDigi.map((data: any) => {
                    const dailyValuesRows = JSON.parse(data?.DailyValues)?.Rows;
                    const dailyValuesHeaders = JSON.parse(data?.DailyValues)?.Headers;
    
                    const hourlyValuesRows = JSON.parse(data?.HourlyValues)?.Rows;
                    const hourlyValuesHeaders = JSON.parse(data?.HourlyValues)?.Headers;

                    const dailyValues = dailyValuesRows.map((row: any) => {
                        const rowData: any = {};
                        dailyValuesHeaders.forEach((header: any, index: any) => {
                            if(header === "AvgTalkTime" || header === "AvgHoldTime" || header === "AvgWaitTime"|| header === "AvgWrapupTime" || 
                                header === "AvgHandlingTime" || header === "AvgSpeedToAnswer" || header === "avgChatTime" ){
                                rowData[header] = convertSecondsToCustomFormat(row[index]);
                            } else {
                                rowData[header] = row[index];
                            }
                        });
                        return rowData;
                    });
    
                    const structuredData = hourlyValuesRows.map((row: any) => {
                        let rowData : any = {};
                        hourlyValuesHeaders.forEach((header: any, index: any) => {
                        rowData[header] = row[index];
                        });
                        return rowData;
                    });
    
    
                    const newArray = structuredData.reduce((acc: any, obj: any) => {
                        Object.keys(obj).forEach((key: any) => {
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(obj[key]);
                        });
                        return acc;
                    }, {});

                    const newDataFirst: any = {};
                    for (const key in newArray) {
                        if (newArray.hasOwnProperty(key)) {
                            newDataFirst[key] = newArray[key].map((value: any) => (value !== undefined && value !== null) ? value : 0);
                        }
                    }

                    // Find the maximum index in the "CallHour" array, for trial purpose
                    const maxIndex = Math.max(...newDataFirst.CallHour);

                    const newData : any = {};
                    for (const key in newDataFirst) {
                        if (newDataFirst.hasOwnProperty(key) && key !== "CallHour") {
                            newData[key] = new Array(maxIndex + 1).fill(0);
                        }
                    }

                    for (const key in newDataFirst) {
                        if (newDataFirst.hasOwnProperty(key) && key !== "CallHour") {
                            newDataFirst[key].forEach((value: any, index: any) => {
                                const newIndex = newDataFirst.CallHour[index];
                                newData[key][newIndex] = value !== null ? value : 0;
                            });
                        }
                    }
    
                
                    return {
                        "SNO": data?.SNO,
                        "HourlyValues": JSON.parse(data?.HourlyValues),
                        "DailyValues": dailyValues,
                        // "newArray": newArray,
                        "newArray": newData,
                    };
                });
                setFullGraphDataDigi(tempDDD[0]?.newArray);
            }
        }else{
            if(tabValue === 'call'){
                tempRRR = dummyApiResponse.map((data: any) => {
                    const dailyValuesRows = JSON.parse(data?.DailyValues)?.Rows;
                    const dailyValuesHeaders = JSON.parse(data?.DailyValues)?.Headers;
        
                    const hourlyValuesRows = JSON.parse(data?.HourlyValues)?.Rows;
                    const hourlyValuesHeaders = JSON.parse(data?.HourlyValues)?.Headers;
                
                    const dailyValues = dailyValuesRows.map((row: any) => {
                        const rowData: any = {};
                        dailyValuesHeaders.forEach((header: any, index: any) => {
                            if (header === "AvgTalkTime" || header === "AvgHoldTime" || header === "AvgWaitTime"|| header === "AvgWrapupTime" || 
                            header === "AvgHandlingTime" || header === "AvgSpeedToAnswer" || header === "avgChatTime" ) {
                                rowData[header] = convertSecondsToCustomFormat(row[index]);
                            } else {
                                rowData[header] = row[index];
                            }
                        });
                        return rowData;
                    });
        
                    const structuredData = hourlyValuesRows.map((row: any) => {
                        let rowData : any = {};
                        hourlyValuesHeaders.forEach((header: any, index: any) => {
                        rowData[header] = row[index];
                        });
                        return rowData;
                    });
        
        
                    const newArray = structuredData.reduce((acc: any, obj: any) => {
                        Object.keys(obj).forEach((key: any) => {
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(obj[key]);
                        });
                        return acc;
                    }, {});

                    const newDataFirst: any = {};
                    for (const key in newArray) {
                        if (newArray.hasOwnProperty(key)) {
                            newDataFirst[key] = newArray[key].map((value: any) => (value !== undefined && value !== null) ? value : 0);
                        }
                    }

                    // Find the maximum index in the "CallHour" array, for trial purpose
                    const maxIndex = Math.max(...newDataFirst.CallHour);

                    const newData : any = {};
                    for (const key in newDataFirst) {
                        if (newDataFirst.hasOwnProperty(key) && key !== "CallHour") {
                            newData[key] = new Array(maxIndex + 1).fill(0);
                        }
                    }

                    for (const key in newDataFirst) {
                        if (newDataFirst.hasOwnProperty(key) && key !== "CallHour") {
                            newDataFirst[key].forEach((value: any, index: any) => {
                                const newIndex = newDataFirst.CallHour[index];
                                newData[key][newIndex] = value !== null ? value : 0;
                            });
                        }
                    }
        
                    return {
                        "SNO": data?.SNO,
                        "HourlyValues": JSON.parse(data?.HourlyValues),
                        "DailyValues": dailyValues,
                        //for trial purpose
                        "newArray": newArray,
                    };
                    
                });
                setFullGraphData(tempRRR[0]?.newArray);
            }else if(tabValue === 'digital'){
                tempDDD = dummyApiResponseDigital?.map((data: any) => {
                    const dailyValuesRows = JSON.parse(data?.DailyValues)?.Rows;
                    const dailyValuesHeaders = JSON.parse(data?.DailyValues)?.Headers;
        
                    const hourlyValuesRows = JSON.parse(data?.HourlyValues)?.Rows;
                    const hourlyValuesHeaders = JSON.parse(data?.HourlyValues)?.Headers;
                
                    const dailyValues = dailyValuesRows.map((row: any) => {
                        const rowData: any = {};
                        dailyValuesHeaders.forEach((header: any, index: any) => {
                            if (header === "AvgTalkTime" || header === "AvgHoldTime" || header === "AvgWaitTime"|| header === "AvgWrapupTime" || 
                                header === "AvgHandlingTime" || header === "AvgSpeedToAnswer" || header === "avgChatTime" ) {
                                rowData[header] = convertSecondsToCustomFormat(row[index]);
                            } else {
                                rowData[header] = row[index];
                            }
                        });
                        return rowData;
                    });
        
                    const structuredData = hourlyValuesRows.map((row: any) => {
                        let rowData : any = {};
                        hourlyValuesHeaders.forEach((header: any, index: any) => {
                        rowData[header] = row[index];
                        });
                        return rowData;
                    });
        
        
                    const newArray = structuredData.reduce((acc: any, obj: any) => {
                        Object.keys(obj).forEach((key: any) => {
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(obj[key]);
                        });
                        return acc;
                    }, {});
        
                
                    return {
                        "SNO": data?.SNO,
                        "HourlyValues": JSON.parse(data?.HourlyValues),
                        "DailyValues": dailyValues,
                        //for trial purpose
                        "newArray": newArray,
                    };
                });
                setFullGraphDataDigi(tempDDD[0]?.newArray);
            }
        }

		if(tabValue === 'call'){
			tempResponseCall = tempRowData?.map((item: any) => {
				// const apiItem = apiResponseCalls[0][item?.key];
                const apiItem = tempRRR[0]?.DailyValues[0][item?.key];
				if (apiItem !== undefined) {
					return {
						label: item.label,
						key: item.key,
						value: apiItem,
						icon: item.key,
                        graphData: 'graphData',
                        id: item.key,
                        // color: '#ffff',
                        color: getColor(item?.key, item?.label),
					};
				}
				return null;
			}).filter((item: any) => item !== null);
		}else if(tabValue === 'digital'){
			tempResponseDigital = tempRowDataDigi?.map((item: any) => {
				// const apiItem = apiResponseDigital[0][item?.key];
                const apiItem = tempDDD[0]?.DailyValues[0][item?.key];
				if (apiItem !== undefined) {
					return {
						label: item.label,
						key: item.key,
						value: apiItem,
						icon: item.key,
                        graphData: 'graphData',
                        id: item.key,
                        // color: '#ffff',
                        color: getColor(item?.key, item?.label),
					};
				}
				return null;
			}).filter((item: any) => item !== null);
		}

		// setIsLoaderOpen(false);
		if(tabValue === 'call'){
			setTempRowData(tempResponseCall);
		}else if(tabValue === 'digital'){
			setTempRowDataDigi(tempResponseDigital);
		}
        setLastFetchedTime(getLastFetchedDetail());
        chartElement?.current?.chart?.reflow();
	};

	const retrieveFilters = async () => {
		const apiResponse = await getSystemAnalyticsFilters();
		setFilters(apiResponse);
	};

	const toggleFilterDrawer = (isOpen: boolean) => {
		setIsFilterDrawerOpen(isOpen);
	};

    const handleFilterExistingData = (filteredData: any) => {
		setSelectedFilters(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId, tabValue === 'call' ? columnData : columnDataDigi, tabValue);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection  }: any) => {
		setSelectedFilters(selectedFilters);
		toggleFilterDrawer(false);
        setFilterType(newFilterType);
        setFilterSection(newfilterSection);
        props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId, tabValue === 'call' ? columnData : columnDataDigi, tabValue);
	};

    const clearFilter = () => {
		setSelectedFilters([]);
        props?.handleFilterChange?.([], filterSection, props?.widgetId, tabValue === 'call' ? columnData : columnDataDigi, tabValue);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = selectedFilters.filter((item: any) => item.id !== id);
		setSelectedFilters(newFilter);
        props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId, tabValue === 'call' ? columnData : columnDataDigi, tabValue);
	};

	useEffect(() => {
        const filterIds = (selectedFilters.length && !(responseLength === selectedFilters.length)) ? selectedFilters.map((item: any) => item.id).join(',') : '0';
        const currentState = stateRef.current;
		retrieveGraphData(filterIds, currentState);
        if (interval) {
			clearInterval(interval);
		}
            interval = setInterval(() => {
                const filterIds = (selectedFilters.length && !(responseLength === selectedFilters.length)) ? selectedFilters.map((item: any) => item.id).join(',') : '0';
                const currentState = stateRef.current;
                retrieveGraphData(filterIds, currentState)
            }, WIDGET_REFRESH_INTERVAL.PERFORMANCE_METRICS);
	}, [selectedFilters, tabValue]);

	useEffect(() => {
		retrieveFilters();
	}, []);

    function getColorForCard(clickedData: any, fullData: any){
        let tempData : any =[];
        fullData.forEach((item: any) => {
            if (item.key === clickedData.key && item.label === clickedData.label) {
                if (item.color && item.color === colorDataForBackground[clickedData.key]) {
                    item.color = '#ffff';
                } else {
                    item.color = colorDataForBackground[clickedData.key];
                }
            }
        });
        tempData = fullData;
        if(tabValue === 'call'){
            // setTempRowData(fullData);
            setTempRowData(tempData);
        }else if(tabValue === 'digital'){
            // setTempRowDataDigi(fullData)
            setTempRowDataDigi(tempData)
        }
        return tempData;

    }

    const getCardClick = (data: any, fullDataClick: any) => {
        let newtemp : any = [];
        let tempDCall: any = [];
        let tempDDigital: any = [];
        if(tabValue === 'call'){
            tempDCall = getColorForCard(data, tempRowData);
        }else if(tabValue === 'digital'){
            tempDDigital = getColorForCard(data, tempRowDataDigi);
        }

        if(tabValue === 'call'){
            if(data.key === "AvgTalkTime" && fullDataClick?.hasOwnProperty('AvgTalkTime')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgTalkTime,
                });
            }
            if(data.key === "AvgHoldTime" && fullDataClick?.hasOwnProperty('AvgHoldTime')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgHoldTime,
                });
            }if(data.key === "AvgWaitTime" && fullDataClick?.hasOwnProperty('AvgWaitTime')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgWaitTime,
                });
            }if(data.key === "AvgWrapupTime" && fullDataClick?.hasOwnProperty('AvgWrapupTime')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgWrapupTime,
                });
            }if(data.key === "AvgHandlingTime" && fullDataClick?.hasOwnProperty('AvgHandlingTime')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgHandlingTime,
                });
            }if(data.key === "AvgSpeedToAnswer" && fullDataClick?.hasOwnProperty('AvgSpeedToAnswer')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgSpeedToAnswer,
                });
            }if(data.key === "CallConnectionRatio" && fullDataClick?.hasOwnProperty('CallConnectionRatio')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.CallConnectionRatio,
                });
            }if(data.key === "WrapTimeExceeded" && fullDataClick?.hasOwnProperty('WrapTimeExceeded')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.WrapTimeExceeded,
                });
            }if(data.key === "AbandonedCalls" && fullDataClick?.hasOwnProperty('AbandonedCalls')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AbandonedCalls,
                });
            }
            if(data.key === "CallAbandonedRatio" && fullDataClick?.hasOwnProperty('CallAbandonedRatio')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.CallAbandonedRatio,
                });
            }if(data.key === "AgentDisconnects" && fullDataClick?.hasOwnProperty('AgentDisconnects')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AgentDisconnects,
                });
            }
            if(data.key === "AgentDisconnectsRatio" && fullDataClick?.hasOwnProperty('AgentDisconnectsRatio')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AgentDisconnectsRatio,
                });
            }if(data.key === "TotalCalls" && fullDataClick?.hasOwnProperty('TotalCalls')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.TotalCalls,
                });
            } if(data.key === "SLA" && fullDataClick?.hasOwnProperty('SLA')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.SLA,
                });
            }if(data.key === "CallPerAgents" && fullDataClick?.hasOwnProperty('CallPerAgents')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.CallPerAgents,
                });
            }

        }else if(tabValue === 'digital'){
            if(data.key === "AvgTalkTime" && fullDataClick?.hasOwnProperty('AvgTalkTime')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AvgTalkTime,
                });
            }if(data.key === "CallPerAgents" && fullDataClick?.hasOwnProperty('CallPerAgents')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.CallPerAgents,
                });
            }if(data.key === "AbandonedCalls" && fullDataClick?.hasOwnProperty('AbandonedCalls')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.AbandonedCalls,
                });
            }if(data.key === "CallAbandonedRatio" && fullDataClick?.hasOwnProperty('CallAbandonedRatio')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.CallAbandonedRatio,
                });
            }if(data.key === "TotalQueuedCalls" && fullDataClick?.hasOwnProperty('TotalQueuedCalls')){
                newtemp.push({
                    name: data.label,
                    color: `${colorDataForPlot[data.key]}`,
                    data: fullDataClick.TotalQueuedCalls,
                });
            }
        }

        let namesArray1 = dynaGraphData.map((obj:any) => obj.name);
        let namesArray2 = newtemp.map((obj: any) => obj.name);
        let commonNames = namesArray1.filter((name: any) => namesArray2.includes(name));

        dynaGraphData = dynaGraphData.filter((obj: any) => !commonNames.includes(obj.name));
        newtemp = newtemp.filter((obj: any) => !commonNames.includes(obj.name));
        let combinedData : any = [...dynaGraphData, ...newtemp];
        setDynaGraphData(combinedData);
        if(tabValue === 'call'){
            stateRef.current = tempDCall;
        }else if(tabValue === 'digital'){
            stateRef.current = tempDDigital;
        }
    }

	return (
		<>
			<Card ref={downloadContent || donutChartElement} className="customCard_Performace">
				<div className="card-header">
					<div className='card-title'>
                        Performance Metrics<div className="card-title-badge">Live</div>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
                        <ToggleButtonGroup value={tabValue} onChange={handletabValueChange} color="primary" exclusive aria-label="bound">
                            <ToggleButton value="call" style={{ width: '70px', padding: '5px' }} aria-label="call">
                                <span>Call</span>
                            </ToggleButton>
                            <ToggleButton value="digital" style={{width: '73px', padding: '5px'}} aria-label="digital">
                                <span>Digital</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <WidgetDownload Wtype={"historic"} element={downloadContent || donutChartElement || chartElement} handleDownload={handleDownload} name={"Performance Metrics"}></WidgetDownload>
						<div style={{marginLeft: '-20px'}}>
							<ReportsPageHead
								filters={selectedFilters}
								toggleFilterDrawer={toggleFilterDrawer}
                                additionalColumns={tabValue === 'call' ? columnTableData : columnTableDataDigital}
                                selectedElements={tabValue === 'call' ? (headerSection === 'call' && props?.selectedFilters?.filterHeaders) || columnTableData : (headerSection != 'call' && props?.selectedFilters?.filterHeaders) || columnTableDataDigital}
                                onColumnsChange={handleOnSelectColumnChange}
                                hamburger
							/>
						</div>
						{/* <WidgetDownload Wtype={"historic"} element={downloadContent || donutChartElement || chartElement} handleDownload={handleDownload} name={"Performance Trend"}></WidgetDownload> */}
					</div>
				</div>
				<Box ref={downloadContent || donutChartElement || chartElement} sx={{ width: '100%', typography: 'body1', marginBottom: '1rem' }}>
                    {selectedFilters.length > 0 && (
                        <Box className="customTabPanel">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="filter-outer">
                                        <div className="filter-container">
                                            <Stack direction="row" spacing={1}>
                                                {filterItems}
                                            </Stack>
                                        </div>
                                        <div className="filter-remove" onClick={clearFilter}>
                                            Remove
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {dynaGraphData?.length > 0 ?
                        <Grid item xs={12} style={{marginLeft: '50px'}}>
                            <HighchartsReact ref={chartElement} highcharts={Highcharts} options={lineChartOptions} />
                        </Grid>
                        :
                        <Grid container xs={12} style={{ padding: '15px' }}>
                            <Grid item xs={12} style={graphStyle} alignContent={'center'}>
                                <Typography sx={{ ...graphItemStyle, textAlign: 'center' }}>Select a card below to show the graph here</Typography>
                            </Grid>
                        </Grid>
                    }
					<Grid container sx={{ padding: '1rem' }}>
						<Grid item xs={12} container style={{minHeight: '100px'}} spacing={2}>
                            {tabValue === 'call' ?
                                <>
								{tempRowData?.map((item: any) => {

                                    const matchedItem = tempColumnData?.find((compareItem: any) =>
                                        compareItem?.key === item?.key && compareItem?.label === item?.label
                                    );

                                    if (matchedItem) {
                                        return (
                                            <Grid item xs={2} key={item.key}>
                                                <CardContent
                                                    data={item}
                                                    fullgraph={tabValue === 'call' ? fullGraphData : fullGraphDataDigi}
                                                    onClickCard={getCardClick}
                                                />
                                            </Grid>
                                        );
                                    }
								})}
                                </>
                                :
                                <>
								{tempRowDataDigi?.map((item: any) => {
                                    const matchedItem = tempColumnDataDigi?.find((compareItem: any) =>
                                        compareItem?.key === item?.key && compareItem?.label === item?.label
                                    );

                                    if (matchedItem) {
                                        return (
                                            <Grid item xs={2} key={item.key}>
                                                <CardContent
                                                    data={item}
                                                    fullgraph={tabValue === 'call' ? fullGraphData : fullGraphDataDigi}
                                                    onClickCard={getCardClick}
                                                />
                                            </Grid>
                                        );
                                    }

								})}
                                </>
                            }
						</Grid>
					</Grid>
				</Box>
			</Card>
            <FilterDrawer isOpen={isFilterDrawerOpen} toggleDrawer={toggleFilterDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={selectedFilters} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
		</>
	);
};

export default PerformanceMetrics;