import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Badge, Box, Card, Chip, Stack, Theme, ToggleButton, ToggleButtonGroup, Menu, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getOverallInteractionTrendSummary } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { dummyHourlyChatData, dummyHourlyData, dummyInboundData, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../HourlyCallSummary/HourlyCallSummary.css';
import RadioGroupComponent from './RadioGroupComponent';
import WidgetPageHeadCustom from '../../WidgetPageHeadCustom/WidgetPageHeadCustom';

// Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
//      Highcharts.seriesTypes.line.prototype.drawLegendSymbol;
const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important',
		minWidth: 70
	},
	selectImg: {
        background: '#008DFF'
    },
    deSelectImg: {
        background: '#008DFF'
    },
	filterItem: {
		fontSize: 12,
		height: 36,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	},
	popoverContent: {
		minWidth: 200,
		fontSize: '14px',
		lineHeight: 2,
		minHeight: 130,
	},
	popoveraccordianmainHeading: {
		minWidth: '200px',
		marginTop: -10,
	},
	popoveraccordiansubHeading: {
		display: 'flex',
		alignItem: 'end',
	}
}));
let interval: any;

const defaultFilters = [
	{ id: 1, label: 'General', color: '#EE4060' },
	{ id: 2, label: 'Canada Sales', color: '#EEBE40' },
	{ id: 3, label: 'Support', color: '#B0CFA5' },
	{ id: 4, label: 'English Sales', color: '#53B9ED' },
	{ id: 5, label: 'Premium Plus', color: '#B2A1F9' }
];

function HourlyCallSummary(props: any) {
	const classes = useStyles();
	const { useState, useRef, useMemo } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [bound, setBound] = useState<any>('inbound');
	const [graphData, setGraphData] = useState<any>([]);
	const [hourlySummaryFilter, setHourlySummaryFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const downloadContent = useRef<null | any>(null);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');

	const chartElement: any = useRef(null);
	// const classes = useStyles();
    const [graphViewTab, setGraphViewTab] = useState<any>('line');
    // const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState<any>(false);

	const [durationLabel, setDurationLabel] = useState<string>('Last 30 Mins');
	const [duration, setDuration] = useState<any>();

	const [dataInGraph, setDataInGraph] = useState<any>({});
	const [selectedPoint, setSelectedPoint] = useState<any>(null);
	const [isPopoverOpenOnClickBar, setIsPopoverOpenOnClickBar] = useState<boolean>(false);
	const [graphRefPoint, setGraphRefPoint] = useState<any>(null);
	const [tempDataForOutbound, setTempDataForOutbound] = useState<any>([]);

	const radioClick = useRef<any>('All');

	const clearFilter = () => {
		setHourlySummaryFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};


	let formHeadingMain = useMemo(
        () => ({
            fontWeight: '600',
            fontSize: '13px',
            color: '#3D4C5E',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

    let subFormHeading = useMemo(
        () => ({
            fontWeight: '400',
            fontSize: '12px',
            color: '#3D4C5E',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

	const handleFilterDelete = (id: Number) => {
		const newFilter = hourlySummaryFilter.filter((item: any) => item.id !== id);
		setHourlySummaryFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = hourlySummaryFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	function colorMapping(type: any) {
		if(type === 'preview'){
			return '#3D8BF8';
		}else if(type === 'manual'){
			return '#E65B5C';
		}else if(type === 'progressive'){
			return '#4F9F52';
		}else if(type === 'predictive'){
			return '#E7AF5B';
		}

	}

	const fillColorMappingInbound = {
		"Total": {
			start: 'rgba(176, 220, 255, 1)', 
			end: 'rgba(176, 220, 255, 0.1)' 
		},
		"Answered": {
			start: 'rgba(61, 139, 248, 1)',
			end: 'rgba(61, 139, 248, 0.1)'
		},
		"Unanswered": {
			start: 'rgba(0, 106, 191, 1)',
			end: 'rgba(176, 220, 255, 0.1)'
		}
	};
	const fillColorMappingDigital = {
		"Total": {
			start: 'rgba(79, 159, 82, 1)',
			end: 'rgba(79, 159, 82, 0.1)' 
		},
		"Answered": {
			start: 'rgba(79, 159, 82, 1)',
			end: 'rgba(79, 159, 82, 0.1)'
		},
		"Unanswered": {
			start: 'rgba(211, 231, 212, 1)',
			end: 'rgba(211, 231, 212, 0.1)'
		}
	};
	const fillColorMappingOutbound = {
		"Total": {
			start: 'rgba(71, 88, 110, 1)', 
			end: 'rgba(153, 160, 168, 0.1)' 
		},
		"Answered": {
			start: 'rgba(178, 187, 198, 1)',
			end: 'rgba(230, 229, 230, 0.1)'
		},
		"Unanswered": {
			start: 'rgba(144, 157, 173, 1)',
			end: 'rgba(153, 160, 168, 0.1)'
		}
	};

	const formatGraphData = (data: any[]) => {
		let graphData : any = [];
		const sortedData = [...data];
		sortedData.sort((a, b) => (+a.CallHour > +b.CallHour ? 1 : +b.CallHour > +a.CallHour ? -1 : 0));
		if(bound === 'inbound'){
			graphData = [
				{
					name: 'Total Calls',
					data: sortedData.map((item) => +item.TotalCalls),
					Answered: sortedData.map((item) => +item.AnsweredCalls),
					UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
					ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
					Total : sortedData.map((item) => +item.TotalCalls),
					CallHour: sortedData.map((item) => +item.CallHour),
					color: '#B0DCFF',
					fillColor: {
						linearGradient: [0, 0, 0, 250],
						stops: [
							[0.1, fillColorMappingInbound["Total"].start],
							[1, fillColorMappingInbound["Total"].end]
						]
					}
				},
				{
					name: 'Answered',
					data: sortedData.map((item) => +item.AnsweredCalls),
					Answered: sortedData.map((item) => +item.AnsweredCalls),
					UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
					ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
					Total : sortedData.map((item) => +item.TotalCalls),
					CallHour: sortedData.map((item) => +item.CallHour),
					color: '#3D8BF8',
					fillColor: {
						linearGradient: [0, 0, 0, 250],
						stops: [
							[0.1, fillColorMappingInbound["Answered"].start],
							[1, fillColorMappingInbound["Answered"].end]
						]
					}
				},
				{
					name: 'Unanswered',
					data: sortedData.map((item) => +item.UnansweredCalls),
					Answered: sortedData.map((item) => +item.AnsweredCalls),
					UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
					ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
					Total : sortedData.map((item) => +item.TotalCalls),
					CallHour: sortedData.map((item) => +item.CallHour),
					color: '#006ABF',
					fillColor: {
						linearGradient: [0, 0, 0, 250],
						stops: [
							[0.1, fillColorMappingInbound["Unanswered"].start],
							[1, fillColorMappingInbound["Unanswered"].end]
						]
					}
				}
			];

		}else if(bound === 'digital'){
			graphData = [
				{
					name: 'Total Chats',
					data: sortedData.map((item) => +item.TotalCalls),
					Answered: sortedData.map((item) => +item.AnsweredCalls),
					UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
					ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
					Total : sortedData.map((item) => +item.TotalCalls),
					CallHour: sortedData.map((item) => +item.CallHour),
					color: '#4F9F52',
					fillColor: {
						linearGradient: [0, 0, 0, 250],
						stops: [
							[0.1, fillColorMappingDigital["Total"].start],
							[1, fillColorMappingDigital["Total"].end]
						]
					}
				},
				{
					name: 'Answered',
					data: sortedData.map((item) => +item.AnsweredCalls),
					Answered: sortedData.map((item) => +item.AnsweredCalls),
					UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
					ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
					Total : sortedData.map((item) => +item.TotalCalls),
					CallHour: sortedData.map((item) => +item.CallHour),
					color: '#A4CDA6',
					fillColor: {
						linearGradient: [0, 0, 0, 250],
						stops: [
							[0.1, fillColorMappingDigital["Answered"].start],
							[1, fillColorMappingDigital["Answered"].end]
						]
					}
				},
				{
					name: 'Unanswered',
					data: sortedData.map((item) => +item.UnansweredCalls),
					Answered: sortedData.map((item) => +item.AnsweredCalls),
					UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
					ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
					Total : sortedData.map((item) => +item.TotalCalls),
					CallHour: sortedData.map((item) => +item.CallHour),
					color: '#D3E7D4',
					fillColor: {
						linearGradient: [0, 0, 0, 250],
						stops: [
							[0.1, fillColorMappingDigital["Unanswered"].start],
							[1, fillColorMappingDigital["Unanswered"].end]
						]
					}
				}
			];
		}else if(bound === 'outbound'){
			if(radioClick.current === 'All'){
				graphData = [
					{
						name: 'Total Calls',
						data: sortedData.map((item) => +item.TotalCalls),
						Answered: sortedData.map((item) => +item.AnsweredCalls),
						UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
						ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
						Total : sortedData.map((item) => +item.TotalCalls),
						CallHour: sortedData.map((item) => +item.CallHour),
						color: '#47586E',
						fillColor: {
							linearGradient: [0, 0, 0, 250],
							stops: [
								[0.1, fillColorMappingOutbound["Total"].start],
								[1, fillColorMappingOutbound["Total"].end]
							]
						}
					},
					{
						name: 'Answered',
						data: sortedData.map((item) => +item.AnsweredCalls),
						Answered: sortedData.map((item) => +item.AnsweredCalls),
						UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
						ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
						Total : sortedData.map((item) => +item.TotalCalls),
						CallHour: sortedData.map((item) => +item.CallHour),
						color: '#909DAD',
						fillColor: {
							linearGradient: [0, 0, 0, 250],
							stops: [
								[0.1, fillColorMappingOutbound["Answered"].start],
								[1, fillColorMappingOutbound["Answered"].end]
							]
						}
					},
					{
						name: 'Unanswered',
						data: sortedData.map((item) => +item.UnansweredCalls),
						Answered: sortedData.map((item) => +item.AnsweredCalls),
						UnAnswered: sortedData.map((item) => +item.UnansweredCalls),
						ConnectionRatio: sortedData.map((item) => +item.ConnectedPercentageRatio),
						Total : sortedData.map((item) => +item.TotalCalls),
						CallHour: sortedData.map((item) => +item.CallHour),
						color: '#B2BBC6',
						fillColor: {
							linearGradient: [0, 0, 0, 250],
							stops: [
								[0.1, fillColorMappingOutbound["Unanswered"].start],
								[1, fillColorMappingOutbound["Unanswered"].end]
							]
						}
					}
				];
			}else{
				// Parse the radioClick state
				let selectedTypes = radioClick?.current?.toLowerCase()?.split(', ')?.map((type: any) => type.trim());

				// Filter the data based on the selected types
				// let filteredData = data?.filter(item => selectedTypes.includes(item.CallType));
			
				// // Create the graphData array
				// graphData = selectedTypes?.map((type: any) => ({
				// 	name: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize the first letter
				// 	data: filteredData
				// 		.filter(item => item?.CallType === type)
				// 		.map(item => +item?.ConnectedPercentageRatio),
				// 	Answered: filteredData.map((item) => +item.AnsweredCalls),
				// 	UnAnswered: filteredData.map((item) => +item.UnansweredCalls),
				// 	ConnectionRatio: filteredData.map((item) => +item.ConnectedPercentageRatio),
				// 	Total : filteredData.map((item) => +item.TotalCalls),
				// 	CallHour: filteredData.map((item) => +item.CallHour),
				// 	color: colorMapping(type)
				// }));


				graphData = selectedTypes.map((type: any) => {
					const typeData = data.filter(item => item.CallType === type);
				
					const answered : any= [];
					const unanswered : any= [];
					const connectionRatio : any= [];
					const total : any= [];
					const callHour : any= [];
				
					typeData.forEach(item => {
						answered.push(item.AnsweredCalls);
						unanswered.push(item.UnansweredCalls);
						connectionRatio.push(Math.round(parseFloat(item.ConnectedPercentageRatio)));
						total.push(item.TotalCalls);
						callHour.push(item.CallHour);
					});
				
					return {
						name: type.charAt(0).toUpperCase() + type.slice(1),
						data: connectionRatio,
						Answered: answered,
						UnAnswered: unanswered,
						ConnectionRatio: connectionRatio,
						Total: total,
						CallHour: callHour,
						color: colorMapping(type)
					};
				});
			}
		}
		return { graphData, sortedData };
	};

	const makeIndexData = (data: any[]) => {

		return data?.map(item => {
			const answered = new Array(23 + 1).fill(0);
			const unAnswered = new Array( 23 + 1).fill(0);
			const connectionRatio = new Array( 23 + 1).fill(0);
			const total = new Array( 23 + 1).fill(0);
			const callHour = new Array( 23 + 1).fill(0);
			const Data = new Array( 23 + 1).fill(0);
	
			item.CallHour.forEach((hour: any, index: any) => {
				answered[hour] = item.Answered[index];
				unAnswered[hour] = item.UnAnswered[index];
				connectionRatio[hour] = item.ConnectionRatio[index];
				total[hour] = item.Total[index];
				callHour[hour] = item.CallHour[index];
				Data[hour] = item.data[index];
			});
	
			return {
				...item,
				Answered: answered,
				UnAnswered: unAnswered,
				ConnectionRatio: connectionRatio,
				Total: total,
				CallHour: callHour,
				data: Data
			};
		});

	}

	const newFilteredData = (data: any[]) => {
		let filteredItem : any = [];
		if(bound === 'inbound'){
			filteredItem = data.filter((item:any) => item.CallType !== 'All');
		}else if(bound === 'digital'){
			filteredItem = data.filter((item:any) => item.CallType === 'All');
		}else if(bound === 'outbound'){
			if(radioClick.current === 'All'){
				filteredItem = data.filter((item:any) => item.CallType === 'All');
			}else{
				// let tempFilteredData : any = [];
				// tempFilteredData = data.filter((item:any) => item.CallType !== 'All');
				filteredItem = data.filter((item:any) => item.CallType !== 'All');

				// if(radioClick.current === 'Manual'){
				// 	filteredItem = tempFilteredData.filter((subItem: any) => subItem.CallType === "manual");
				// }else if(radioClick.current === 'Predictive'){
				// 	filteredItem = tempFilteredData.filter((subItem: any) => subItem.CallType === "predictive");
				// }else if(radioClick.current === 'Progressive'){
				// 	filteredItem = tempFilteredData.filter((subItem: any) => subItem.CallType === "progressive");
				// }else if(radioClick.current === 'Preview'){
				// 	filteredItem = tempFilteredData.filter((subItem: any) => subItem.CallType === "preview");
				// }
			}
		}
		return filteredItem;
	}

	const retriveGraphData = async (filters = '0') => {
		if (!duration?.fromTime) return;
		const data: any = await getOverallInteractionTrendSummary({ filters, type: filterType, bound : bound === 'inbound' ? 'Voice' : bound, ...duration });
		// let data : any = [];
		// if(bound === 'digital'){
		// 	data = dummyHourlyChatData;
		// }else if(bound === 'inbound'){
		// 	data = dummyInboundData;
		// }else if(bound === 'outbound'){
		// 	data = dummyHourlyData;
		// }

		if(bound === 'outbound'){
			setTempDataForOutbound(data);
		}
		const filteredData = newFilteredData(data);
		const { graphData: formattedGraphData, sortedData } = formatGraphData(filteredData);
		const makeIndexForAllData = makeIndexData(formattedGraphData);
		setGraphData(makeIndexForAllData);
		setLastFetchedTime(getLastFetchedDetail());
		chartElement?.current?.chart?.redraw();
	};

	const handlePointClick = (point: any, additionalData: any) => {
		setSelectedPoint(point);
		setDataInGraph(additionalData);
		setGraphRefPoint(point?.graphic?.element || point);
		setIsPopoverOpenOnClickBar(true);
	};
	const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		if (newAlignment === null || newAlignment === undefined) return;
		setBound(newAlignment);
	};

	const handleGraphViewTab = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        if (newAlignment === null || newAlignment === undefined) return;
        setGraphViewTab(newAlignment);
    };

	const handleRadioChanged = (value: any) => {
		if(value.hasOwnProperty('Aggregate') && value.Aggregate === 'Aggregate'){
			// setRadioClick('All');
			radioClick.current = 'All';
		}else if(!value.hasOwnProperty('Aggregate')){
			let currentOptions = radioClick.current === 'All' ? [] : radioClick.current.split(', ').filter((option: any) => option);

			if (currentOptions.includes(value)) {
				currentOptions = currentOptions.filter((option: any) => option !== value);
			} else {
				currentOptions.push(value);
			}
	
			const selectedOptionsString = currentOptions.length ? currentOptions.join(', ') : 'All';
	
			if (radioClick !== selectedOptionsString) {
				radioClick.current = selectedOptionsString;
				// setRadioClick(selectedOptionsString);
			}
		}
		const tempFilteredData = newFilteredData(tempDataForOutbound);
		const { graphData: formattedGraphData, sortedData } = formatGraphData(tempFilteredData);
		const makeIndexForAllData = makeIndexData(formattedGraphData);
		// setCategories(sortedData.map((item: any) => `${item.CallHour}:00`));
		setGraphData(makeIndexForAllData);
		// setGraphData(formattedGraphData);
    }

	const handleOnPopoverClose = () => {
		setGraphRefPoint(null);
		setSelectedPoint(null);
		setIsPopoverOpenOnClickBar(false);
	};

	const getPopoverContentOnClickBar = () => {
		if (!selectedPoint) return null;
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div className={classes.popoveraccordianmainHeading}>
					<div className={classes.popoveraccordiansubHeading}>
						<Typography sx={formHeadingMain} flexGrow={1}>{dataInGraph?.name}</Typography>
						<div style={{...formHeadingMain}}>
							{`${dataInGraph?.callHour ? dataInGraph?.callHour : '0' }:00`}
						</div>
					</div>
					<Divider sx={{borderStyle: 'dashed', marginTop: '2px'}}></Divider>
					<div className={classes.popoveraccordiansubHeading}>
						<Typography sx={subFormHeading} flexGrow={1}>Connection Ratio</Typography>
						<div>{dataInGraph?.ConnectionRatio ? `${dataInGraph.ConnectionRatio}%` : '0%'}</div>
					</div>
					<div className={classes.popoveraccordiansubHeading}>
						<Typography sx={subFormHeading} flexGrow={1}>Total</Typography>
						<div>{dataInGraph?.total ? `${dataInGraph.total}` : '0'}</div>
					</div>
					<div className={classes.popoveraccordiansubHeading}>
						<Typography sx={subFormHeading} flexGrow={1}>Answered</Typography>
						<div>{dataInGraph?.answered ? `${dataInGraph.answered}` : '0'}</div>
					</div>
					<div className={classes.popoveraccordiansubHeading}>
						<Typography sx={subFormHeading} flexGrow={1}>UnAnswered</Typography>
						<div>{dataInGraph?.UnAnswered ? `${dataInGraph.UnAnswered}` : '0'}</div>
					</div>
				</div>
			</Box>
		);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const toggleRefresh = () => {
		const filterIds = hourlySummaryFilter.length ? hourlySummaryFilter.map((item: any) => item.id).join(',') : '0';
		retriveGraphData(filterIds);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const options = {
		chart: {
			type: 'column',
			height: 300,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: 'Time ➡',
				style: { fontWeight: 'bold' }
			},
			// categories: categories,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
				'09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
				'18:00','19:00','20:00','21:00','22:00','23:00'
			],
			crosshair: true
		},
		yAxis: {
			title: {
				text: bound === 'inbound' ? "Call Volume" : bound === 'digital' ? "Chat Volume" : bound === 'outbound' && radioClick.current === 'All' ? "Call Volume" : 'Connection %',
				style: { fontWeight: 'bold' }
			},
			gridLineDashStyle: 'dash'
		},
		legend: {
			layout: 'horizontal',
			align: 'right',
			verticalAlign: 'top',
			itemMarginTop: 10
		},
		tooltip: {
			formatter(this: any): any {
				return `${this.y}`;
			}
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				borderRadiusTopLeft: '50%',
				borderRadiusTopRight: '50%',
				pointWidth: 10,
				borderRadius: 5,
				point: {
					events: {
						click: function ({ point }: any) {
							// const pointN = point.point;
							const series = point.series;
							const pointIndex = point.index;
	
							// Retrieve the additional data (Answered and Total) for the current point
							const answered = series.userOptions.Answered[pointIndex];
							const UnAnswered= series.userOptions.UnAnswered[pointIndex];
							const total = series.userOptions.Total[pointIndex];
							const callHour = series.userOptions.CallHour[pointIndex];
							const ConnectionRatio = series.userOptions.ConnectionRatio[pointIndex];
							const name = series.name;



                            // handlePointClick(point);
							handlePointClick(point, {
								answered,
								total,
								callHour,
								name,
								UnAnswered,
								ConnectionRatio,
								seriesData: series.userOptions.data[pointIndex]
							});
						}
					}
				}
			}
		},
		series: graphData
	};

	const optionsLine = {
        chart: {
            type: 'line',
            height: 300,
        },
        credits: { enabled: false },
        title: { text: '' },
        xAxis: {
            title: {
                text: 'Time ➡',
                style: { fontWeight: 'bold' }
            },
            // categories: categories,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
			'09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
			'18:00','19:00','20:00','21:00','22:00','23:00'
			],
            crosshair: true
        },
        yAxis: {
            title: {
                text: bound === 'outbound' && radioClick.current === 'All' ? "Call Volume" : 'Connection %',
                style: { fontWeight: 'bold' }
            },
            gridLineDashStyle: 'dash'
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            itemMarginTop: 10
        },
        tooltip: {
			// useHTML: true,
			// backgroundColor: '#ffff', // Make the default tooltip background transparent
			// borderWidth: 0, // Remove the default border
			// shadow: false,
            formatter(this: any): any {
                return `${this.y}`;
            }
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                borderRadiusTopLeft: '50%',
                borderRadiusTopRight: '50%',
                pointWidth: 10,
                borderRadius: 5,
                point: {
                    events: {
                        click: function ({ point }: any) {

							// const pointN = point.point;
							const series = point.series;
							const pointIndex = point.index;
	
							// Retrieve the additional data (Answered and Total) for the current point
							const answered = series.userOptions.Answered[pointIndex];
							const UnAnswered= series.userOptions.UnAnswered[pointIndex];
							const total = series.userOptions.Total[pointIndex];
							const callHour = series.userOptions.CallHour[pointIndex];
							const ConnectionRatio = series.userOptions.ConnectionRatio[pointIndex];
							const name = series.name;



                            // handlePointClick(point);
							handlePointClick(point, {
								answered,
								total,
								callHour,
								name,
								UnAnswered,
								ConnectionRatio,
								seriesData: series.userOptions.data[pointIndex]
							});
                        }
                    }
                }
            }
        },
        series: graphData
    };

	const optionsArea = {
        chart: {
            type: 'area',
            height: 300,
        },
        credits: { enabled: false },
        title: { text: '' },
        xAxis: {
            title: {
                text: 'Time ➡',
                style: { fontWeight: 'bold' }
            },
            // categories: categories,
			categories: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00',
			'09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00',
			'18:00','19:00','20:00','21:00','22:00','23:00'
			],
            crosshair: true
        },
        yAxis: {
            title: {
                text: bound === 'inbound' ? "Call Volume" : bound === 'digital' ? "Chat Volume" : 'Call Volume',
                style: { fontWeight: 'bold' }
            },
            gridLineDashStyle: 'dash'
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            itemMarginTop: 10
        },
        tooltip: {
            formatter(this: any): any {
                return `${this.y}`;
            }
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                borderRadiusTopLeft: '50%',
                borderRadiusTopRight: '50%',
                pointWidth: 10,
                borderRadius: 5,
				// fillColor: {
                //     linearGradient: [0, 0, 0, 250],
                //     stops: [
                //         [0.1, `${bound === 'inbound' && graphViewTab === 'line' ? 'rgba(0, 106, 191, 1)' :
				// 				// bound === 'digital' && graphViewTab === 'bar' ? 'rgba(79, 159, 82, 0.1)':
                //                 'red'}`], // 10% - Color #E65B5C
                //         [1, `${bound === 'inbound' && graphViewTab === 'line' ? 'rgba(176, 220, 255, 0.1)' :
				// 				// bound === 'digital' && graphViewTab === 'bar' ? 'rgba(79, 159, 82, 0.1)':
                //                 'red'}`]    // 100% - Color #E75B5C
                //     ]
                // },
                point: {
                    events: {
                        click: function ({ point }: any) {
							// const pointN = point.point;
							const series = point.series;
							const pointIndex = point.index;
	
							// Retrieve the additional data (Answered and Total) for the current point
							const answered = series.userOptions.Answered[pointIndex];
							const UnAnswered= series.userOptions.UnAnswered[pointIndex];
							const total = series.userOptions.Total[pointIndex];
							const callHour = series.userOptions.CallHour[pointIndex];
							const ConnectionRatio = series.userOptions.ConnectionRatio[pointIndex];
							const name = series.name;



                            // handlePointClick(point);
							handlePointClick(point, {
								answered,
								total,
								callHour,
								name,
								UnAnswered,
								ConnectionRatio,
								seriesData: series.userOptions.data[pointIndex]
							});
                        }
                    }
                }
            }
        },
        series: graphData
    };

	React.useEffect(() => {
		const resizeListener = () => {
			chartElement?.current?.chart?.reflow();
		};
		window.addEventListener('widgetResize', resizeListener);
		window.addEventListener('resize', resizeListener);
		return () => {
			clearInterval(interval);
			window.removeEventListener('widgetResize', resizeListener);
		};
	}, []);

	React.useEffect(() => {
        const resizeListener = () => {
            chartElement?.current?.chart?.reflow();
        };
        window.addEventListener('widgetResize', resizeListener);
        window.addEventListener('resize', resizeListener);
        return () => {
            clearInterval(interval);
            window.removeEventListener('widgetResize', resizeListener);
        };
    }, []);

	React.useEffect(() => {
		const filterIds = (hourlySummaryFilter.length && !(responseLength === hourlySummaryFilter.length)) ? hourlySummaryFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = hourlySummaryFilter.length ? hourlySummaryFilter.map((item: any) => item.id).join(',') : '0';
		retriveGraphData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retriveGraphData(filterIds), WIDGET_REFRESH_INTERVAL.HOURLY_CALL_SUMMARY);
	}, [bound, hourlySummaryFilter, duration]);

	const handleFilterExistingData = (filteredData: any) => {
		setHourlySummaryFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setHourlySummaryFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	}

	const handleMenuClickClose = (event: any) => {
		setAnchorEl(null);
		if (!!event) {
			setAnchorEl(null);
		}
	};

	const handleDuration = (type: string = '', timeObj: any) => {
		console.log("time came ", type, timeObj);
		if (type) {
			setDurationLabel(type);
			setDuration(timeObj);
		}
	};

	return (
		<>
			<Card ref={downloadContent || chartElement} className="customCard_hourlyCallSummary">
				<div className="card-header">
					<div className="card-title">
						Interaction Trend
						<span className="card-title-badge">Live</span>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						<ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
							<ToggleButton value="inbound" className={classes.boundButton} aria-label="inbound">
								<span>Inbound</span>
							</ToggleButton>
							<ToggleButton value="outbound" className={classes.boundButton} aria-label="outbound">
								<span>Outbound</span>
							</ToggleButton>
							<ToggleButton value="digital" className={classes.boundButton} aria-label="Digital">
								<span>Digital</span>
							</ToggleButton>
						</ToggleButtonGroup>

						<WidgetPageHeadCustom
							duration={durationLabel}
							handleDuration={handleDuration}
							enableLastThirtyMinutesDuration={true}
							enableLastOneHourDuration={true}
							enableLastFourHourDuration={true}
							enableLastEightHourDuration={true}
							withTimePicker={true}

							enableWidgetIcon={true}
							graphViewTab={graphViewTab}
							handleGraphViewTab={handleGraphViewTab}
						/>
						<WidgetDownload element={downloadContent || chartElement} handleDownload={handleDownload} name={'Interaction Trend'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={hourlySummaryFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580', marginRight: '10px' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ pt: 2, pl: 2 }}>
					{hourlySummaryFilter.length > 0 && (
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction="row" spacing={1}>
									{filterItems}
								</Stack>
							</div>
							<div className="filter-remove" onClick={clearFilter}>
								Remove
							</div>
						</div>
					)}
				</Box>

				<div style={{display: 'flex', alignContent: 'end', marginBottom: '10px'}}>
                    <div style={{flexGrow: 1}}> 
                    {bound === 'outbound' ? 
                        <>
                        <RadioGroupComponent aggregateValue={radioClick.current} radioChanged={handleRadioChanged} />
                        </>
                    :
                    <></>
                    }
                    </div>
                </div>

				{graphViewTab === 'line' && bound === 'outbound' && radioClick.current === 'All' ? 
                    <>
                        {/* <HighchartsReact ref={chartElement} highcharts={Highcharts} options={optionsLine} /> */}
						<HighchartsReact ref={chartElement} highcharts={Highcharts} options={optionsArea} />
                    </>
				: graphViewTab === 'line' && bound === 'outbound' && radioClick.current !== 'All' ?
					<>
						<HighchartsReact ref={chartElement} highcharts={Highcharts} options={optionsLine} />
					</>
                : graphViewTab === 'bar' && bound === 'outbound' ?
                    <>
                        <HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
                    </>
                :graphViewTab === 'line' && (bound === 'inbound' || bound === 'digital') ?
                    <>
                        <HighchartsReact ref={chartElement} highcharts={Highcharts} options={optionsArea} />
                    </>
                :graphViewTab === 'bar' && (bound === 'inbound' || bound === 'digital') ?
                    <>
                        <HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
                    </>
                :
                    <></>
                }
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={hourlySummaryFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>

			<Menu
				id='hourlyDigitalMenu'
				aria-labelledby="hourlyDigitalMenu"
				anchorEl={graphRefPoint}
				open={isPopoverOpenOnClickBar}
				onClose={handleOnPopoverClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				sx={{
					'& .MuiMenu-paper': {
						backgroundColor: '#FFFFFF',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04)',
						border: '1px solid #E6E5E6'
					}
				}}
				anchorReference="anchorEl"
			>
				{getPopoverContentOnClickBar()}

			</Menu>
		</>
	);
}
export default HourlyCallSummary;
