import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Button, Divider, Grid, TextField } from '@mui/material';
import './Adminsettings.css';
import { getSettingsList, settingsSave } from '../../../services/api-service';
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { Formik } from "formik";
import OZSelect from '../../../components/admin/common/OZSelect';
import LockedAndStatusConfirmationDialog from '../../../components/admin/common/OZLockedAndStatusDialog';
import { NewAdminPages, newAuthSubAdminAccess } from '../../../services/page-service';

let switchSettingObj = {};
function IntegrationOrMiscellaneousSettings(props) {
  const [settingData, setSettings] = useState([]);
  const [toggleEditModes, setToggleEditMode] = useState({});
  const [textEditModes, setTextEditMode] = useState({});
  const [salesForceTicketFields, setSalesForceTicketFileds] = useState({})
   const [salesForceChatFields, setSalesForceChatFileds] = useState({})
  const [zendeskTicketFields, setZendeskTicketFields] = useState({})
  const [toggleStateConfirmationDialog, setToggleStateConfirmationDialog] = useState(false);

  const IntegrationSettingsNameList = ['FreshSales', 'FreshWorks', 'SalesForce', 'Zendesk', 'Dynamics365', 'Kapdesk', 'SIP Settings', 'AgileCRM']
  const MiscellaneousExcludedList = [...IntegrationSettingsNameList,'Outbound','Agent Settings','Admin Settings']
  const settingsNameList = settingData.map((item) => item.SettingName);
  const MiscellaneousSettingsList = [
    ...new Set(settingsNameList.filter((name) => !MiscellaneousExcludedList.includes(name))),
  ];


  useEffect(() => {
    getSettings();
  }, []);

  let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '27px', fontSize: '14px', width: '76px', fontWeight: 400 }), [])

  const SettingNameStyle = {
    height: '21px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#212121',
    paddingTop: '16px',
    flexGrow: 1,
    alignSelf: 'center'
  }


  async function getSettings() {
        try {
      props.settingsList()
        .then((resp) => {
          setSettings(resp.Data);
          let data = {}
          setSettings(resp.Data);
          resp?.Data?.forEach((newitem) => {
            return data[newitem?.ParameterName] = getCheckedStatus(newitem);
          })
          setToggleEditMode(data);
          let freshworksticketdata = {}
          setSettings(resp.Data);
          resp?.Data?.forEach((intergrationTicket) => {
            if (intergrationTicket.InputType === 'json' && intergrationTicket.ParameterName === "FreshWorks Ticket Fields") {
              freshworksticketdata = getTicketStatus((((intergrationTicket.ParameterValue) === null) || (intergrationTicket.ParameterValue === "false")) ? (intergrationTicket.DefaultValue) : (intergrationTicket.ParameterValue));
              return freshworksticketdata;
            }
          })
          setTextEditMode(freshworksticketdata);

          let salesforceticketdata = {}
          setSettings(resp.Data);
          resp?.Data?.forEach((intergrationTicket) => {
            if (intergrationTicket.InputType === 'json' && intergrationTicket.ParameterName === "SalesForce Task Fields") {
              salesforceticketdata = getTicketStatus((((intergrationTicket.ParameterValue) === null) || (intergrationTicket.ParameterValue === "false")) ? (intergrationTicket.DefaultValue) : (intergrationTicket.ParameterValue));
              return salesforceticketdata;
            }
          })
          setSalesForceTicketFileds(salesforceticketdata);
          
          
          
          let salesforcechatdata = {}
          setSettings(resp.Data);
          resp?.Data?.forEach((intergrationTicket) => {
            if (intergrationTicket.InputType === 'json' && intergrationTicket.ParameterName === "Salesforce Chat Fields") {
              salesforcechatdata = getTicketStatus((((intergrationTicket.ParameterValue) === null) || (intergrationTicket.ParameterValue === "false")) ? (intergrationTicket.DefaultValue) : (intergrationTicket.ParameterValue));
              return salesforcechatdata;
            }
          })
          setSalesForceChatFileds(salesforcechatdata);

          let zendeskticketdata = {}
          setSettings(resp.Data);
          resp?.Data?.forEach((intergrationTicket) => {
            if (intergrationTicket.InputType === 'json' && intergrationTicket.ParameterName === "Zendesk Ticket Fields") {
              zendeskticketdata = getTicketStatus((((intergrationTicket.ParameterValue) === null) || (intergrationTicket.ParameterValue === "false")) ? (intergrationTicket.DefaultValue) : (intergrationTicket.ParameterValue));
              return zendeskticketdata;
            }
          })
          setZendeskTicketFields(zendeskticketdata);

        })
        .catch((e) => {
          setSettings([]);
        });
    } catch (e) {
      showErrorNotification("error ");
    }
  };


  async function agentSettingsave(data) {
    try {
      settingsSave(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification("Setting UserParameter has been updated successfully.")
            getSettings();
          }
          else {
            showWarningNotification(resp.Message)
          }
        })
        .catch((e) => {
          showErrorNotification(e)
        });
    } catch (e) {
      showErrorNotification(e);

    }
  };

  const handleEditTextField = (settingName, value) => {
    setTextEditMode(prev => ({ ...prev, [settingName]: value }))
  };


  const handleTicketEdit = (settingName, value) => {
    console.log("value"+value)
    setTextEditMode(prev => ({ ...prev, [settingName]: value }))

  };

  const handleSalesforceTicketEdit = (settingName, value) => {
    setSalesForceTicketFileds(prev => ({ ...prev, [settingName]: value }))

  };
  
  
   const handleSalesforceChatEdit = (settingName, value) => {
    setSalesForceChatFileds(prev => ({ ...prev, [settingName]: value }))

  };

  const handleZendeskTicketEdit = (settingName, value) => {
    setZendeskTicketFields(prev => ({ ...prev, [settingName]: value }))

  };


  const updateSwitch = () => {
    let setting = switchSettingObj.setting;
    let value = switchSettingObj.value;
    saveSettings(setting, value)
    setToggleStateConfirmationDialog(false);
    switchSettingObj = {}
  }

  const onCloseToggleConfirmationDialog = () => {
    let setting = switchSettingObj.setting
    let value = switchSettingObj.value
    setToggleEditMode(prev => ({ ...prev, [setting.ParameterName]: !value }))
    setToggleStateConfirmationDialog(false);
    switchSettingObj = {}
  };


  const handleToggle = (e, setting) => {
    switchSettingObj['setting'] = setting;
    switchSettingObj['value'] = e.target.checked;
    setToggleEditMode(prev => ({ ...prev, [setting.ParameterName]: e.target.checked }))
    setToggleStateConfirmationDialog(true);
  }


  function getData(value) {
    if (value === null) {
      return
    }
    let objData = JSON.parse(value);
    if (objData === null) {
      return
    }
    return Object.entries(objData);
  };


  const getTicketStatus = (value) => {
    if (value === null) {
    }
    let ticketField = JSON.parse(value);
    for (let j in ticketField) {
      ticketField[j] = false;
    }
    return ticketField;
  }

  function saveSettings(setting, newValue) {
    try {
      let body = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newValue }

      switch (setting.InputType) {
        case "textfield":
          handleEditTextField(setting.ParameterName, false)
          break;
        case "checkbox":
          let settingVal = switchSettingObj.setting
          let value = switchSettingObj.value
          value = newValue
          setToggleEditMode(prev => ({ ...prev, [settingVal.ParameterName]: value }))
          break
        case "selectbox":
          handleEditTextField(setting.ParameterName, false)
          break
        case "json":
          break
        default:
          showErrorNotification('Not valid setting input', setting.InputType);

      }
      agentSettingsave(body);
    } catch (e) {
      showErrorNotification('error while saving settings')
    }
  }




  const getCheckedStatus = (setting) => {
    if (setting.ParameterValue === null) {
      if (setting.DefaultValue === 'false') {
        return false
      } else {
        return true;
      }
    } else {
      if (setting.ParameterValue === "true") {
        return true;
      } else {
        return false;
      }
    }
  };

  function getOption(value) {
    return value.split(',');
  }

  const AdminSettingTextElement = (props, index) => {
    let setting = props.setting;
    const [inputText, setInputText] = useState(setting.ParameterValue || setting.DefaultValue)
    return (
      <div className='settingField' style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'space-between' }}>
        <Typography className='admin-settings-text-font' flexGrow={1} fontSize="14px">{setting.ParameterName}</Typography>
        <div style={{ display: "flex" }}>
          {textEditModes[setting.ParameterName] && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Button
                onClick={() => { handleEditTextField(setting.ParameterName, false) }}
                sx={{ ...buttonStyle, width: '48px', marginRight: "10px", marginTop: "5px" }}
              >
                Discard
              </Button>
              <Button
                type='submit'
                variant="contained"
                onClick={(e) => { saveSettings(setting, inputText) }}
                sx={{ ...buttonStyle, marginTop: "5px" }}
              >
                Save
              </Button>
            </div>
          )}
          <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
            {!textEditModes[setting.ParameterName] && (
              <Button
                onClick={() => handleEditTextField(setting.ParameterName, true)}
                disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                sx={{
                  width: '15px',
                  height: '25px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#008DFF',
                  marginTop: "2px",
                  paddingTop: "12px",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  marginRight: "10px"
                }}>
                Edit
              </Button>
            )}
          </div>
          <div className='admin-settings-input-text'>
            {!textEditModes[setting.ParameterName] && ((((setting.ParameterValue) === null) || (setting.ParameterValue === "false") || (setting.ParameterValue === "")) ? <Typography sx={{ fontSize: '14px' }}>{setting.DefaultValue}</Typography> : <Typography sx={{ fontSize: '14px' }}>{setting.ParameterValue}</Typography>)}
          </div>
          {textEditModes[setting.ParameterName] && <TextField name={setting.ParameterName} id={setting.ParameterName} onChange={(e) => setInputText(e.target.value)} size='small' value={inputText} type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '10px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </div>
      </div>
    )
  }

  const AdminSettingSwitchElement = (props) => {
    let setting = props.setting;
    return (
      <div style={{
        justifyContent: 'space-between', display: "flex", paddingTop: "10px", paddingBottom: "10px", width: "100%"
      }}>
        <Typography style={{ marginLeft: '24px', marginRight: '24px', alignSelf: 'center', fontSize: '14px' }}>{setting.ParameterName}</Typography>
        <div style={{ alignSelf: 'center', marginRight: '24px', marginTop: "10px" }}>
          <SimpleSwitch
            disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
            name={setting.ParameterName}
            checked={toggleEditModes[setting.ParameterName]}
            onChange={(e) => handleToggle(e, setting)}
          />
        </div>
      </div>
    )
  }

  const AdminSettingSelectBoxElement = (props) => {
    let setting = props.setting;
    let paramInitData = { [setting.ParameterName]: setting.ParameterValue || setting.DefaultValue }
    return (
      <Formik
        initialValues={paramInitData}
        onSubmit={(values) => {
          saveSettings(setting, values[setting.ParameterName]);
        }}>
        {(props) => (
          <form onSubmit={props.handleSubmit}
            id='agentForm'
            className='ozcwScrollbar'
          >
            <div style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'end' }} className='settingField'>
              <Typography className='admin-settings-text-font' fontSize={"14px"} flexGrow={1}>{setting.ParameterName}</Typography>
              <div style={{ display: "flex", }}>
                {textEditModes[setting.ParameterName] && (
                  <div style={{ display: "flex", }}>
                    <Button
                      onClick={() => { handleEditTextField(setting.ParameterName, false) }}
                      sx={{ ...buttonStyle, width: '48px', marginRight: "10px", marginTop: "15px" }}
                    >
                      Discard
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ ...buttonStyle, marginTop: "15px" }}
                    >
                      Save
                    </Button>
                  </div>
                )}
                <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                  <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                    {!textEditModes[setting.ParameterName] && (
                      <Button
                        onClick={() => handleEditTextField(setting.ParameterName, true)}
                        disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                        sx={{
                          width: '15px',
                          height: '25px',
                          fontWeight: '400',
                          fontSize: '14px',
                          color: '#008DFF',
                          paddingTop: "11px",
                          paddingBottom: "10px",
                          marginBottom: "10px",
                          marginRight: "10px"
                        }}>
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
                <div className='admin-settings-input-text'>
                  {!textEditModes[setting.ParameterName] && (((setting.ParameterValue === null) || (setting.ParameterValue === "false")) ? <Typography sx={{ fontSize: '14px' }}>{setting.DefaultValue}</Typography> : <Typography sx={{ fontSize: '14px' }}>{setting.ParameterValue}</Typography>)}
                </div>
                {textEditModes[setting.ParameterName] && <div style={{
                  width: '200px', marginTop: '6px',
                  marginRight: '20px',
                }}> <OZSelect
                    placeholder="Select option"
                    name={setting.ParameterName}
                    options={getOption(setting.InputOptions)} />    </div>}
              </div>
            </div>
          </form>)}

      </Formik>
    )
  };


  const SalesForceJSONInputTextElement = (props) => {
    const onClickSave = (setting) => {
      let ticketFieldJSON = JSON.parse((setting.ParameterValue === null) || (setting.ParameterValue === "false")?setting.DefaultValue:setting.ParameterValue)
      ticketFieldJSON[props.inputTextTicket[0]] = ticketFieldText;
      let newvalueJSON = JSON.stringify(ticketFieldJSON);
      let apijsonBody = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newvalueJSON }
      agentSettingsave(apijsonBody);
    }
    const [ticketFieldText, setTicketFieldText] = useState(props.inputTextTicket[1]);
    return (
      <div className='settingField' >
        <Stack direction={"row"} bgcolor={"#FFFFFF"} justifyContent='end'>
          <Grid
            item
            xs
            sm={6}
            md={9}
            lg={12}
            className='admin-settings-text-font'>
            <label>{props.inputTextTicket[0]}</label>
          </Grid>
          <Grid item sx={{ paddingTop: '9px' }}>
            {salesForceTicketFields[props.inputTextTicket[0]] && (
              <Stack direction={"row"} spacing={2} sx={{ marginRight: '24px' }}>
                <Button
                  onClick={() => handleSalesforceTicketEdit(props.inputTextTicket[0], false)}
                  sx={{ ...buttonStyle, width: '48px' }}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => { onClickSave(props.setting) }}
                >
                  Save
                </Button>
              </Stack>
            )}
          </Grid>
          <div className='hide' style={{ alignSelf: 'center' }}>
            {!salesForceTicketFields[props.inputTextTicket[0]] && (
              <Button
                sx={{
                  width: '15px',
                  height: '25px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#008DFF',
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  marginRight: "10px"
                }}
                disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                onClick={() => handleSalesforceTicketEdit(props.inputTextTicket[0], true)}
              >
                Edit
              </Button>
            )}
          </div>
          {!salesForceTicketFields[props.inputTextTicket[0]] && (
            <Grid item className='admin-settings-input-text'>
              {(((props.inputTextTicket[1].length)) !== 0) ? props.inputTextTicket[1] : '---'}
            </Grid>)}
          {salesForceTicketFields[props.inputTextTicket[0]] && <TextField name={props.inputTextTicket[0]} value={ticketFieldText} onChange={(e) => { setTicketFieldText(e.target.value) }} size='small' type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '6px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </Stack>
        <Divider style={{ marginLeft: "24px", marginRight: "24px", border: "0.5px solid #F6F7F9" }} />
      </div>
    )
  };



  const FreshworksIntergrationJSONInputTextElement = (props) => {
    const onClickSave = (setting) => {
            let ticketFieldJSON = JSON.parse((setting.ParameterValue === null) || (setting.ParameterValue === "false")?setting.DefaultValue:setting.ParameterValue)
      ticketFieldJSON[props.inputTextTicket[0]] = ticketFieldText;
      let newvalueJSON = JSON.stringify(ticketFieldJSON);
      let apijsonBody = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newvalueJSON }
      agentSettingsave(apijsonBody);
    }
    const [ticketFieldText, setTicketFieldText] = useState(props.inputTextTicket[1]);
    return (
      <div className='settingField' >
        <Stack direction={"row"} bgcolor={"#FFFFFF"} justifyContent='end'>
          <Grid
            item
            xs
            sm={6}
            md={9}
            lg={12}
            className='admin-settings-text-font'>
            <label>{props.inputTextTicket[0]}</label>
          </Grid>
          <Grid item sx={{ paddingTop: '9px' }}>
            {textEditModes[props.inputTextTicket[0]] && (
              <Stack direction={"row"} spacing={2} sx={{ marginRight: '24px' }}>
                <Button
                  onClick={() => handleTicketEdit(props.inputTextTicket[0], false)}
                  sx={{ ...buttonStyle, width: '48px' }}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => { 
                    console.log("ticketfiex"+ticketFieldText)
                    console.log(props.setting)
                    onClickSave(props.setting) }}
                >
                  Save
                </Button>
              </Stack>
            )}
          </Grid>
          <div className='hide' style={{ alignSelf: 'center' }}>
            {!textEditModes[props.inputTextTicket[0]] && (
              <Button
                sx={{
                  width: '15px',
                  height: '25px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#008DFF',
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  marginRight: "10px"
                }}
                disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                onClick={() => handleTicketEdit(props.inputTextTicket[0], true)}
              >
                Edit
              </Button>
            )}
          </div>
          {!textEditModes[props.inputTextTicket[0]] && (
            <Grid item className='admin-settings-input-text'>
              {(((props.inputTextTicket[1].length)) !== 0) ? props.inputTextTicket[1] : '---'}
            </Grid>)}
          {textEditModes[props.inputTextTicket[0]] && <TextField name={props.inputTextTicket[0]} value={ticketFieldText} onChange={(e) => { console.log(e.target.value); setTicketFieldText(e.target.value) }} size='small' type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '6px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </Stack>
        <Divider style={{ marginLeft: "24px", marginRight: "24px", border: "0.5px solid #F6F7F9" }} />
      </div>
    )
  };

  const ZendeskIntergrationJSONInputTextElement = (props) => {
    const onClickSave = (setting) => {
      let ticketFieldJSON = JSON.parse((setting.ParameterValue === null) || (setting.ParameterValue === "false")?setting.DefaultValue:setting.ParameterValue)
      ticketFieldJSON[props.inputTextTicket[0]] = ticketFieldText;
      let newvalueJSON = JSON.stringify(ticketFieldJSON);
      let apijsonBody = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newvalueJSON }
      agentSettingsave(apijsonBody);
    }
    const [ticketFieldText, setTicketFieldText] = useState(props.inputTextTicket[1]);
    return (
      <div className='settingField' >
        <Stack direction={"row"} bgcolor={"#FFFFFF"} justifyContent='end'>
          <Grid
            item
            xs
            sm={6}
            md={9}
            lg={12}
            className='admin-settings-text-font'>
            <label>{props.inputTextTicket[0]}</label>
          </Grid>
          <Grid item sx={{ paddingTop: '9px' }}>
            {zendeskTicketFields[props.inputTextTicket[0]] && (
              <Stack direction={"row"} spacing={2} sx={{ marginRight: '24px' }}>
                <Button
                  onClick={() => handleZendeskTicketEdit(props.inputTextTicket[0], false)}
                  sx={{ ...buttonStyle, width: '48px' }}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => { onClickSave(props.setting) }}
                >
                  Save
                </Button>
              </Stack>
            )}
          </Grid>
          <div className='hide' style={{ alignSelf: 'center' }}>
            {!zendeskTicketFields[props.inputTextTicket[0]] && (
              <Button
                sx={{
                  width: '15px',
                  height: '25px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#008DFF',
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  marginRight: "10px"
                }}
                disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                onClick={() => handleZendeskTicketEdit(props.inputTextTicket[0], true)}
              >
                Edit
              </Button>
            )}
          </div>
          {!zendeskTicketFields[props.inputTextTicket[0]] && (
            <Grid item className='admin-settings-input-text'>
              {(((props.inputTextTicket[1].length)) !== 0) ? props.inputTextTicket[1] : '---'}
            </Grid>)}
          {zendeskTicketFields[props.inputTextTicket[0]] && <TextField name={props.inputTextTicket[0]} value={ticketFieldText} onChange={(e) => { setTicketFieldText(e.target.value) }} size='small' type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '6px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </Stack>
        <Divider style={{ marginLeft: "24px", marginRight: "24px", border: "0.5px solid #F6F7F9" }} />
      </div>
    )
  };

  const AdminSettingJSONElement = (props) => {
    let setting = props.setting;
    if (setting.ParameterName === "FreshWorks Ticket Fields") {
      return (
        <div>
          {setting && getData((((setting.ParameterValue) === null) || (setting.ParameterValue === "false")) ? (setting.DefaultValue) : (setting.ParameterValue)).map((item, index) => {
            return (
              <div key={index}>
                <FreshworksIntergrationJSONInputTextElement setting={setting} inputTextTicket={item} />
              </div>
            )
          })}
        </div>
      )
    }
    else if (setting.ParameterName === "SalesForce Task Fields") {
      return (
        <div>
          {setting && getData((((setting.ParameterValue) === null) || (setting.ParameterValue === "false")) ? (setting.DefaultValue) : (setting.ParameterValue)).map((item, index) => {
            return (
              <div key={index}>
                <SalesForceJSONInputTextElement setting={setting} inputTextTicket={item} />
              </div>
            )
          })}
        </div>
      )

    }else if (setting.ParameterName === "Salesforce Chat Fields") {
      return (
        <div>
          {setting && getData((((setting.ParameterValue) === null) || (setting.ParameterValue === "false")) ? (setting.DefaultValue) : (setting.ParameterValue)).map((item, index) => {
            return (
              <div key={index}>
                <SalesForceJSONInputTextElement setting={setting} inputTextTicket={item} />
              </div>
            )
          })}
        </div>
      )

    }
    else if (setting.ParameterName === "Zendesk Ticket Fields") {
      return (
        <div>
          {setting && getData((((setting.ParameterValue) === null) || (setting.ParameterValue === "false")) ? (setting.DefaultValue) : (setting.ParameterValue)).map((item, index) => {
            return (
              <div key={index}>
                <ZendeskIntergrationJSONInputTextElement setting={setting} inputTextTicket={item} />
              </div>
            )
          })}
        </div>
      )
    }
  }


  return (<div className='ozAdminSettings'>
    {toggleStateConfirmationDialog && <LockedAndStatusConfirmationDialog title={"Toggle State Confirmation"} content={"Are you Sure you want to change the Toggle State?"}
      open={toggleStateConfirmationDialog} updateFunction={updateSwitch} onClose={onCloseToggleConfirmationDialog} />}

    {((props.name).toLowerCase() === 'integration' ? IntegrationSettingsNameList : MiscellaneousSettingsList)
      ?.map((IntegrationSettingsName, index) => {
        return (
          <div key={index}>
            <Typography sx={SettingNameStyle}>{IntegrationSettingsName}</Typography>
            <Box style={{ borderRadius: '8px' }} sx={{
              width: "868px", background: '#FFFFFF', borderRadius: '8px', marginTop: '32px'
            }}>
              {settingData && settingData?.map((setting, index) => {
                if ((setting.SettingName) === IntegrationSettingsName)
                  return (
                    <div key={index}>
                      {setting.InputType === "textfield" && <AdminSettingTextElement setting={setting} />}
                      {setting.InputType === "checkbox" && <AdminSettingSwitchElement setting={setting} />}
                      {setting.InputType === "selectbox" && <AdminSettingSelectBoxElement setting={setting} />}
                      {setting.InputType === "json" && setting.ParameterName === "FreshWorks Ticket Fields" && <>
                        <Typography sx={{
                          height: '21px',
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: '#212121',
                          paddingLeft: '24px',
                          marginTop: '16px'
                        }} flexGrow={1} alignSelf='center'>{setting.ParameterName}</Typography>
                        <AdminSettingJSONElement setting={setting} /></>}
                      {setting.InputType === "json" && setting.ParameterName === "SalesForce Task Fields" && <>
                        <Typography sx={{
                          height: '21px',
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: '#212121',
                          marginTop: '16px',
                          paddingLeft: '24px',
                        }} flexGrow={1} alignSelf='center'>{setting.ParameterName}</Typography>
                        <AdminSettingJSONElement setting={setting} /></>}
                        {setting.InputType === "json" && setting.ParameterName === "Salesforce Chat Fields" && <>
                        <Typography sx={{
                          height: '21px',
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: '#212121',
                          marginTop: '16px',
                          paddingLeft: '24px',
                        }} flexGrow={1} alignSelf='center'>{setting.ParameterName}</Typography>
                        <AdminSettingJSONElement setting={setting} /></>}
                      {setting.InputType === "json" && setting.ParameterName === "Zendesk Ticket Fields" && <>
                        <Typography sx={{
                          height: '21px',
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '21px',
                          color: '#212121',
                          marginTop: '16px',
                          paddingLeft: '24px',
                        }} flexGrow={1} alignSelf='center'>{setting.ParameterName}</Typography>
                        <AdminSettingJSONElement setting={setting} /></>}
                      {(index !== settingData.length - 1) && (
                        <Divider style={{ marginLeft: "24px", marginRight: "24px", border: "0.5px solid #F6F7F9" }} />
                      )}
                    </div>
                  )
                else {
                  return null;
                }
              })}
            </Box>
          </div>
        )
      })}
  </div>);
}
export default IntegrationOrMiscellaneousSettings;

