import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Box, IconButton, Slider, Stack } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../../../static/icons/screen-recording.svg';
import { ReactComponent as PauseIcon } from '../../../../static/icons/rec-pause.svg';
import { ReactComponent as PlayIcon } from '../../../../static/icons/rec-play.svg';
import { ReactComponent as SkipBackIcon } from '../../../../static/icons/rec-rev.svg';
import { ReactComponent as SkipForwardIcon } from '../../../../static/icons/rec-forward.svg'
import { ReactComponent as FullScreeIcon } from '../../../../static/icons/rec-fullscreen.svg'
import { ReactComponent as CloseIcon } from '../../../../static/icons/closewidget.svg'

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

interface VideoPlayerProps {
  url: string;
}

interface VideoPlayerIconProps {
  row: {
    videoRecordingURL?: string;
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    opacity: '.2 !important'
  },
  '& .MuiPaper-root': {
    borderRadius: '12px',
    width: '566px',
    maxWidth: '566px',
    maxHeight: '493px',

  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: '#1D242D',
    paddingBottom: '0px'
  },
  '& .MuiDialogTitle-root': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }

}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  paddingTop: '5px',
  paddingBottom: '5px',
  color: '#99A0A8',
  height: 4,
  '& .MuiSlider-thumb': {
    width: 10,
    height: 10,
    background: 'conic-gradient(from 180deg at 50% 50%, #D9D9D9 0deg, #737373 360deg)',
    transition: '.4s cubic-bezier(.47,1.64,.41,.8)',
    '&::before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },

    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: .28,
  },

}));

// VideoPlayer Component
const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handleTimeUpdate = () => setCurrentTime(videoElement.currentTime);
    const handleLoadedMetadata = () => setDuration(videoElement.duration);
    const handleEnded = () => setIsPlaying(false);

    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  const togglePlay = () => {
    if (!videoRef.current) return;
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const skip = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime += seconds;
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  if (!url) {
    return (
      <Box display={'flex'} gap={1} justifyContent={'center'} p={4} >
        <Typography fontWeight={600} color={'white'}>Error:</Typography>
        <Typography fontWeight={400} color={'white'}>No video URL provided.</Typography>
      </Box>
    );
  }

  function showFullscreenDiv() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      return;
    }
    const fullscreenDiv = document.getElementById("ozScreenRecVideoPlayer");

    if (fullscreenDiv) {
      fullscreenDiv.requestFullscreen();
    } else {
      console.error("Fullscreen div not found.");
    }
  }

  return (
    <Box id="ozScreenRecVideoPlayer">
      <video ref={videoRef} style={{ width: '100%', height: '100%', borderRadius: '8px', border: '1px solid #D6D9DC' }}>
        <source src={url} type="video/mp4" />
      </video>

      <Box >
        <Stack sx={{ paddingLeft: '2px', paddingRight: '8px' }}>
          <CustomSlider
            aria-label="time-indicator"
            size="medium"
            value={currentTime}
            min={0}
            step={.1}
            max={duration}
            onChange={(_, value) => {
              setCurrentTime(value as number);
              if (videoRef.current) {
                videoRef.current.currentTime = parseFloat(value.toString());
              }
            }}

          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} ml={-1.5} mr={-1.5}>
          <Stack direction={'row'} >
            <IconButton onClick={togglePlay}  >
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
            <IconButton onClick={() => skip(-10)}>
              <SkipBackIcon />
            </IconButton>
            <IconButton onClick={() => skip(10)}>
              <SkipForwardIcon />
            </IconButton>

          </Stack>
          <Stack direction={'row'} alignItems={'center'} >
            <Typography color={'#B2BBC6'} fontWeight={400} fontSize={'12px'} mb={'8px'}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
            <IconButton onClick={() => showFullscreenDiv()} >
              <FullScreeIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </Box >
  );
};


const VideoPlayerIcon: React.FC<VideoPlayerIconProps> = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const videoUrl = row?.videoRecordingURL || '';

  function getFileName(url: any) {
    try {
      const pathname = new URL(url).pathname;

      const fileName = pathname.split('/').pop();

      const fileNameWithoutExtension = fileName ? fileName.split('.').slice(0, -1).join('.') : pathname;

      return fileNameWithoutExtension || fileName;

    } catch (e) {
      console.error("Failed to get Filename", e);
      return url;
    }
  }

  const videTitleStyle = useMemo(() => ({
    fontSize: '14px',
    color: '#000000',
    fontWeight: '400',
    backgroundColor: '#E6F4FF'
  }), []);

  return (
    <>
      {/* <IconButton onClick={() => setIsOpen(true)}>
        <DownloadIcon />
      </IconButton> */}


      {/* <button
        className="p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => setIsOpen(true)}
      >
        <Play className="h-5 w-5 text-gray-500" />
      </button> */}

      {/* {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <VideoPlayer url={videoUrl} />
        </Modal>
      )} */}


      <React.Fragment>
        <IconButton onClick={() => setIsOpen(true)}>
          <DownloadIcon />
        </IconButton>

        <BootstrapDialog
          onClose={() => setIsOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogTitle sx={{
            ...videTitleStyle,
            m: 0, p: 1,
          }} id="customized-dialog-title">

            {videoUrl ? <Box display={'flex'} gap={.8}>
              Viewing
              {
                <Typography sx={{
                  ...videTitleStyle,
                  fontSize: '12px',
                  paddingTop: '3px',
                  fontWeight: '500',
                }}>{getFileName(videoUrl)}</Typography>
              }

              screen
            </Box> : <Box display={'flex'} gap={.8}>Video Player</Box>}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpen(false)}
            size='small'
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 5,
            })}
          >
            <CloseIcon width={18} height={18} />
          </IconButton>
          <DialogContent dividers>
            <VideoPlayer url={videoUrl} />
          </DialogContent>

        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default VideoPlayerIcon;