import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { Typography, Button, TextField } from "@mui/material";
import "./AgentSettings.css";
import SimpleSwitch from "../../../components/admin/common/OZSwitch/SimpleSwitch";
import { getSettingsList, settingsSave } from "../../../services/api-service";
import Divider from '@mui/material/Divider';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { Formik } from "formik";
import OZSelect from "../../../components/admin/common/OZSelect";
import LockedAndStatusConfirmationDialog from "../../../components/admin/common/OZLockedAndStatusDialog";
import { NewAdminPages, newAuthSubAdminAccess } from "../../../services/page-service";

let switchSettingObj = {};
function AgentSettings(props) {
  const [settingData, setSettings] = useState([]);
  const [toggleEditModes, setToggleEditMode] = useState({});
  const [textEditModes, setTextEditMode] = useState({});
  const [toggleStateConfirmationDialog, setToggleStateConfirmationDialog] = useState(false);


  useEffect(() => {
    getSettings();
  }, []);

  let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '27px', fontSize: '14px', width: '76px', fontWeight: 400 }), [])

  async function getSettings() {
    try {
      getSettingsList()
        .then((resp) => {
          let data = {}
          setSettings(resp.Data);
          resp.Data.forEach((item) => {
            return data[item.ParameterName] = getCheckedStatus(item)
          })
          setToggleEditMode(data);
          let newdata = {}
          setSettings(resp.Data);
          resp.Data.forEach((newitem) => {
            return newdata[newitem.ParameterName] = false;
          })
          setTextEditMode(newdata);
        })
        .catch((e) => {
          setSettings([]);
        });
    } catch (e) {
      showErrorNotification("error ");
    }
  };


  async function agentSettingsave(data) {
    try {
      settingsSave(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification("Setting UserParameter has been updated successfully.")
            getSettings();
          }
          else {
            showWarningNotification(resp.Message)
          }
        })
        .catch((e) => {
          showErrorNotification(e)
        });
    } catch (e) {
      showErrorNotification(e);

    }
  };



  const updateSwitch = () => {
    let setting = switchSettingObj.setting;
    let value = switchSettingObj.value;
    saveSettings(setting, value)
    setToggleStateConfirmationDialog(false);
    switchSettingObj = {}
  }


  const handleEditTextField = (settingName, value) => {
    setTextEditMode(prev => ({ ...prev, [settingName]: value }))
  };



  const saveURLElementSetting = (setting, inputText, selectedvalue) => {
    handleEditTextField(setting.ParameterName, false)
    handleEditSelectBoxField(setting.ParameterName, false)
    let dataSendApi = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": inputText, "requestType": selectedvalue, "inputType": "URL" }
    agentSettingsave(dataSendApi);
  };

  const handleEditSelectBoxField = (settingName, value) => {
    setTextEditMode(prev => ({ ...prev, [settingName]: value }))
  };

  const onCloseToggleConfirmationDialog = () => {
    let setting = switchSettingObj.setting
    let value = switchSettingObj.value
    setToggleEditMode(prev => ({ ...prev, [setting.ParameterName]: !value }))
    setToggleStateConfirmationDialog(false);
    switchSettingObj = {}
  };


  const handleToggle = (e, setting) => {
    switchSettingObj['setting'] = setting;
    switchSettingObj['value'] = e.target.checked;
    setToggleEditMode(prev => ({ ...prev, [setting.ParameterName]: e.target.checked }))
    setToggleStateConfirmationDialog(true);
  }

  const saveSettings = (setting, newValue) => {
    try {
      let body = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newValue }
      let urlBody = { "parameter": { "id": setting.ParameterID, "code": setting.ParameterCode, "name": setting.ParameterName }, "parameterValue": newValue }
      switch (setting.InputType) {
        case "textfield":
          handleEditTextField(setting.ParameterName, false)
          break;
        case "selectbox":
          handleEditSelectBoxField(setting.ParameterName, false)
          break
        case "checkbox":
          let settingVal = switchSettingObj.setting
          let value = switchSettingObj.value
          value = newValue
          setToggleEditMode(prev => ({ ...prev, [settingVal.ParameterName]: value }))
          break;
        case "URL":
          handleEditTextField(setting.ParameterName, false)
          handleEditSelectBoxField(setting.ParameterName, false)
          break;
        default:
          showErrorNotification('Not valid setting input', setting.InputType);
      }
      if (setting.InputType !== "URL") {
        agentSettingsave(body);
      }
      else {
        agentSettingsave(urlBody);
      }

    } catch (e) {
      showErrorNotification('error while saving settings')
    }
  }




  const getCheckedStatus = (setting) => {
    if (setting.ParameterValue === null) {
      if (setting.DefaultValue === 'false') {
        return false
      } else {
        return true;
      }
    } else {
      if (setting.ParameterValue === "true") {
        return true;
      } else {
        return false;
      }
    }
  };


  function getOption(value) {
    return value.split(',');
  }


  const AgentSettingURLElement = (props, index) => {
    let setting = props.setting;
    let paramInitData = { [setting.ParameterName]: setting.RequestType || setting.DefaultRequestType }
    const [inputText, setInputText] = useState(setting.ParameterValue || setting.DefaultValue)
    return (
      <Formik
        initialValues={paramInitData}
        onSubmit={(values) => {
          saveURLElementSetting(setting, inputText, values[setting.ParameterName]);
        }}>
        {(props) => (
          <form onSubmit={props.handleSubmit}
            id='agentForm'
            className='ozcwScrollbar'
          >
            <div style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'end',gap: "30px" }} className='settingField'>
              <Typography className='admin-settings-text-font' fontSize={"14px"} minWidth={"120px"} flexGrow={1}>{setting.ParameterName}</Typography>
              <div style={{ display: "flex", }}>
                {textEditModes[setting.ParameterName] && (
                  <div style={{ display: "flex", }}>
                    <Button
                      onClick={() => { handleEditTextField(setting.ParameterName, false) }}
                      sx={{ ...buttonStyle, width: '48px', marginRight: "10px", marginTop: "15px" }}
                    >
                      Discard
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ ...buttonStyle, marginTop: "15px" }}
                    >
                      Save
                    </Button>
                  </div>
                )}
                <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                  <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                    {!textEditModes[setting.ParameterName] && (
                      <Button
                        disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                        onClick={() => handleEditTextField(setting.ParameterName, true)}
                        sx={{
                          width: '15px',
                          height: '25px',
                          fontWeight: '400',
                          fontSize: '14px',
                          color: '#008DFF',
                          paddingTop: "11px",
                          paddingBottom: "10px",
                          marginBottom: "10px",
                          marginRight: "10px"
                        }}>
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
                <div className='admin-settings-input-text' style={{ display: 'flex',gap: '2px',marginLeft: '33px'}}>
                  {!textEditModes[setting.ParameterName] && ((setting.RequestType === null) ? <Typography sx={{ fontSize: '14px' }}>[{setting.DefaultRequestType}]</Typography> : <Typography sx={{ fontSize: '14px' }}>[{setting.RequestType}]</Typography>)}
                  {!textEditModes[setting.ParameterName] && ((setting.ParameterValue === null) ? <Typography sx={{ fontSize: '14px', paddingLeft: '2px' }}>{setting.DefaultValue}</Typography> : <Typography sx={{ fontSize: '14px',wordBreak: 'break-all' }}>{setting.ParameterValue}</Typography>)}
                </div>

                {textEditModes[setting.ParameterName] && (<>
                  <div style={{ display: 'flex' }}>
                    <TextField name={setting.ParameterName} id={setting.ParameterName} onChange={(e) => setInputText(e.target.value)} size='small' value={inputText} type="url" sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#ffff',
                        fontSize: '14px',
                        height: '36px',
                        marginRight: '24px',
                        marginTop: '10px',
                        marginBottom: '6px',
                        width: '200px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #D6D6D7'
                      },
                      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: '#D6D6D7',
                        border: '1px solid #D6D6D7'
                      }
                    }} />
                  </div>
                  <div style={{
                    width: '100px', marginTop: '6px',
                    marginRight: '20px',
                  }}>
                    <OZSelect
                      placeholder="Select option"
                      name={setting.ParameterName}
                      options={['GET', 'POST']} />
                  </div></>)}
              </div>
            </div>
          </form>)}
      </Formik>

    )
  }


  const AdminSettingTextElement = (props, index) => {
    let setting = props.setting;
    const [inputText, setInputText] = useState(setting.ParameterValue || setting.DefaultValue)
    return (
      <div className='settingField' style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'space-between' }}>
        <Typography className='admin-settings-text-font' flexGrow={1} fontSize="14px">{setting.ParameterName}</Typography>
        <div style={{ display: "flex" }}>
          {textEditModes[setting.ParameterName] && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Button
                onClick={() => { handleEditTextField(setting.ParameterName, false) }}
                sx={{ ...buttonStyle, width: '48px', marginRight: "10px", marginTop: "5px" }}
              >
                Discard
              </Button>
              <Button
                type='submit'
                variant="contained"
                onClick={(e) => { saveSettings(setting, inputText) }}
                sx={{ ...buttonStyle, marginTop: "5px" }}

              >
                Save
              </Button>
            </div>
          )}
          <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
            {!textEditModes[setting.ParameterName] && (
              <Button
                onClick={() => handleEditTextField(setting.ParameterName, true)}
                disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                sx={{
                  width: '15px',
                  height: '25px',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#008DFF',
                  marginTop: "2px",
                  paddingTop: "12px",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  marginRight: "10px"
                }}>
                Edit
              </Button>
            )}
          </div>

          <div className='admin-settings-input-text'>
            {!textEditModes[setting.ParameterName] && ((setting.ParameterValue === null || setting.ParameterValue === "") ? <Typography sx={{ fontSize: '14px' }}>{setting.DefaultValue}</Typography> : <Typography sx={{ fontSize: '14px' }}>{setting.ParameterValue}</Typography>)}
          </div>
          {textEditModes[setting.ParameterName] && <TextField name={setting.ParameterName} id={setting.ParameterName} onChange={(e) => setInputText(e.target.value)} size='small' value={inputText} type='text' sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '36px',
              marginRight: '24px',
              marginTop: '10px',
              marginBottom: '6px',
              width: '200px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              backgroundColor: '#D6D6D7',
              border: '1px solid #D6D6D7'
            }
          }} />}
        </div>
      </div>
    )
  }


  const AdminSettingSwitchElement = (props) => {
    let setting = props.setting;

    return (
      <div style={{
        justifyContent: 'space-between', display: "flex", paddingTop: "10px", paddingBottom: "10px", width: "100%"
      }}>
        <Typography style={{ marginLeft: '24px', marginRight: '24px', alignSelf: 'center', fontSize: '14px' }}>{setting.ParameterName}</Typography>
        <div style={{ alignSelf: 'center', marginRight: '24px', marginTop: "10px" }}>
          <SimpleSwitch
            disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
            name={setting.ParameterName}
            checked={toggleEditModes[setting.ParameterName]}
            onChange={(e) => handleToggle(e, setting)}
          />
        </div>
      </div>

    )
  }

  const AdminSettingSelectBoxElement = (props) => {
    let setting = props.setting;
    let paramInitData = { [setting.ParameterName]: setting.ParameterValue || setting.DefaultValue }
    return (
      <Formik
        initialValues={paramInitData}
        onSubmit={(values) => {
          saveSettings(setting, values[setting.ParameterName]);
        }}>
        {(props) => (
          <form onSubmit={props.handleSubmit}
            id='agentForm'
            className='ozcwScrollbar'
          >
            <div style={{ display: "flex", bgcolor: "#FFFFFF", justifyContent: 'end' }} className='settingField'>
              <Typography className='admin-settings-text-font' fontSize={"14px"} flexGrow={1}>{setting.ParameterName}</Typography>
              <div style={{ display: "flex", }}>
                {textEditModes[setting.ParameterName] && (
                  <div style={{ display: "flex", }}>
                    <Button
                      onClick={() => { handleEditTextField(setting.ParameterName, false) }}
                      sx={{ ...buttonStyle, width: '48px', marginRight: "10px", marginTop: "15px" }}
                    >
                      Discard
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ ...buttonStyle, marginTop: "15px" }}
                    >
                      Save
                    </Button>
                  </div>
                )}
                <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                  <div className='hide' style={{ alignSelf: 'center', paddingTop: '5px' }}>
                    {!textEditModes[setting.ParameterName] && (
                      <Button
                        disabled={newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View')}
                        onClick={() => handleEditSelectBoxField(setting.ParameterName, true)}
                        sx={{
                          width: '15px',
                          height: '25px',
                          fontWeight: '400',
                          fontSize: '14px',
                          color: '#008DFF',
                          paddingTop: "11px",
                          paddingBottom: "10px",
                          marginBottom: "10px",
                          marginRight: "10px"
                        }}>
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
                <div className='admin-settings-input-text'>
                  {!textEditModes[setting.ParameterName] && ((setting.ParameterValue === null) ? <Typography sx={{ fontSize: '14px' }}>{setting.DefaultValue}</Typography> : <Typography sx={{ fontSize: '14px' }}>{setting.ParameterValue}</Typography>)}
                </div>

                {textEditModes[setting.ParameterName] && <div style={{
                  width: '200px', marginTop: '6px',
                  marginRight: '20px',
                }}> <OZSelect
                    placeholder="Select option"
                    name={setting.ParameterName}
                    options={getOption(setting.InputOptions)} />    </div>}
              </div>
            </div>
          </form>)}
      </Formik>
    )
  };

  return (
    <div className="ozAgentSettings" >
      {toggleStateConfirmationDialog && <LockedAndStatusConfirmationDialog title={"Toggle State Confirmation"} content={"Are you Sure you want to change the Toggle State?"}
        open={toggleStateConfirmationDialog} updateFunction={updateSwitch} onClose={onCloseToggleConfirmationDialog} />}

      <Typography
        sx={{
          height: "14px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "14px",
          marginBottom: "10px",
          color: "#212121",
        }}
        flexGrow={1}
        alignSelf="center"
      >
        Settings / Agent Settings
      </Typography>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "28px",
          marginBottom: '24px'
        }}
        flexGrow={1}
        alignSelf="center"
      >
        Agent Settings
      </Typography>
      <Box style={{ borderRadius: '8px' }} sx={{
        width: "868px", background: '#FFFFFF', borderRadius: '8px', marginTop: '16px'
      }}>
        {settingData && settingData?.map((setting, index) => {
          if ((setting.SettingName) === "Agent Settings")
            return (
              <div key={index}>
                {setting.InputType === "textfield" && <AdminSettingTextElement setting={setting} />}
                {setting.InputType === "checkbox" && <AdminSettingSwitchElement setting={setting} toggleObject={props.toggleObject} />}
                {setting.InputType === "selectbox" && <AdminSettingSelectBoxElement setting={setting} />}
                {setting.InputType === "URL" && <AgentSettingURLElement setting={setting} />}
                {(index !== settingData.length - 1) && (
                  <Divider style={{ marginLeft: "24px", marginRight: "24px", border: "0.5px solid #F6F7F9" }} />
                )}
              </div>
            )
          else {
            return null;
          }
        })}
      </Box>
    </div>
  );
}
export default AgentSettings;



