import React, { useState, useMemo, useEffect } from 'react';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography, Stack, Step, Stepper, StepLabel, Divider, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import Box from '@mui/material/Box';
import OZSelect from '../../../components/admin/common/OZSelect';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { addNewCampaignInbound, addNewMarketingFormDigitalCampaign, digitalCampaignsDetails, getCampaignListForAdmin, getSecondaryDidDropdown, removeDigitalCampaignsIntegrations, updateCamapignInbound, updateMarketingDigitalCampaign } from '../../../services/api-service';
import {
    getSkillsDropdown, getSubUserDropdown, getBlockNumberGroupDropdown, getOnHoldMusicDropdown,
    getDispositionsDropdown, getIvrFlowDropdown, getIvrFlowChatDropdown, getPluginDropdown
} from '../../../services/api-service';
import OZRichText from '../../../components/admin/common/OZRichText/OZRichText';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from "@mui/lab/TabList";
import PopupState from 'material-ui-popup-state';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import CopyCampaignForm from './CopyCampaignForm';
import LockedAndStatusConfirmationDialog from '../../../components/admin/common/OZLockedAndStatusDialog';
import { checkRoles, checkSettings } from '../../../utils/util';
import { authPage, AdminPages, newAuthSubAdminAccess, NewAdminPages, newAuthPages } from '../../../services/page-service';
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";
import { enableIntegrationById, disableIntegrationById } from '../../../services/api-service';
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import WhatsAppIntegrationForm from './WhatsAppIntegrationForm';
import EditDigitalCampaignWhatsAppIntegration from './EditDigitalCampaignWhatsAppIntegration';
import AddAndEditDigitalCampaignSmsIntegration from './AddAndEditDigitalCampaignSmsIntegration';
import AddAndEditDigitalCampaignFacebookIntegration from './AddAndEditDigitalCampaignFacebookIntegration';
import AddAndEditDigitalCampaignTwitterIntegration from './AddAndEditDigitalCampaignTwitterIntegration';
import jwt from 'jwt-decode';

const CampaignInboundForm = (props) => {

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add

    const [mode, setMode] = useState(initMode);
    const [value, setValue] = useState('0');

    const validationSchema = useMemo(() => (yup.object({
        campaignName: yup
            .string("Enter Campaign Name")
            .when('campaignType', {
                is: type => type === 'Chat',
                then: yup
                    .string()
                    .min(2, "Campaign Name should be between 2 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
                    .max(50, "Campaign Name should be between 2 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
                    .matches(/^([A-Za-z0-9]((\.|@|_|-)?[A-Za-z0-9])+)$/, "Campaign Name should be between 2 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
                    .required("Campaign Name is required"),
                otherwise: yup
                    .string()
                    .notRequired()
                    .when('campaignType', {
                        is: type => type === 'InBound',
                        then: yup
                            .string()
                            .min(2, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
                            .max(255, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
                            .matches(/^([A-Za-z0-9]((\.|@|_|-)?[A-Za-z0-9])+)$/, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
                            .required("Campaign Name is required"),
                        otherwise: yup
                            .string()
                            .notRequired(),
                    })

            }),
        campaignType: yup
            .string()
            .required("Campaign Type is required"),
        fallbackDID: yup
            .string().matches(/^(None|(\+)?[0-9]{3,16})$/, "FallBack DID should be between 3 to 16 digits long and allows prefix +")
            .test("maxDigits",
                "FallBack DID should be between 3 to 16 digits long and allows prefix +",
                (number) => number === 'None' || (String(number).length >= 3 && String(number).length <= 17))
            .nullable(),
        dialCount: yup
            .number('Daily Outbound Limit per Number should be a Number.')
            .typeError("Daily Outbound Limit per Number should be a Number.")
            .when('allowedManual', {
                is: type => type === true && checkRoles("ROLE_OBD_RESTRICTION"),
                then: yup.number('Daily Outbound Limit per Number is required.')
                    .min(1, 'Daily Outbound Limit per Number between 1 and 99.')
                    .max(99, 'Daily Outbound Limit per Number between 1 and 99.')
                    .typeError('Daily Outbound Limit per Number should be a Number.')
                    .nullable(),
                otherwise: yup.number('Daily Outbound Limit per Number is required.').notRequired().nullable()
            }),
        did: yup
            .string()
            .when("campaignType", {
                is: type => type === 'Chat',
                then: yup
                    .string("Enter Campaign Identifier")
                    .nullable()
                    .test("maxDigits",
                        "Campaign Identifier should be between 4 to 12 digits long and allows prefix +",
                        (number) => String(number).length >= 4 && String(number).length <= 13)
                    .matches(/^(\+)?[0-9]{0,12}$/, 'Campaign Identifier should be between 4 to 12 digits long and allows prefix +')
                    .required("Campaign Identifier is required"),
                otherwise: yup
                    .string()
                    .notRequired()
                    .when("campaignType", {
                        is: type => type === 'InBound',
                        then: yup
                            .string("Enter DID")
                            .test("maxDigits",
                                "DID should be between 3 to 16 digits long and allows prefix +",
                                (number) => String(number).length >= 3 && String(number).length <= 17)
                            .matches(/^(\+)?[0-9]{3,16}$/, "DID should be between 3 to 16 digits long and allows prefix +")
                            .required('DID is required'),
                        otherwise: yup
                            .string()
                            .notRequired(),
                    })
            }),
        ruleNot: yup
            .number()
            .typeError('The value must be a number')
            .when('campaignType', {
                is: id => id === 'Chat',
                then: yup
                    .number()
                    .min(1, 'Number of concurrent chats should be between 1 and 20.')
                    .max(20, 'Number of concurrent chats should be between 1 and 20.')
                    .typeError('The value must be a number')
                    .integer('The value must be a number')
                    .required('Number of concurrent chats is required.'),
                otherwise: yup.number().notRequired().nullable()
            }),
        disclaimer: yup
            .string()
            .when('allowedManual', {
                is: type => type === true,
                then: yup
                    .string()
                    .min(5, "Disclaimer Should start with http|https and Ends with .wav/.mp3 etc and atleast one character to be contain in between and allows Max Length of 255 characters")
                    .max(255, "Disclaimer Should start with http|https and Ends with .wav/.mp3 etc and atleast one character to be contain in between and allows Max Length of 255 characters")
                    .matches(/^(https?:\/\/)(.{1,255}\.)[a-zA-Z0-9]+$/, "Disclaimer Should start with http|https and Ends with .wav/.mp3 etc and atleast one character to be contain in between and allows Max Length of 255 characters"),
                otherwise: yup.string().notRequired()
            }),

        dispositions: yup
            .array()
            .of(yup.object())
            .required("Dispositions is required.")
            .min(1, "Dispositions field must have at least 1 item"),
        callPrefix: yup
            .string()
            .matches(/^[0-9+][0-9]{0,5}$/, "Call Prefix should be between 1 to 6 digits long and allows prefix +")
            .nullable(),
        skills: yup
            .array()
            .of(yup.object().shape({
                id: yup.number().required("Skills is required"),
                skillName: yup.string().required("skills is required"),
            }))
            .required("Skills is required")
            .min(1, "Skills field must have at least 1 item "),
        blockNumberGroups: yup
            .array()
            .when('blockNumber.id', {
                is: id => id === 'Allow' || id === 'Block',
                then: yup
                    .array()
                    .of(yup.object().shape({
                        id: yup.number().required("Block Number Group is required."),
                        name: yup.string().required("Block Number Group is required."),
                    }))
                    .min(1, "Block Number Group must have at least 1 item")
                    .required(),
                otherwise: yup
                    .array()
                    .notRequired()
                    .nullable()
            }),
        sla: yup
            .number()
            .typeError("Wrapup Time (in Sec) is required")
            .when(['dispositionType.id', 'campaignType'], {
                is: (id, campaignType) =>
                    (campaignType === 'InBound' && (id === 'API' || id === 'Toolbar')),
                then: yup
                    .number()
                    .min(0, "Wrapup Time should be between 0 to 9999")
                    .max(9999, "Wrapup Time should be between 0 to 9999")
                    .typeError('Wrapup Time should be a Number')
                    .required("Wrapup Time (in Sec) is required.")
                    .nullable(),
                otherwise: yup.number().notRequired().nullable()
            }),
        ivrFlowChat: yup
            .object()
            .when('campaignType', {
                is: id => id === 'Chat',
                then: yup
                    .object()
                    .nullable()
                    .required("IVR Flow is required."),
                otherwise: yup.object().notRequired().nullable()
            }),
        //popUrlAt?.id === "Plugin"
        screenPopUrlInBound: yup
            .object()
            .when(['popUrlAt.id', 'campaignType'], {
                is: (id, campaignType) =>
                    (campaignType === 'InBound' && (id === 'Plugin')),
                then: yup
                    .object()
                    .nullable()
                    .required("Plugin Name is required."),
                otherwise: yup.object().notRequired().nullable()
            }),
        transferSkills: yup
            .array()
            .when('isTransferSkill', {
                is: value => value === true || value === 'true',
                then: yup
                .array()
                .of(yup.object().shape({
                    id: yup.number().required("Select values for Transfer Skills"),
                    skillName: yup.string().required("Select values for Transfer Skills"),
                }))
                .required("Select values for Transfer Skills")
                .min(1, "Select values for Transfer Skills"),
                otherwise: yup.array().notRequired().nullable()
            }),
        script: yup
            .string()
            .when('campaignType', {
                is: type => type !== 'Chat',
                then: yup
                .string()
                .test('is-html-length-valid','Script character length cannot exceed 7,000 characters and should not include icons or emojis.',(value) => {
                    if (!value) return true;
                    const regex = /^[\u0000-\u00FF]*$/;
                    const isLatin1Text = regex.test(value);
                    if (!isLatin1Text) {
                        return false;
                    }
                    const parser = new DOMParser();
                    const parsedHtml = parser.parseFromString(value, 'text/html');
                    const textContent = parsedHtml.documentElement.textContent;
                    // const textContent = parsedHtml.documentElement.textContent.trim().replace(/\s+/g, ' ');
                    return textContent.length <= 7000;
                }),
                otherwise: yup.string().notRequired().nullable()
            }),
    })), []);

    const [copyCampaign, setCopyCampaign] = useState(false);
    const [skillOption, setSkillOption] = useState([]);
    const [ivrFlowOption, setIvrflowOption] = useState([]);
    const [subUserOption, setSubUserOption] = useState([]);
    const [blockNumberGroupOption, setBlockNumberGroupOption] = useState([]);
    const [onHoldMusicOption, setOnHoldMusicOption] = useState([]);
    const [dispositonsOption, setDispositonsOption] = useState([]);
    const [secondaryFallbackDidOption, setSecondaryFallBackDidOption] = useState([]);
    const [secondaryDidOption, setSecondaryDidOption] = useState([]);
    const [secondaryDidOptionFullList, setSecondaryDidOptionFullList] = useState([]);

    const [ivrFlowChatOption, setIvrflowChatOption] = useState([]);
    const [pluginOption, setpluginOption] = useState([]);
    const [loading, setLoading] = useState((mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? true : false);
    const [newLoading, setNewLoading] = useState(false);

    const [digitalCampaignIntegrationDetails, setDigitalCampaignIntegrationDetails] = useState([])
    const [alreadyAssignedDIDsOFWhatsApp, setAlreadyAssignedDIDsOfWhatsApp] = useState([])
    const [alreadyAssignedDIDsOFSms, setAlreadyAssignedDIDsOfSms] = useState([])

    console.log(alreadyAssignedDIDsOFWhatsApp);
    console.log(alreadyAssignedDIDsOFSms);

    const closeCampaignInboundForm = () => {
        // localStorage.clear();
        localStorage.removeItem("InboundForm");
        props.updateCampaignInbound();
        props.onClose();
    }

    useEffect(() => {
        if (mode !== ADMIN_FORM_MODES.view) {
            getSkillsOptions()
            getIvrFlowOptions()
            getSubUserOptions()
            getBlocknumberGroupOptions()
            getOnHoldMusicOptions()
            getDispositionsOptions()
            getIvrFlowChatOptions()
            // getPluginOptions()
        }
        getSecondaryDidOptions()
        getPluginOptions()
    }, [mode])

    useEffect(() => {
        if (mode !== ADMIN_FORM_MODES.add && props.data.campaignType === "Chat") {
            getDigitalCampaignDetails()
        }
    }, [])

    async function getSecondaryDidOptions() {
        setNewLoading(true);
        try {
            getSecondaryDidDropdown().then(resp => {
                setSecondaryDidOptionFullList(resp?.Data !== null ? JSON.parse(resp.Data) : [])
                const newDIArray = resp.Data !== null ? JSON.parse(resp.Data).map(item => item?.DID) : [];
                const newFallBackDidArray = resp.Data !== null ? ["None", ...JSON.parse(resp.Data).map(item => item?.DID)] : [];
                setSecondaryDidOption(newDIArray);
                setSecondaryFallBackDidOption(newFallBackDidArray)
                setNewLoading(false);
            }).catch(e => {
                setSecondaryDidOption([]);
                showErrorNotification('No Secondary DID Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function addCampaignInboundForm(data) {
        console.log("addCampaignInboundForm", data)
        data.popUrlAt = data.popUrlAt.value;
        if (data.dispositionType.value === -1) {
            data.sla = '0';
        }
        data.dispositionType = data.dispositionType.value;
        data.blockNumber = data.blockNumber.value;
        if (data.recOn !== null) {
            data.recOn = data.recOn.value;
        }
        if (data.screenPopUrl === null) {
            data.screenPopUrl = "";
            delete data.screenPopUrlInBound;
        }
        if (data.holdMusic !== null) {
            data.holdMusic = data.holdMusic.name;
        }
        if (data.transferMusic !== null) {
            data.transferMusic = data.transferMusic.name;
        }
        try {
            addNewCampaignInbound(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign is Created Successfully`);
                    props.updateCampaignInbound();
                    closeCampaignInboundForm();
                    setNewLoading(false);
                } else {
                    // showErrorNotification(JSON.stringify(resp.Message));
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                }

            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function updateCampaignInboundForm(data, id) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.dispositionType.value === -1) {
            data.sla = '0';
        }
        data.dispositionType = data.dispositionType.value;
        data.blockNumber = data.blockNumber.value;
        if (data.recOn !== null) {
            data.recOn = data.recOn.value;
        }
        if (data.screenPopUrl === null) {
            data.screenPopUrl = "";
            delete data.screenPopUrlInBound;
        }
        if (data.holdMusic !== null) {
            data.holdMusic = data.holdMusic.name;
        }
        if (data.transferMusic !== null) {
            data.transferMusic = data.transferMusic.name;
        }
        try {
            updateCamapignInbound(data, id).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign is Updated Successfully`);
                    props.updateCampaignInbound();
                    closeCampaignInboundForm();
                    setNewLoading(false);
                } else {
                    // showErrorNotification(JSON.stringify(resp.Message));
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function addInboundFormDigitalCampaign(data) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.dispositionType.value === -1) {
            data.sla = '0';
        }
        data.dispositionType = data.dispositionType.value;
        if (data.screenPopUrl === null) {
            data.screenPopUrl = "";
        }
        data.isTransferSkill = false;
        try {
            addNewMarketingFormDigitalCampaign(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign is Created Successfully`);
                    // props.updateCampaignMarketing();
                    props.updateCampaignInbound();
                    closeCampaignInboundForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function updateInboundDigitalCampaignForm(data, id) {
        if (data.dispositionType.value === -1) {
            data.sla = '0';
        }
        data.dispositionType = data.dispositionType.value;
        data.popUrlAt = data.popUrlAt.value;
        if (data.screenPopUrl === null) {
            data.screenPopUrl = "";
        }
        data.isTransferSkill = false;
        try {
            updateMarketingDigitalCampaign(data, id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`${data.campaignName} Campaign is Updated Successfully`);
                    // props.updateCampaignMarketing();
                    props.updateCampaignInbound();
                    closeCampaignInboundForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }

            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSkillsOptions() {
        setNewLoading(true);
        try {
            getSkillsDropdown().then(resp => {
                setSkillOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setSkillOption([]);
                showErrorNotification('No Skill Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getIvrFlowOptions() {
        setNewLoading(true);
        try {
            getIvrFlowDropdown().then(resp => {
                setIvrflowOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setIvrflowOption([]);
                showErrorNotification('No IVRFlow Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getIvrFlowChatOptions() {
        setNewLoading(true);
        try {
            getIvrFlowChatDropdown().then(resp => {
                setIvrflowChatOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No IvrFlow Chat, Check your Internet Connection');
                setIvrflowChatOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getPluginOptions() {
        setNewLoading(true);
        try {
            getPluginDropdown().then(resp => {
                setpluginOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Plugin Name, Check your Internet Connection');
                setpluginOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSubUserOptions() {
        setNewLoading(true);
        try {
            getSubUserDropdown().then(resp => {
                setSubUserOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setSubUserOption([]);
                showErrorNotification('No User Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getBlocknumberGroupOptions() {
        setNewLoading(true);
        try {
            getBlockNumberGroupDropdown().then(resp => {
                setBlockNumberGroupOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No BlockNumber Group Data, Check your Internet Connection');
                setBlockNumberGroupOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getOnHoldMusicOptions() {
        setNewLoading(true);
        try {
            getOnHoldMusicDropdown().then(resp => {
                setOnHoldMusicOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Hold Music Data, Check your Internet Connection');
                setOnHoldMusicOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getDispositionsOptions() {
        setNewLoading(true);
        try {
            getDispositionsDropdown().then(resp => {
                setDispositonsOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Dispostions Data, Check your Internet Connection');
                setDispositonsOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getCampaign() {
        setNewLoading(true);
        let didAssignedList = []
        try {
            getCampaignListForAdmin().then(resp => {
                setNewLoading(false);
                (resp.Data).map((item) => {
                    if (item.campaignType === "Chat" && item.campaignId !== props.data.campaignId) {
                        digitalCampaignsDetails(item.campaignId).then(response => {
                            if (response.Status === "Success") {
                                if (response.Data.length > 0) {
                                    response.Data.map((items) => {
                                        if (items.integration.name === "SmsChat") {
                                            let did = items.clientId.split(":");
                                            if (did[1] === undefined || did[1] === null) {
                                                did = items.clientId
                                            }
                                            else {
                                                did = did[1];
                                            }
                                            didAssignedList.push(did)
                                            setAlreadyAssignedDIDsOfSms(didAssignedList)
                                        }
                                        if (items.integration.name === "WhatsappChat") {
                                            let did = items.clientId.split(":");
                                            if (did[1] === undefined || did[1] === null) {
                                                did = items.clientId
                                            }
                                            else {
                                                did = did[1];
                                            }
                                            didAssignedList.push(did)
                                            setAlreadyAssignedDIDsOfWhatsApp(didAssignedList)
                                        }
                                    })
                                }
                                setNewLoading(false);
                            }
                            else {
                                showErrorNotification('Error fetching Digital Campaigns Details');
                                setNewLoading(false);
                            }
                        }).catch(e => {
                            showErrorNotification('Error In fetching Digital Campaigns Details');
                            setNewLoading(false);
                        })
                    }
                })
            }).catch(e => {
                setNewLoading(false)
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            setNewLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getDigitalCampaignDetails() {
        setNewLoading(true);
        try {
            digitalCampaignsDetails(props.data.campaignId).then(resp => {
                if (resp.Status === "Success") {
                    console.log(resp.Data)
                    setDigitalCampaignIntegrationDetails(resp.Data)
                    getCampaign()
                    setNewLoading(false);
                }
                else {
                    showErrorNotification('Error fetching Digital Campaigns Details');
                    setNewLoading(false);
                }
            }).catch(e => {
                showErrorNotification('Error In fetching Digital Campaigns Details');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function removeDigitalCampaignIntegration(data, name) {
        setNewLoading(true);
        try {
            removeDigitalCampaignsIntegrations(data).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`${name} Integration Revoked Successfully`)
                    getDigitalCampaignDetails()
                    setNewLoading(false);
                }
                else {
                    showErrorNotification(`Error in Revoking ${name} Integartion`);
                    setNewLoading(false);
                }
            }).catch(e => {
                showErrorNotification(`Error in Revoking ${name} Integartion`);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    const onClickEdit = () => {
        setMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardEdit = () => {
        setMode(ADMIN_FORM_MODES.view);
    }

    const openCopyCampaign = () => {
        setCopyCampaign(true);
    }

    const closeCopyCampaignForm = (props) => {
        setCopyCampaign(false);
    }

    const DataForm = (props) => {

        if (props?.data) {




            if (props?.data?.fallbackDID === null || props?.data?.fallbackDID.length === 0) {
                props.data.fallbackDID = "None";
            }

            if (props?.data?.campaignType !== "Chat") {
                const popUrlAt = [{ id: 'Client Side', value: 0 }, { id: 'Server Side', value: 1 }, { id: 'Client&Server', value: 2 }, { id: 'Client Side OnBusy', value: 3 }, { id: 'Plugin', value: 4 }, { id: 'Server Side OnBusy', value: 5 }];
                popUrlAt.forEach(item => {
                    if (item.value === props.data.popUrlAt) {
                        props.data.popUrlAt = item;
                    }
                })
                // console.log("popUrlAt"+props.data.screenPopUrl)
                // if(props?.data?.popUrlAt===4 && props?.data?.screenPopUrl===17){
                //     props.data.screenPopUrl='Idesk'
                // }

                // console.log("here i am getting", props?.data);
                if (props?.data?.popUrlAt?.id === "Plugin") {
                    console.log("here plugins", pluginOption);
                    pluginOption.forEach(item => {
                        // console.log("match",item.id === parseInt(props?.data?.screenPopUrl))
                        // console.log("match id" ,item.id);
                        // console.log("match id2" , parseInt(props?.data?.screenPopUrl))
                        // console.log("match id33333" , item)
                        props.data.screenPopUrlInBound = item
                    })
                    const match = pluginOption.find(item => item.id === parseInt(props?.data?.screenPopUrl));
                    // console.log("here plugins match", match);
                    props.data.screenPopUrlInBound = match
                    // props.data.screenPopUrlInBound;
                }



                const dispositionType = [{ id: 'None', value: -1 }, { id: 'API', value: -2 }, { id: 'Toolbar', value: 1 }];
                dispositionType.forEach(item => {
                    if (item.value === props.data.dispositionType) {
                        props.data.dispositionType = item;
                    }
                })

                const blockNumber = [{ id: 'None', value: 0 }, { id: 'Block', value: 1 }, { id: 'Allow', value: 2 }];
                blockNumber.forEach(item => {
                    if (item.value === props.data.blockNumber) {
                        props.data.blockNumber = item;
                    }
                })
                const recOn = [{ id: 'Record the call in Full', value: 0 }, { id: 'Record only the conversation', value: 1 }, { id: 'Do not record calls', value: 2 }];
                recOn.forEach(item => {
                    if (item.value === props.data.recOn) {
                        props.data.recOn = item;
                    }
                })
            }
            else if (props?.data?.campaignType === "Chat") {
                const dispositionType = [{ id: 'None', value: -1 }, { id: 'Toolbar', value: 1 }];
                dispositionType.forEach(item => {
                    if (item.value === props.data.dispositionType) {
                        props.data.dispositionType = item;
                    }
                })
                const popUrlAt = [{ id: 'Client Side', value: 0 }, { id: 'Plugin', value: 4 }];
                popUrlAt.forEach(item => {
                    if (item.value === props.data.popUrlAt) {
                        props.data.popUrlAt = item;
                    }
                })
                if (props?.data?.ivrFlow) {
                    props.data.ivrFlowChat = props.data.ivrFlow;
                }
                if (props?.data?.popUrlAt) {
                    if (props.data.popUrlAt.id === 'Client Side') {
                        props.data.screenPopUrlChatInput = props.data.screenPopUrl;
                    } else if (props.data.popUrlAt.id === 'Plugin') {
                        props.data.screenPopUrlChat = props.data.screenPopUrl;
                    }
                }
            }
        }
        let initData = props.data || {
            campaignName: "",
            campaignType: 'InBound',
            users: [],
            did: '',
            fallbackDID: null,
            dialCount: null,
            disclaimer:"",
            ivrFlow: null,
            ivrFlowChat: null,//this is made for campaignType === Chat
            callbackUrl: "",
            popUrlAt: { id: 'Client Side', value: 0 },
            callPrefix: null,
            dispositions: [],
            dispositionType: { id: 'Toolbar', value: 1 },
            sla: null,
            blockNumber: { id: 'None', value: 0 },
            blockNumberGroups: [],
            recOn: { id: 'Record only the conversation', value: 1 },
            holdMusic: null,
            transferMusic: null,
            dynamicDID: [],
            fallbackDynamicDID: [],
            // screenPopUrl: "",
            screenPopUrl: null,
            screenPopUrlChat: null,
            screenPopUrlChatInput: null,
            screenPopUrlInBound: null,
            dndEnable: true,
            offLineMode: false,
            acwNac: false,
            allowedManual: false,
            dncEnabled: false,
             didMasking: false,
            a2aCalling: false,
            internalCalling: false,
            skills: [],
            position: "READY",

            ruleNot: null,
            script: "",
            transferSkills : [],
            isTransferSkill: false
        };
        setLoading(false)

        //for  stepper
        const steps = ["Basic Settings", "General Information", "Skills", "Script"];
        const [activeStep, setActiveStep] = useState(0);
        const [completed] = useState({});

        const [facebookIcon, setFacebookIcon] = useState(digitalCampaignIntegrationDetails.some(item => item.integration.name === "FbChat"));
        const [twitterIcon, setTwitterIcon] = useState(digitalCampaignIntegrationDetails.some(item => item.integration.name === "TwitterChat"));
        const [whatsAppIcon, setWhatsAppIcon] = useState(digitalCampaignIntegrationDetails.some(item => item.integration.name === "WhatsappChat"));
        const [smsIcon, setSmsIcon] = useState(digitalCampaignIntegrationDetails.some(item => item.integration.name === "SmsChat"));
        const [whatsAppOlderConfiguration, setWhatsAppOlderConfiguration] = useState(false);
        const [toggledByUser, setToggledByUser] = useState(null);
        const [editDigitalCampaignDialog, setEditDigitalCampaignDialog] = useState(null);

        const user = localStorage.getItem('userList');
        const isShowWhatsAppOldConfigurationToggle = (user !== undefined && user !== null && user !== "undefined" && user !== "null") ? true : false

        const handleChange = (event, newValue, props) => {
            localStorage.setItem('InboundForm', JSON.stringify(props.values))
            setValue(newValue);
        }

        const handleNext = () => {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        };

        const handleStep = (step) => () => {
            setActiveStep(step);
        };

        const totalSteps = () => {
            return steps.length;
        };

        const completedSteps = () => {
            return Object.keys(completed).length;
        };

        const isLastStep = () => {
            return activeStep === totalSteps() - 1;
        };

        const allStepsCompleted = () => {
            return completedSteps() === totalSteps();
        };

        let formHeading = useMemo(
            () => ({
                fontWeight: '400',
                fontSize: '22px',
                color: '#212121',
                fontStyle: 'normal',
                fontFamily: 'Roboto',
            }),
            []
        );

        let scriptStyle = useMemo(
            () => ({
                color: '#E65B5C',
                fontWeight: '400',
                fontSize: '0.75rem',
                marginLeft: '10px',
                marginTop: '5px'
            }),
            []
        );

        let copyStyle = useMemo(
            () => ({
                paddingLeft: '20px',
                fontSize: '14px',
                flex: 'right',
                color: '#536580',
            }),
            []
        );

        const tabButtonStyle = useMemo(
            () => ({
                color: "#212121",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                letterSpacing: "0em",
                backgroundColor: "#F5F8FA",
                width: "5.8125rem",
                height: "1.875rem",
                borderRadius: 0,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: '#FFFF',
                },
            }),
            []
        );

        const formButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );

        let stepperStyle = useMemo(
            () => ({
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                paddingTop: '1.25rem',
            }),
            []
        );

        let logoInnerCardStyle = useMemo(
            () => ({
                backgroundColor: '#FFFFFF',
                border: '4px',
                height: '7.75rem',
                width: '7.75rem',
                marginLeft: '27px',
                marginTop: '25px',
            }),
            []
        );

        let logoInnerCardStyleWhenOldConfigurationToogleIsTrue = useMemo(
            () => ({
                backgroundColor: '#FFFFFF',
                border: '4px',
                height: '6.3rem',
                width: '7.75rem',
                marginLeft: '27px',
                marginTop: '25px',
            }),
            []
        );

        let logoOuterCardStyle = useMemo(
            () => ({
                paddingTop: '1px',
                backgroundColor: ' #F6F7F9',
                border: '4px',
                height: '11.25rem',
                width: '11.25rem',
            }),
            []
        );

        const HandleSwitch = (event, value, id) => {
            if (event.target.checked === true) {
                if (value === 'facebook') {
                    setFacebookIcon(event.target.checked);
                    setToggledByUser("facebook")
                } else if (value === 'twitter') {
                    setTwitterIcon(event.target.checked);
                    setToggledByUser("twitter")
                }
                else if (value === 'whatsApp') {
                    setWhatsAppIcon(event.target.checked);
                    setToggledByUser("whatsApp")
                }
                else if (value === "whatsAppOldConfigs") {
                    setWhatsAppOlderConfiguration(event.target.checked);
                    setToggledByUser("whatsAppOldConfigs")
                }
                else if (value === "sms") {
                    setSmsIcon(event.target.checked);
                    setToggledByUser("sms")
                }

            }
            if (event.target.checked === false) {
                if (value === 'facebook') {
                    setToggledByUser(null);
                } else if (value === 'twitter') {
                    setToggledByUser(null);
                }
                else if (value === 'whatsApp') {
                    let data = {}
                    digitalCampaignIntegrationDetails.map((item) => {
                        if (item.integration.name === "WhatsappChat") {
                            data = item
                        }
                    })
                    removeDigitalCampaignIntegration(data, "whatsApp");
                    setToggledByUser(null);
                }
                else if (value === 'sms') {
                    let data = {}
                    digitalCampaignIntegrationDetails.map((item) => {
                        if (item.integration.name === "SmsChat") {
                            data = item
                        }
                    })
                    removeDigitalCampaignIntegration(data, "SmsChat");
                    setToggledByUser(null);
                }
            }
        };

        const defaultFacebookIconTextStyle = useMemo(
            () => ({
                marginTop: '15px',
                color: '#7C878B',
                marginLeft: '28px',
                fontSize: '14px',
                fontWeight: '500',
            }),
            []
        );
        const defaultIconTwitterTextStyle = useMemo(
            () => ({
                marginTop: '20px',
                color: '#7C878B',
                marginLeft: '37px',
                fontSize: '14px',
                fontWeight: '500',
            }),
            []
        );
        const defaultIconWhatsAppTextStyle = useMemo(
            () => ({
                color: '#7C878B',
                marginLeft: '28px',
                fontSize: '14px',
                fontWeight: '500',
                marginTop: (mode === ADMIN_FORM_MODES.add) ? '20px' : digitalCampaignIntegrationDetails.some(item => item.integration.name === "WhatsappChat") ? '20px' : isShowWhatsAppOldConfigurationToggle ? '0px' : '20px'
            }),
            []
        );
        const defaultIconSmsTextStyle = useMemo(
            () => ({
                color: '#7C878B',
                marginLeft: '38px',
                fontSize: '14px',
                fontWeight: '500',
                marginTop: '20px',
            }),
            []
        );

        const activeFacebookIconTextStyle = useMemo(
            () => ({
                marginTop: '15px',
                color: '#3D8BF8',
                marginLeft: '28px',
                fontSize: '14px',
                fontWeight: '500',
                cursor: (mode === ADMIN_FORM_MODES.edit && facebookIcon) ? 'pointer' : null,
            }),
            []
        );
        const activeIconWhatsAppTextStyle = useMemo(
            () => ({
                color: '#3D8BF8',
                marginLeft: '28px',
                fontSize: '14px',
                fontWeight: '500',
                cursor: (mode === ADMIN_FORM_MODES.edit && whatsAppIcon) ? 'pointer' : null,
                marginTop: (mode === ADMIN_FORM_MODES.add) ? '20px' : digitalCampaignIntegrationDetails.some(item => item.integration.name === "WhatsappChat") ? '20px' : isShowWhatsAppOldConfigurationToggle ? '0px' : '20px'
            }),
            []
        );
        const activeIconTwitterTextStyle = useMemo(
            () => ({
                marginTop: '20px',
                color: '#3D8BF8',
                marginLeft: '37px',
                fontSize: '14px',
                fontWeight: '500',
                cursor: (mode === ADMIN_FORM_MODES.edit && twitterIcon) ? 'pointer' : null,
            }),
            []
        );
        const activeIconSmsTextStyle = useMemo(
            () => ({
                color: '#3D8BF8',
                marginLeft: '38px',
                fontSize: '14px',
                fontWeight: '500',
                cursor: (mode === ADMIN_FORM_MODES.edit && smsIcon) ? 'pointer' : null,
                marginTop: '20px'
            }),
            []
        );

        const activeToggleStyle = useMemo(
            () => ({
                marginLeft: '8px',
                color: '#3D8BF8',
                fontSize: '12px',
                fontWeight: '500',
            }),
            []
        );

        const dafaultToggleStyle = useMemo(
            () => ({
                marginLeft: '8px',
                fontSize: '12px',
                fontWeight: '500',
                color: '#D6D6D7',
            }),
            []
        );

        const CampaignSettings = (props) => {
            const propsSettings = props?.props;
            const [isInternalCallingEnabled, setIsInternalCallingEnabled] = useState(false);

            useEffect(() => {
                const fetchData = async () => {
                    setIsInternalCallingEnabled(await checkSettings("INTERNAL_CALLING"));
                };
                fetchData();
            }, []);

            return (
                <Stack >
                    <div style={{ fontSize: '18px', color: '#212121', marginTop: '20px', fontWeight: '400' }}>
                        <label>Campaign Settings</label>
                    </div>
                    <Grid container style={{ marginTop: '1px', fontSize: '14px', color: '#212121' }} spacing={2} display='flex' direction={'row'} >
                        {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_DND_CHECK")) &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="dndEnable"
                                    label="DND Mode"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                        <Grid item xs={3} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="offLineMode"
                                label="Offline Mode"
                                labelPosition="end"
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="a2aCalling"
                                label="Agent to Agent Calling"
                                labelPosition="end"
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="allowedManual"
                                label="Allow Manual Dialing"
                                labelPosition="end"
                            />
                        </Grid>
                        {checkRoles("ROLE_DNC_REGULATION") &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view || (propsSettings?.props?.values?.dispositionType === null) || propsSettings?.props?.values?.dispositionType?.id === 'None'}
                                    name="dncEnabled"
                                    label="Apply DNC Regulation"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                         {checkRoles("ROLE_DIDMasking") &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="didMasking"
                                    label="Apply DID Masking"
                                    labelPosition="end"
                                />
                            </Grid>
                        } 
                        {isInternalCallingEnabled &&
                            <Grid item xs={3} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="internalCalling"
                                    label="Internal Calling"
                                    labelPosition="end"
                                />
                            </Grid>
                        }
                        {(propsSettings?.props?.values?.dispositionType?.id !== "None") &&
                            <Grid item xs={4} >
                                <OZFCheckbox
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name='acwNac'
                                    label="ACW for Unanswered Call"
                                    labelPosition="end"
                                />
                                <div style={{ color: '#3D8BF8', marginLeft: '30px', marginTop: '-10px', fontSize: '12px' }}>Only For Manual Calls</div>
                            </Grid>
                        }
                    </Grid>
                    <Grid container style={{ fontSize: '14px', color: '#212121' }} spacing={2} display='flex' direction={'row'} >
                    </Grid>
                </Stack>
            )
        }

        const [leaveCampaign, setLeaveCampaign] = useState(false);

        const LeaveCampaign = (props) => {
            const closeLeaveCampaign = () => {
                setOpenLeaveCampaign(false);
                props.onClose();
            }
            const [openLeaveCampaign, setOpenLeaveCampaign] = useState(true);

            return (
                <div>
                    <LockedAndStatusConfirmationDialog
                        hideActions
                        title={
                            <div>
                                <div style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                                    <label>Leave Campaign?</label>
                                </div>
                            </div>
                        }
                        content={
                            <>
                                <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
                                    Your entries will be lost when you leave this page.
                                </div>
                                <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
                                    Are you sure you want leave the campaign ?
                                </div>
                                <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
                                    <Grid item >
                                        <Button style={formButtonStyle} variant='outlined' type="submit" onClick={closeLeaveCampaign}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button style={formButtonStyle} onClick={closeCampaignInboundForm} variant="contained" type="submit">
                                            Yes, Leave Campaign
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        open={openLeaveCampaign}
                        onClose={closeLeaveCampaign}
                    >
                    </LockedAndStatusConfirmationDialog>
                </div>
            )
        }

        const openLeaveCampaignForm = () => {
            setLeaveCampaign(true);
        }

        const closeLeaveCampaignForm = () => {
            setLeaveCampaign(false);
        }

        const BasicSettings = (props) => {
            const basicProps = props?.props;
            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Basic Settings</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                <Grid container direction='row'>
                                    {/* {authPage(AdminPages.INBOUND_CAMP_EDIT) && (
                                        <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                            <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit Basic Settings' : 'Discard Edit'}</label>
                                        </div>
                                    )} */}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                        <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                            <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit Basic Settings' : 'Discard Edit'}</label>
                                        </div>
                                    )}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                    <>
                                    {
                                        <PopupState variant="popover" popupId="demo-popup-menu" >
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <div style={copyStyle} onClick={openCopyCampaign}>
                                                        <label style={{ cursor: "pointer" }}>Copy Campaign</label>
                                                    </div>
                                                    {copyCampaign && (
                                                        <div>
                                                            <CopyCampaignForm
                                                                onClose={closeCopyCampaignForm}
                                                                id={props?.props?.values?.campaignId}
                                                                type={props?.props?.values?.campaignType}
                                                                campaignName={props?.props?.values?.campaignName}
                                                                updateCopyCampaign={closeCopyCampaignForm}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    }
                                    </>
                                )}
                                </Grid>
                            </div>
                        )}

                    </div>
                    <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {basicProps?.values?.campaignType === 'Chat' &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                    name="campaignName"
                                    label="Campaign Name *"
                                    placeholder="Campaign Name"
                                />
                            </Grid>
                        }
                        {basicProps?.values?.campaignType !== 'Chat' &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    name="campaignName"
                                    label="Campaign Name *"
                                    placeholder="Campaign Name"
                                />
                            </Grid>
                        }
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode !== ADMIN_FORM_MODES.add}
                                name="campaignType"
                                label="Campaign Type *"
                                options={['InBound', 'Chat']}
                                // options={[{ id: 'Chat', value: 'Chat' }, { id: 'InBound', value: 'InBound' }]}
                                placeholder="Please Select"
                            />
                        </Grid>
                    </Grid>

                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {localStorage.getItem('parentUser') === 'null' &&
                            (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CAMPAIGNSUB")) &&
                            <Grid item xs={6} >
                                <CustomAutoComplete
                                    disabled={mode === ADMIN_FORM_MODES.view}
                                    mode='Add'
                                    name="users"
                                    limitTags={2}
                                    placeholder={"Please Select"}
                                    Datas={subUserOption}
                                    labelHeader='Sub Admin Assigned '
                                    optionLabelName='username'
                                    showCheckBox={true}
                                    multiple={true}
                                    showAvatar={true}
                                    showAvatarInChip
                                    showselectall="true"
                                    popupIcon={null}
                                />
                            </Grid>
                        }
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                name="did"
                                options={secondaryDidOption}
                                label={(basicProps?.values?.campaignType === "InBound") ? "DID *" : 'Campaign Identifier *'}
                                placeholder={(basicProps?.values?.campaignType === "InBound") ? "DID No." : 'Campaign Identifier'}
                            />
                        </Grid>
                    </Grid>

                    {basicProps?.values?.campaignType === "InBound" &&
                        <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                            <Grid item xs={6} >
                                {checkRoles("ROLE_FallBackDID") &&
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position === "RUNNING"}
                                        name="fallbackDID"
                                        label="FallBack DID"
                                        placeholder="FallBack DID"
                                        options={secondaryFallbackDidOption}
                                    />
                                }
                            </Grid>
                        </Grid>
                    }

                    {(basicProps?.values?.campaignType === "Chat") && (
                        <div>
                            <div style={{ marginTop: '20px', fontSize: '18px', color: '#212121' }}>
                                <label>Integrate</label>
                            </div>
                            <Grid container style={{ marginTop: '20px', fontSize: '14px', color: '#212121' }} display='flex' direction={'row'} spacing={3} >
                                {checkRoles("ROLE_FBCHAT") &&
                                    <Grid item>
                                        <div style={logoOuterCardStyle}>
                                            {mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view ?
                                                <Tooltip title={facebookIcon && mode === ADMIN_FORM_MODES.edit ? "Click to Edit Configuration" : null}>
                                                    <div style={logoInnerCardStyle} onClick={facebookIcon && mode === ADMIN_FORM_MODES.edit ? () => setEditDigitalCampaignDialog("facebook") : null}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/facebook.svg`} alt='Facebook' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(facebookIcon) ? activeFacebookIconTextStyle : defaultFacebookIconTextStyle}>
                                                            FaceBook
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                                :
                                                <Tooltip title={mode === ADMIN_FORM_MODES.add && "Facebook integration will be Available once Campaign is Created"}>
                                                    <div style={logoInnerCardStyle}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/facebook.svg`} alt='Facebook' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(facebookIcon) ? activeFacebookIconTextStyle : defaultFacebookIconTextStyle}>
                                                            Facebook
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {(mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view) &&
                                                <>
                                                    <Tooltip title={mode === ADMIN_FORM_MODES.edit ? facebookIcon ? "DisIntegrate Is Not Possible" : "Toggle here to Integrate" : "Switch To Edit Mode To Edit FacebookIntegration"}>
                                                        <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                            <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={facebookIcon} onChange={(e) => HandleSwitch(e, 'facebook', basicProps?.values?.campaignId)} />
                                                            <div style={facebookIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                                {facebookIcon ? 'Activated' : 'Activate'}
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </>
                                            }
                                        </div>
                                    </Grid>}
                                {checkRoles("ROLE_TWITTER") &&
                                    <Grid item>
                                        <div style={logoOuterCardStyle}>
                                            {mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view ?
                                                <Tooltip title={twitterIcon && mode === ADMIN_FORM_MODES.edit ? "Click to Edit Configuration" : null}>
                                                    <div style={logoInnerCardStyle} onClick={twitterIcon && mode === ADMIN_FORM_MODES.edit ? () => setEditDigitalCampaignDialog("twitter") : null}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/twitter.svg`} alt='Twitter' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(twitterIcon) ? activeIconTwitterTextStyle : defaultIconTwitterTextStyle}>
                                                            Twitter
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                                :
                                                <Tooltip title={mode === ADMIN_FORM_MODES.add && "Twitter integration will be Available once Campaign is Created"}>
                                                    <div style={logoInnerCardStyle}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/twitter.svg`} alt='Twitter' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(facebookIcon) ? activeIconTwitterTextStyle : defaultIconTwitterTextStyle}>
                                                            Twitter
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {(mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view) &&
                                                <>
                                                    <Tooltip title={mode === ADMIN_FORM_MODES.edit ? twitterIcon ? "DisIntegrate Is Not Possible" : "Toggle here to Integrate" : "Switch To Edit Mode To Edit TwitterIntegration"}>
                                                        <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                            <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={twitterIcon} onChange={(e) => HandleSwitch(e, 'twitter', basicProps?.values?.campaignId)} />
                                                            <div style={twitterIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                                {twitterIcon ? 'Activated' : 'Activate'}
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </>
                                            }
                                        </div>
                                    </Grid>}
                                {checkRoles("ROLE_WHATSAPP_CHAT") &&
                                    <Grid item>
                                        <div style={logoOuterCardStyle}>
                                            {mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view ?
                                                <Tooltip title={whatsAppIcon && mode === ADMIN_FORM_MODES.edit ? "Click to Edit Configuration" : null}>
                                                    <div style={(isShowWhatsAppOldConfigurationToggle && !(digitalCampaignIntegrationDetails.some(item => item.integration.name === "WhatsappChat"))) ? logoInnerCardStyleWhenOldConfigurationToogleIsTrue : logoInnerCardStyle} onClick={whatsAppIcon && mode === ADMIN_FORM_MODES.edit ? () => setEditDigitalCampaignDialog("whatsApp") : null}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/whatsApp.svg`} alt='whatsApp' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(whatsAppIcon || whatsAppOlderConfiguration) ? activeIconWhatsAppTextStyle : defaultIconWhatsAppTextStyle}>
                                                            WhatsApp
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                                :
                                                <Tooltip title={mode === ADMIN_FORM_MODES.add && "WhatsApp integration will be Available once Campaign is Created"}>
                                                    <div style={logoInnerCardStyle}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/whatsApp.svg`} alt='whatsApp' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(whatsAppIcon || whatsAppOlderConfiguration) ? activeIconWhatsAppTextStyle : defaultIconWhatsAppTextStyle}>
                                                            WhatsApp
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {(mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view) &&
                                                <>
                                                    <Tooltip title={mode === ADMIN_FORM_MODES.edit ? whatsAppIcon ? "Toggle here to DisIntegrate" : "Switch to initiate New WhatsApp integration" : "Switch To Edit Mode To Edit WhatsAppIntegration"}>
                                                        <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                            <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={whatsAppIcon} onChange={(e) => HandleSwitch(e, 'whatsApp', basicProps?.values?.campaignId)} />
                                                            <div style={whatsAppIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                                {whatsAppIcon ? 'Activated' : 'Activate'}
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                    {isShowWhatsAppOldConfigurationToggle && !(digitalCampaignIntegrationDetails.some(item => item.integration.name === "WhatsappChat")) &&
                                                        <Tooltip title={mode === ADMIN_FORM_MODES.edit ? "Switch to integrate existing WhatsApp number" : "Switch To Edit Mode To Edit WhatsAppIntegration"}>
                                                            <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                                <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={whatsAppOlderConfiguration} onChange={(e) => HandleSwitch(e, 'whatsAppOldConfigs', basicProps?.values?.campaignId)} />
                                                                <div style={whatsAppOlderConfiguration ? activeToggleStyle : dafaultToggleStyle}>
                                                                    {whatsAppOlderConfiguration ? 'Activated' : 'Activate'}
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </Grid>}
                                {checkRoles("ROLE_SMS_CHAT") &&
                                    <Grid item>
                                        <div style={logoOuterCardStyle}>
                                            {mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view ?
                                                <Tooltip title={smsIcon && mode === ADMIN_FORM_MODES.edit ? "Click to Edit Configuration" : null}>
                                                    <div style={logoInnerCardStyle} onClick={smsIcon && mode === ADMIN_FORM_MODES.edit ? () => setEditDigitalCampaignDialog("sms") : null}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/sms.svg`} alt='SMS' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(smsIcon) ? activeIconSmsTextStyle : defaultIconSmsTextStyle}>
                                                            SMS
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                                :
                                                <Tooltip title={mode === ADMIN_FORM_MODES.add && "SMS integration will be Available once Campaign is Created"}>
                                                    <div style={logoInnerCardStyle}>
                                                        <img src={`${process.env.PUBLIC_URL}/icons/sms.svg`} alt='SMS' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                        <div style={(smsIcon) ? activeIconSmsTextStyle : defaultIconSmsTextStyle}>
                                                            SMS
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }

                                            {(mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view) &&
                                                <>
                                                    <Tooltip title={mode === ADMIN_FORM_MODES.edit ? smsIcon ? "Toggle here to DisIntegrate" : "Toggle here to Integrate" : "Switch To Edit Mode To Edit SMSIntegration"}>
                                                        <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                            <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={smsIcon} onChange={(e) => HandleSwitch(e, 'sms', basicProps?.values?.campaignId)} />
                                                            <div style={smsIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                                {smsIcon ? 'Activated' : 'Activate'}
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </>
                                            }
                                        </div>
                                    </Grid>}

                            </Grid >
                            {whatsAppIcon && toggledByUser === "whatsApp" &&
                                <WhatsAppIntegrationForm open={whatsAppIcon && toggledByUser === "whatsApp"} close={() => { setWhatsAppIcon(false); setToggledByUser(null) }} campaignId={basicProps?.values?.campaignId} alreadyAssignedDIDs={alreadyAssignedDIDsOFWhatsApp} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} />
                            }
                            {whatsAppOlderConfiguration && toggledByUser === "whatsAppOldConfigs" &&
                                <WhatsAppIntegrationForm open={whatsAppOlderConfiguration && toggledByUser === "whatsAppOldConfigs"} close={() => { setWhatsAppOlderConfiguration(false); setToggledByUser(null) }} whatsAppOlderConfigDialog={true} campaignId={basicProps?.values?.campaignId} alreadyAssignedDIDs={alreadyAssignedDIDsOFWhatsApp} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} />
                            }
                            {whatsAppIcon && editDigitalCampaignDialog === "whatsApp" &&
                                <EditDigitalCampaignWhatsAppIntegration open={whatsAppIcon && editDigitalCampaignDialog === "whatsApp"} close={() => { setEditDigitalCampaignDialog(null) }} data={digitalCampaignIntegrationDetails} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} skillsList={basicProps.initialValues.skills} alreadyAssignedDIDs={alreadyAssignedDIDsOFWhatsApp} />
                            }
                            {smsIcon && toggledByUser === "sms" &&
                                <AddAndEditDigitalCampaignSmsIntegration open={smsIcon && toggledByUser === "sms"} close={() => { setSmsIcon(false); setToggledByUser(null) }} campaignId={basicProps?.values?.campaignId} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} mode={"Add"} alreadyAssignedDIDs={alreadyAssignedDIDsOFSms} />
                            }
                            {smsIcon && editDigitalCampaignDialog === "sms" &&
                                <AddAndEditDigitalCampaignSmsIntegration open={smsIcon && editDigitalCampaignDialog === "sms"} close={() => { setEditDigitalCampaignDialog(null) }} data={digitalCampaignIntegrationDetails} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} mode={"Edit"} alreadyAssignedDIDs={alreadyAssignedDIDsOFSms} />
                            }
                            {facebookIcon && toggledByUser === "facebook" &&
                                <AddAndEditDigitalCampaignFacebookIntegration open={facebookIcon && toggledByUser === "facebook"} close={() => { setFacebookIcon(false); setToggledByUser(null) }} campaignId={basicProps?.values?.campaignId} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} mode={"Add"} />
                            }
                            {facebookIcon && editDigitalCampaignDialog === "facebook" &&
                                <AddAndEditDigitalCampaignFacebookIntegration open={facebookIcon && editDigitalCampaignDialog === "facebook"} close={() => { setEditDigitalCampaignDialog(null) }} data={digitalCampaignIntegrationDetails} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} mode={"Edit"} />
                            }
                            {twitterIcon && toggledByUser === "twitter" &&
                                <AddAndEditDigitalCampaignTwitterIntegration open={twitterIcon && toggledByUser === "twitter"} close={() => { setTwitterIcon(false); setToggledByUser(null) }} campaignId={basicProps?.values?.campaignId} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} mode={"Add"} />
                            }
                            {twitterIcon && editDigitalCampaignDialog === "twitter" &&
                                <AddAndEditDigitalCampaignTwitterIntegration open={twitterIcon && editDigitalCampaignDialog === "twitter"} close={() => { setEditDigitalCampaignDialog(null) }} data={digitalCampaignIntegrationDetails} getDigitalCampaignDetails={() => { getDigitalCampaignDetails() }} mode={"Edit"} />
                            }
                        </div>
                    )}

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 8.5, marginBottom: '10px' } : { marginTop: 8.5 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                //  onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null}
                                onClick={() => {
                                    if (mode === ADMIN_FORM_MODES.add) {
                                        handleNext();
                                    } else if (mode === ADMIN_FORM_MODES.edit) {
                                        if (!basicProps.isValid) {
                                            basicProps.handleSubmit();
                                            showErrorNotification("Please Check the values and fill all the mandatory field");
                                        }
                                    }
                                }}
                                variant="contained" type="submit" form="campaignInbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            );
        }

        const GeneralInformation = (props) => {
            const propsGeneral = props?.props;
            console.log("", propsGeneral)
            if (propsGeneral.values.campaignType === "Chat") {
                if (propsGeneral.values.popUrlAt.id === 'Server Side' || propsGeneral.values.popUrlAt.id === 'Client&Server' || propsGeneral.values.popUrlAt.id === 'Client Side OnBusy' || propsGeneral.values.popUrlAt.id === 'Server Side OnBusy') {
                    propsGeneral.values.popUrlAt = { id: 'Client Side', value: 0 };
                }
            }
            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>General Information</Typography>

                        {mode === ADMIN_FORM_MODES.view && (
                            <div>
                                <Grid container direction='row'>
                                    {/* {authPage(AdminPages.INBOUND_CAMP_EDIT) && (
                                        <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                            <label style={{ cursor: "pointer" }}>Edit</label>
                                        </div>
                                    )} */}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                        <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                            <label style={{ cursor: "pointer" }}>Edit</label>
                                        </div>
                                    )}
                                </Grid>
                            </div>
                        )}
                        {mode === ADMIN_FORM_MODES.edit && (
                            <div>
                                <Grid container direction='row'>
                                    <div style={{ fontSize: '14px', color: '#3D8BF8' }} onClick={onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }} >Discard Edit</label>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </div>

                    {(propsGeneral?.values?.campaignType === "Chat") && (
                        <div>
                            <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                
                                <Grid item xs={6} >
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='ruleNot'
                                        label="Number of concurrent chats *"
                                        placeholder="Per agents"
                                    />
                                </Grid>
                                {(checkRoles("ROLE_CBK_CAMPAIGN") || checkRoles("ROLE_ADMIN")) &&
                                    <Grid item xs={6} >
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="callbackUrl"
                                            label="URL to Push"
                                            placeholder="URL to Push"
                                        />
                                    </Grid>}
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='popUrlAt'
                                        label="Hit ScreenPop URL at"
                                        optionLabel='id'
                                        options={[{ id: 'Client Side', value: 0 }, { id: 'Plugin', value: 4 }]}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    {propsGeneral?.values?.popUrlAt?.id !== 'Plugin' && (
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            // name="screenPopUrl"
                                            name='screenPopUrlChatInput'
                                            label="ScreenPop URL"
                                            placeholder="ScreenPop URL"
                                        />
                                    )}
                                    {propsGeneral?.values?.popUrlAt?.id === 'Plugin' && (
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            // name='screenPopUrl'
                                            name='screenPopUrlChat'
                                            label="Plugin Name *"
                                            optionLabel='name'
                                            options={pluginOption}
                                            placeholder="Please Select"
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                
                                <Grid item xs={6} >
                                    <CustomAutoComplete
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        mode='Add'
                                        limitTags={2}
                                        name="dispositions"
                                        labelHeader='Dispositions *'
                                        placeholder={"Please Select"}
                                        Datas={dispositonsOption}
                                        optionLabelName='reason'
                                        showCheckBox={true}
                                        multiple={true}
                                        showselectall="true"
                                        popupIcon={null}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="dispositionType"
                                        label="Disposition Type *"
                                        optionLabel='id'
                                        options={[{ id: 'None', value: -1 }, { id: 'Toolbar', value: 1 }]}
                                        placeholder="None"
                                    />
                                </Grid>
                                {propsGeneral?.values?.dispositionType?.id !== 'None' &&
                                    <Grid item xs={6} >
                                        <OZInput
                                            disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.dispositionType?.id === 'None') || (propsGeneral?.values?.dispositionType === null)}
                                            name='sla'
                                            label={
                                                <>
                                                    <label>{(propsGeneral?.values?.dispositionType?.id === 'None' || (propsGeneral?.values?.dispositionType === null)) ? 'Wrapup Time (In Seconds)' : 'Wrapup Time (In Seconds)*'}</label>
                                                    <span style={{ opacity: '0.5', marginLeft: '10px' }} >(Note:value 0 means infinite time)</span>
                                                </>
                                            }
                                            placeholder="Time in seconds"
                                            type='number'
                                        />
                                    </Grid>
                                }
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_IVRDESIGNER_ALL")) &&
                                    <Grid item xs={6} >
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="ivrFlowChat"
                                            label="IVR Flow *"
                                            optionLabel='flowName'
                                            options={ivrFlowChatOption}
                                            placeholder="Select IVR Flow"
                                        />
                                    </Grid>}
                            </Grid>
                            <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                            
                            </Grid>
                        </div>
                    )}

                    {propsGeneral?.values?.campaignType !== "Chat" &&
                        <>
                            <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                {/* <Grid item xs={6} >
                                    {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_IVRDESIGNER_ALL")) &&
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="ivrFlow"
                                            label="IVR Flow"
                                            optionLabel='flowName'
                                            options={ivrFlowOption}
                                            placeholder="Please Select IVR Flow"
                                        />
                                    }
                                </Grid> */}
                                <Grid item xs={6} >
                                    {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CBK_CAMPAIGN")) &&
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="callbackUrl"
                                            label="URL to Push"
                                            placeholder="URL to Push"
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='popUrlAt'
                                        label="Hit ScreenPop URL at"
                                        optionLabel='id'
                                        options={[{ id: 'Client Side', value: 0 }, { id: 'Server Side', value: 1 }, { id: 'Client&Server', value: 2 }, { id: 'Client Side OnBusy', value: 3 }, { id: 'Plugin', value: 4 }, { id: 'Server Side OnBusy', value: 5 }]}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                
                                <Grid item xs={6} >
                                    {propsGeneral?.values?.popUrlAt?.id !== 'Plugin' && (
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            // name="screenPopUrl"
                                            name='screenPopUrl'
                                            label="ScreenPop URL"
                                            placeholder="ScreenPop URL"
                                        />
                                    )}
                                    {propsGeneral?.values?.popUrlAt?.id === 'Plugin' && (
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name='screenPopUrlInBound'
                                            label="Plugin Name *"
                                            optionLabel='name'
                                            options={pluginOption}
                                            placeholder="Please Select"
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={6} >
                                    {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CALLPREFIX")) &&
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="callPrefix"
                                            label="Call Prefix"
                                            placeholder="Call Prefix"
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                            <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="dispositionType"
                                        label="Disposition Type *"
                                        optionLabel='id'
                                        options={[{ id: 'None', value: -1 }, { id: 'API', value: -2 }, { id: 'Toolbar', value: 1 }]}
                                        placeholder="None"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <CustomAutoComplete
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        mode='Add'
                                        limitTags={2}
                                        name="dispositions"
                                        labelHeader='Dispositions *'
                                        placeholder={"Please Select"}
                                        Datas={dispositonsOption}
                                        optionLabelName='reason'
                                        showCheckBox={true}
                                        multiple={true}
                                        showselectall="true"
                                        popupIcon={null}
                                    />
                                </Grid>
                                
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                
                                {/* <Grid item xs={6} >
                                    <OZInput
                                        disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.dispositionType?.id === 'None') || (propsGeneral?.values?.dispositionType === null)}
                                        name="sla"
                                        label={(propsGeneral?.values?.dispositionType?.id === 'None' || (propsGeneral?.values?.dispositionType === null)) ? 'Wrapup Time (In Seconds)' : 'Wrapup Time (In Seconds)*'}
                                        placeholder="Time in seconds"
                                    />
                                </Grid> */}
                                {propsGeneral?.values?.dispositionType?.id !== 'None' &&
                                    <Grid item xs={6} >
                                        <OZInput
                                            disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.dispositionType?.id === 'None') || (propsGeneral?.values?.dispositionType === null)}
                                            name='sla'
                                            label={
                                                <>
                                                    <label>{(propsGeneral?.values?.dispositionType?.id === 'None' || (propsGeneral?.values?.dispositionType === null)) ? 'Wrapup Time (In Seconds)' : 'Wrapup Time (In Seconds)*'}</label>
                                                    <span style={{ opacity: '0.5', marginLeft: '10px' }} >(Note:value 0 means infinite time)</span>
                                                </>
                                            }
                                            placeholder="Time in seconds"
                                            type='number'
                                        />
                                    </Grid>
                                }
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="blockNumber"
                                        label="Block Number"
                                        optionLabel='id'
                                        options={[{ id: 'None', value: 0 }, { id: 'Block', value: 1 }, { id: 'Allow', value: 2 }]}
                                        placeholder="None"
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                
                                {propsGeneral?.values?.blockNumber?.id !== 'None' &&
                                    <Grid item xs={6} >
                                        {/* <OZSelect
                                        disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.blockNumber?.value === 0) || (propsGeneral?.values?.blockNumber === null)}
                                        name="blockNumberGroups"
                                        label='Block Number Group'
                                        multiSelect={true}
                                        showChip={true}
                                        showCheckbox={true}
                                        optionLabel='name'
                                        options={blockNumberGroupOption}
                                        placeholder="Please Select"
                                    /> */}
                                        <CustomAutoComplete
                                            disabled={(mode === ADMIN_FORM_MODES.view) || (propsGeneral?.values?.blockNumber?.value === 0) || (propsGeneral?.values?.blockNumber === null)}
                                            mode='Add'
                                            limitTags={2}
                                            name="blockNumberGroups"
                                            labelHeader={propsGeneral?.values?.blockNumber?.id === 'None' ? 'Block Number Group' : 'Block Number Group *'}
                                            placeholder={"Please Select"}
                                            Datas={blockNumberGroupOption}
                                            optionLabelName='name'
                                            showCheckBox={true}
                                            multiple={true}
                                            showselectall="true"
                                            popupIcon={null}
                                        />
                                    </Grid>
                                }
                                
                                  {propsGeneral?.values?.allowedManual === true &&
                                 <Grid item xs={6} >
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="disclaimer"
                                            label="Play Disclaimer URL"
                                            placeholder="Play Disclaimer URL"
                                        />
                                </Grid>
                                    }
                                
                                {propsGeneral?.values?.allowedManual === true &&
                                    <Grid item xs={6} >
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="recOn"
                                            label="Record"
                                            optionLabel='id'
                                            options={[{ id: 'Record the call in Full', value: 0 }, { id: 'Record only the conversation', value: 1 }, { id: 'Do not record calls', value: 2 }]}
                                            placeholder="Please Select"
                                        />
                                    </Grid>
                                }

                                {(checkRoles("ROLE_OBD_RESTRICTION") && propsGeneral?.values?.allowedManual === true) &&
                                    <Grid item xs={6} >
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="dialCount"
                                            label="Daily Outbound Limit per Number"
                                            placeholder="Daily Outbound Limit per Number"
                                        />
                                    </Grid>
                                }

<Grid item xs={6} >
                                    {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_IVRDESIGNER_ALL")) &&
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="ivrFlow"
                                            label="IVR Flow"
                                            optionLabel='flowName'
                                            options={ivrFlowOption}
                                            placeholder="Please Select IVR Flow"
                                        />
                                    }
                                </Grid>

                            </Grid>

                            <CampaignSettings props={props} />

                            {checkRoles("ROLE_CAMPAIGNHOLDMUSIC") &&
                                <>
                                    <div style={{ fontSize: '20px', display: 'flex', marginTop: '20px' }}>
                                        <label>Music Files</label>
                                    </div>
                                    <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                        <Grid item xs={6} >
                                            <OZSelect
                                                disabled={mode === ADMIN_FORM_MODES.view}
                                                name="holdMusic"
                                                label="On Hold"
                                                optionLabel='name'
                                                options={onHoldMusicOption}
                                                placeholder="Please Select"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <OZSelect
                                                disabled={mode === ADMIN_FORM_MODES.view}
                                                name="transferMusic"
                                                label="On Transfer"
                                                optionLabel='name'
                                                options={onHoldMusicOption}
                                                placeholder="Please Select"
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                    }

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 10, marginBottom: '10px' } : { marginTop: 10 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                //  onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null}
                                onClick={() => {
                                    if (mode === ADMIN_FORM_MODES.add) {
                                        handleNext();
                                    } else if (mode === ADMIN_FORM_MODES.edit) {
                                        if (!propsGeneral.isValid) {
                                            propsGeneral.handleSubmit();
                                            showErrorNotification("Please Check the values and fill all the mandatory field");
                                        }
                                    }
                                }}
                                variant="contained" type="submit" form="campaignInbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>

                </Stack>
            );
        }

        const Skills = (props) => {
            const propsSkills = props?.props;
            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Skills *</Typography>

                        {mode === ADMIN_FORM_MODES.view && (
                            <div>
                                <Grid container direction='row'>
                                    {/* {authPage(AdminPages.INBOUND_CAMP_EDIT) && (
                                        <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                            <label style={{ cursor: "pointer" }}>Edit</label>
                                        </div>
                                    )} */}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))&& (
                                        <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                            <label style={{ cursor: "pointer" }}>Edit</label>
                                        </div>
                                    )}
                                </Grid>
                            </div>
                        )}
                        {mode === ADMIN_FORM_MODES.edit && (
                            <div>
                                <Grid container direction='row'>
                                    <div style={{ fontSize: '14px', color: '#3D8BF8' }} onClick={onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>Discard Edit</label>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </div>
                    {(mode === ADMIN_FORM_MODES.add && (
                        <div style={{ marginTop: '2px', fontSize: '14px', display: 'flex', color: '#99A0A8' }}>
                            <span style={{ opacity: '0.4' }}>Please select Skill to add</span>
                        </div>
                    ))}
                    <div style={{ marginTop: 25, minHeight: '22.0625rem', border: '1px solid #D6D6D7', borderRadius: '8px 8px 8px 8px', backgroundColor: '#FFFFFF' }}>
                        <div style={{ paddingTop: '10px', paddingLeft: '15px', fontSize: '14px', fontWeight: '400', color: '#212121' }}>
                            {(mode === ADMIN_FORM_MODES.add) ? propsSkills?.values?.skills?.length + ' Selected...' : propsSkills?.values?.skills?.length + ' Skills Selected'}
                        </div>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider sx={{ borderColor: "#D6D6D7", }} />
                        </span>
                        <div style={{ marginTop: '20px', marginLeft: '17px', minHeight: '2.75rem', paddingRight: '17px' }}>
                            <CustomAutoComplete
                                disabled={mode === ADMIN_FORM_MODES.view}
                                limitTags={6}
                                mode='Add'
                                name="skills"
                                placeholder={"Search Skill Name"}
                                Datas={skillOption}
                                optionLabelName='skillName'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                                popupIcon={null}
                            />
                        </div>
                    </div>

                    {propsSkills?.values?.campaignType !== "Chat" &&
                        <>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} >
                                    <OZFCheckbox
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="isTransferSkill"
                                        label="Use for Skill Transfers"
                                        labelPosition="end"
                                    />
                                </Grid>
                            </Grid>
                            {propsSkills?.values?.isTransferSkill === true && propsSkills?.values?.campaignType !== "Chat" &&
                                <>
                                    <div style={{ display: 'flex', alignContent: 'center', marginTop: '10px' }}>
                                        <Typography sx={formHeading} flexGrow={1}>{propsSkills?.values?.isTransferSkill === true ? 'Transfer Skills *' : 'Transfer Skills'}</Typography>
                                    </div>

                                    <Grid style={{fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                        <Grid item xs={12} >
                                            <div style={{ marginTop: '20px', marginLeft: '13px', minHeight: '2.75rem', paddingRight: '17px' }}>
                                                <CustomAutoComplete
                                                    labelHeader='Value *'
                                                    disabled={mode === ADMIN_FORM_MODES.view}
                                                    mode='Add'
                                                    limitTags={5}
                                                    name="transferSkills"
                                                    Datas={skillOption}
                                                    optionLabelName='skillName'
                                                    showCheckBox={true}
                                                    multiple={true}
                                                    showselectall="true"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                    }

                    {/* <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={6} >
                            <OZFCheckbox
                                disabled={mode === ADMIN_FORM_MODES.view}
                                name="isTransferSkill"
                                label="Use for Skill Transfers"
                                labelPosition="end"
                            />
                        </Grid>
                    </Grid>
                    {propsSkills?.values?.isTransferSkill === true &&
                        <>
                            <div style={{ display: 'flex', alignContent: 'center', marginTop: '10px' }}>
                                <Typography sx={formHeading} flexGrow={1}>{propsSkills?.values?.isTransferSkill === true ? 'Transfer Skills *' : 'Transfer Skills'}</Typography>
                            </div>

                            <Grid style={{fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={12} >
                                    <div style={{ marginTop: '20px', marginLeft: '13px', minHeight: '2.75rem', paddingRight: '17px' }}>
                                        <CustomAutoComplete
                                            labelHeader='Value *'
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            mode='Add'
                                            limitTags={5}
                                            name="transferSkills"
                                            Datas={skillOption}
                                            optionLabelName='skillName'
                                            showCheckBox={true}
                                            multiple={true}
                                            showselectall="true"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    } */}

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 8, marginBottom: '10px' } : { marginTop: 8 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        {propsSkills?.values?.campaignType !== "Chat" &&
                            <Grid item >
                                <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                    // onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null}
                                    onClick={() => {
                                        if (mode === ADMIN_FORM_MODES.add) {
                                            handleNext();
                                        } else if (mode === ADMIN_FORM_MODES.edit) {
                                            if (!propsSkills.isValid) {
                                                propsSkills.handleSubmit();
                                                showErrorNotification("Please Check the values and fill all the mandatory field");
                                            }
                                        }
                                    }}
                                    variant="contained" type="submit" form="campaignInbound-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                                </Button>
                            </Grid>
                        }

                        {propsSkills?.values?.campaignType === "Chat" &&
                            <Grid item >
                                <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view}
                                    onClick={() => {
                                        if (!propsSkills.isValid) {
                                            propsSkills.handleSubmit();
                                            showErrorNotification("Please Check the values and fill all the mandatory field")
                                        }
                                    }} variant="contained" type={(propsSkills.isValid) ? "submit" : null} form="campaignInbound-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Stack>
            )
        }

        const Script = (props) => {
            const scriptProps = props?.props;
            const tempScripts = 'script';
            const errorScript = getIn(scriptProps.errors, tempScripts);
            const touchScript = getIn(scriptProps.touched, tempScripts);
            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Script</Typography>
                        {mode === ADMIN_FORM_MODES.view && (
                            <div>
                                <Grid container direction='row'>
                                    {/* {authPage(AdminPages.INBOUND_CAMP_EDIT) && (
                                        <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                            <label style={{ cursor: "pointer" }}>Edit</label>
                                        </div>
                                    )} */}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full'))&& (
                                        <div style={{ fontSize: '14px', color: '#536580' }} onClick={onClickEdit}>
                                        <label style={{ cursor: "pointer" }}>Edit</label>
                                    </div>
                                    )}
                                </Grid>
                            </div>
                        )}
                        {mode === ADMIN_FORM_MODES.edit && (
                            <div>
                                <Grid container direction='row'>
                                    <div style={{ fontSize: '14px', color: '#3D8BF8' }} onClick={onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>Discard Edit</label>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </div>
                    <div style={{ marginTop: '2px', fontSize: '14px', display: 'flex', color: '#99A0A8' }}>
                        <span style={{ opacity: '0.5' }}>Write script for the campaign</span>
                    </div>
                    <div style={{ marginTop: 10, minHeight: '22.0625rem', border: '1px solid #D6D6D7', borderRadius: '8px 8px 8px 8px', backgroundColor: '#FFFFFF' }}>
                        <div style={{ marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                            <OZRichText disable={mode === ADMIN_FORM_MODES.view} name="script" placeholder=" " />
                        </div>
                    </div>
                    <Typography sx={scriptStyle} >{(errorScript || touchScript) ? errorScript : ""}</Typography>

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 13, marginBottom: '10px' } : { marginTop: 13 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={() => {
                                if (!scriptProps.isValid) {
                                    scriptProps.handleSubmit();
                                    showErrorNotification("Please Check the values and fill all the mandatory field")
                                }
                            }} variant='contained' type={(scriptProps.isValid) ? "submit" : null} form="campaignInbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            )
        }

        const StepperIC = (props) => {
            let iconColor = props.active || props.completed ? '#3D8BF8' : '#7A7A7A'
            return (
                <div style={{
                    paddingTop: '2.5px', border: `1px dashed ${iconColor}`,
                    borderRadius: '50%',
                    height: '24px',
                    width: '24px',
                    color: iconColor,
                    fontWeight: '400',
                    fontSize: '12px', textAlign: 'center',
                    cursor: "pointer"
                }}>
                    {props.icon}
                </div>

            )
        }

        function getInitData() {
            // if (mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) {
            let localData = localStorage.getItem('InboundForm');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
            // } else {
            //     let localData = localStorage.getItem('OutboundForm');
            //     if (localData) {
            //         return JSON.parse(localData);
            //     } else {
            //         return initData;
            //     }
            // }
        }

        return (
            <div className='oz-campaign-add-form'>
                <Formik
                    // initialValues={JSON.parse(localStorage.getItem('InboundForm')) || initData}
                    validateOnMount
                    // initialValues={(mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.view) ? (JSON.parse(localStorage.getItem('InboundForm')) || initData) : initData}
                    initialValues={getInitData()}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.add) {
                            localStorage.setItem('InboundForm', JSON.stringify(values));
                        }
                        let saveData = structuredClone(values);
                        saveData.dynamicDID = saveData?.dynamicDID?.length !== 0 ? saveData?.dynamicDID?.map(item => item.dynamicDID).join(',') : "";
                        saveData.fallbackDynamicDID = saveData?.fallbackDynamicDID?.length !== 0 ? saveData?.fallbackDynamicDID?.map(item => item.fallbackDynamicDID).join(',') : "";
                        if (saveData.campaignType !== "Chat") {
                            delete saveData.ruleNot;
                            delete saveData.ivrFlowChat;//this is made for ivrFlow in CampaignType === Chat
                            delete saveData.screenPopUrlChat;//this is made for screenpopUrl in CampaignType === Chat
                            delete saveData.screenPopUrlChatInput;

                            if(saveData.campaignType !== 'Chat'){
                                if(saveData.script){
                                    let newParser = "";
                                    const regex = /^[\u0000-\u00FF]*$/;
                                    const isLatin1Text = regex.test(saveData.script);
                                    if (!isLatin1Text) {
                                        showErrorNotification("Script character length cannot exceed 7,000 characters and should not include icons or emojis.");
                                        return null;
                                    }
                                    const parser = new DOMParser();
                                    const parsedHtml = parser.parseFromString(saveData.script, 'text/html');
                                    const textContent = parsedHtml.documentElement.textContent;
                                    // newParser = textContent.trim();
                                    newParser = textContent.trim().replace(/\s+/g, ' ');
                                    if(textContent.length > 7000){
                                        showErrorNotification("Script character length cannot exceed 7,000 characters and should not include icons or emojis.");
                                        return null;
                                    }
                                }
                            }

                            if (saveData.dispositionType.id !== 'None') {
                                if (saveData.sla === null || saveData.sla === '') {
                                    showErrorNotification("Please pass Wraup Time");
                                    return
                                }
                            }

                            if (!saveData?.fallbackDID || saveData?.fallbackDID?.length === 0 || saveData?.fallbackDID === 'None') {
                                saveData.fallbackDID = null;
                            }
                            if (saveData.popUrlAt.id === 'Plugin') {
                                if (saveData.screenPopUrlInBound === null || saveData?.screenPopUrlInBound?.length === 0) {
                                    showErrorNotification("Plugin Name is required.");
                                    return
                                }
                            }
                            if (saveData?.blockNumber?.id === 'None') {
                                saveData.blockNumberGroups = [];
                            }
                            if (saveData?.skills?.length === 0) {
                                showErrorNotification("Please pass at least one valid Skill");
                                return
                            }
                            else {
                                if (mode === ADMIN_FORM_MODES.add) {
                                    if (saveData.popUrlAt.id === 'Plugin') {
                                        if (saveData.screenPopUrlInBound !== null) {
                                            saveData.screenPopUrl = saveData.screenPopUrlInBound?.id;
                                            delete saveData.screenPopUrlInBound;
                                        }
                                    } else {
                                        delete saveData.screenPopUrlInBound; //to delete the screenPopURL Inbound
                                    }
                                    console.log("saveData", saveData)
                                    setNewLoading(true);
                                    addCampaignInboundForm(saveData)
                                }
                                if (mode === ADMIN_FORM_MODES.edit) {
                                    setNewLoading(true);
                                    if (saveData.popUrlAt.id === 'Plugin') {
                                        if (saveData.screenPopUrlInBound !== null) {
                                            saveData.screenPopUrl = saveData.screenPopUrlInBound?.id;
                                            delete saveData.screenPopUrlInBound;
                                        }
                                    } else {
                                        delete saveData.screenPopUrlInBound; //to delete the screenPopURL Inbound
                                    }
                                    let idEdit = props?.data?.campaignId;
                                    if (!saveData.callPrefix || saveData?.callPrefix?.length === 0) {
                                        saveData.callPrefix = null;
                                    }
                                    if (!saveData.fallbackDID || saveData?.fallbackDID?.length === 0) {
                                        saveData.fallbackDID = null;
                                    }
                                    updateCampaignInboundForm(saveData, idEdit)
                                }
                            }
                        }
                        else if (saveData.campaignType === "Chat") {
                            console.log("**********",values);
                            let saveData = structuredClone(values);
                            if (saveData?.skills?.length === 0) {
                                showErrorNotification("Please pass at least one valid Skill");
                                return
                            }
                            if (saveData?.ivrFlowChat?.length === 0 || saveData.ivrFlowChat === null || saveData?.dispositions?.length === 0 || saveData.dispositions === ''
                                || saveData.ruleNot === null || saveData.ruleNot === '') {
                                showErrorNotification("Please pass Mandatory Parameters for Campaign type Chat");
                                return
                            }
                            if (saveData.popUrlAt.id === 'Plugin') {
                                if (saveData.screenPopUrlChat === null || saveData?.screenPopUrlChat?.length === 0) {
                                    showErrorNotification("Plugin Name is required.");
                                    return
                                }
                            }
                            if (mode === ADMIN_FORM_MODES.add) {
                                setNewLoading(true);
                                if (saveData.campaignType === 'Chat') {
                                    if (saveData.popUrlAt.id === 'Plugin') {
                                        saveData.screenPopUrlChat = saveData?.screenPopUrlChat.id;
                                        saveData.screenPopUrl = saveData?.screenPopUrlChat;
                                        delete saveData.screenPopUrlChatInput;
                                    } else {
                                        saveData.screenPopUrl = saveData?.screenPopUrlChatInput;
                                        delete saveData.screenPopUrlChatInput;
                                    }
                                    // saveData.screenPopUrl = saveData.screenPopUrlChat;
                                    saveData.ivrFlow = saveData.ivrFlowChat;
                                    delete saveData.screenPopUrlChat;
                                    delete saveData.screenPopUrlInBound;
                                    delete saveData.ivrFlowChat;
                                    delete saveData.a2aCalling;
                                    // delete saveData.didMasking;
                                    // delete saveData.didMasking;
                                    delete saveData.allowedManual;
                                    delete saveData.dynamicDID;
                                    delete saveData.fallbackDynamicDID
                                    delete saveData.acwNac;
                                    delete saveData.offLineMode;
                                    delete saveData.dndEnable;
                                    delete saveData.transferMusic;
                                    delete saveData.holdMusic;
                                    delete saveData.blockNumberGroups;
                                    delete saveData.blockNumber;
                                    // delete saveData.sla;
                                    // delete saveData.dispositionType;
                                    delete saveData.callPrefix;
                                    delete saveData.fallbackDID;
                                    delete saveData.script;
                                    delete saveData.recOn;
                                    delete saveData.transferSkills;
                                    // delete saveData.isTransferSkill;
                                    addInboundFormDigitalCampaign(saveData);
                                }
                            }

                            if (mode === ADMIN_FORM_MODES.edit) {
                                setNewLoading(true);
                                if (saveData.campaignType === 'Chat') {
                                    let id = saveData.campaignId;
                                    if (saveData.popUrlAt.id === 'Plugin') {
                                        saveData.screenPopUrlChat = saveData?.screenPopUrlChat.id;
                                        saveData.screenPopUrl = saveData?.screenPopUrlChat;
                                        delete saveData.screenPopUrlChatInput;
                                    } else {
                                        saveData.screenPopUrl = saveData?.screenPopUrlChatInput;
                                        delete saveData.screenPopUrlChatInput;
                                    }
                                    // saveData.screenPopUrl = saveData.screenPopUrlChat;
                                    saveData.ivrFlow = saveData.ivrFlowChat;

                                    delete saveData.screenPopUrlChat;
                                    delete saveData.screenPopUrlInBound;
                                    delete saveData.ivrFlowChat;
                                    delete saveData.a2aCalling;
                                    // delete saveData.didMasking;
                                    // delete saveData.didMasking;
                                    delete saveData.allowedManual;
                                    delete saveData.acwNac;
                                    delete saveData.offLineMode;
                                    delete saveData.dynamicDID;
                                    delete saveData.fallbackDynamicDID;
                                    delete saveData.dndEnable;
                                    delete saveData.transferMusic;
                                    delete saveData.holdMusic;
                                    delete saveData.blockNumberGroups;
                                    delete saveData.blockNumber;
                                    // delete saveData.sla;
                                    // delete saveData.dispositionType;
                                    delete saveData.callPrefix;
                                    delete saveData.fallbackDID;
                                    delete saveData.script;
                                    delete saveData.recOn;
                                    delete saveData.transferSkills;
                                    // delete saveData.isTransferSkill;
                                    updateInboundDigitalCampaignForm(saveData, id);
                                }
                            }
                        }
                    }}
                >
                    {
                        props => (
                            <div>
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) && (
                                    <div>
                                        <form onSubmit={props.handleSubmit} id='campaignInbound-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            {loading ?
                                                (
                                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <LoadingScreen />
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div style={{ marginTop: '18px' }}>
                                                        <TabContext value={value}>
                                                            <TabList
                                                                TabIndicatorProps={{
                                                                    style: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
                                                                }}
                                                                value={value}
                                                                onChange={(e, newvalue) => handleChange(e, newvalue, props)}
                                                            >
                                                                <OZCustomToggleButton label="Settings" value="0" sx={{ ...tabButtonStyle, borderRight: 0 }}>Settings</OZCustomToggleButton>
                                                                <OZCustomToggleButton label="General Info" value="1" sx={{ ...tabButtonStyle, borderRight: 0 }}>General Info</OZCustomToggleButton>
                                                                <OZCustomToggleButton label="Skills" value="2" sx={{ ...tabButtonStyle, borderRight: (props?.values?.campaignType === "Chat") ? '1px solid #DBE1E6' : 0 }}>Skills</OZCustomToggleButton>
                                                                {(props?.values?.campaignType !== "Chat") && <OZCustomToggleButton label="Script" value="3" sx={{ ...tabButtonStyle }}>Script</OZCustomToggleButton>}
                                                            </TabList>
                                                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                                                <Divider sx={{ borderColor: "#E6E5E6", width: '60vw', marginTop: '-19px' }} />
                                                            </span>
                                                            <div style={{ width: '60vw', minHeight: '23rem', borderRadius: '0px 0px 8px 8px', background: '#FFFFFF' }}>
                                                                <TabPanel value="0">
                                                                    <BasicSettings props={props} />
                                                                </TabPanel>
                                                                <TabPanel value="1">
                                                                    <GeneralInformation props={props} />
                                                                </TabPanel>
                                                                <TabPanel value="2">
                                                                    <Skills props={props} />
                                                                </TabPanel>
                                                                <TabPanel value="3">
                                                                    <Script props={props} />
                                                                </TabPanel>
                                                            </div>
                                                        </TabContext>
                                                    </div>
                                                )
                                            }
                                            {newLoading && (
                                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                    <LoadingScreen />
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                )}

                                {mode === ADMIN_FORM_MODES.add && (
                                    <div>
                                        <div>
                                            <Box sx={{ borderRadius: '0px 0px 3px 3px', width: '45vw', marginTop: 2 }}>
                                                <Stepper activeStep={activeStep}>
                                                    {steps.map((label, index) => {
                                                        if (props.values.campaignType === "Chat") {
                                                            if (index < 3) {
                                                                return (
                                                                    <Step key={label} completed={completed[index]}>
                                                                        <StepLabel color="inherit" onClick={handleStep(index)} StepIconComponent={StepperIC}>
                                                                            {label}
                                                                        </StepLabel>
                                                                    </Step>
                                                                )
                                                            }
                                                        }
                                                        else {
                                                            return (
                                                                <Step key={label} completed={completed[index]}>
                                                                    <StepLabel color="inherit" onClick={handleStep(index)} StepIconComponent={StepperIC}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </Step>
                                                            )
                                                        }
                                                        return null;
                                                    })}
                                                </Stepper>
                                            </Box>
                                        </div>
                                        <form onSubmit={props.handleSubmit} id='campaignInbound-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px',
                                        }}>
                                            <div style={{ width: '60vw', minHeight: '23rem', borderRadius: '8px', marginTop: 4, backgroundColor: '#FFFFFF' }}>
                                                <React.Fragment >
                                                    {activeStep === 0 && (
                                                        <div style={stepperStyle}>
                                                            <BasicSettings props={props} />
                                                        </div>
                                                    )}
                                                    {activeStep === 1 && (
                                                        <div style={stepperStyle}>
                                                            <GeneralInformation props={props} />
                                                        </div>
                                                    )}
                                                    {activeStep === 2 && (
                                                        <div style={stepperStyle}>
                                                            <Skills props={props} />
                                                        </div>
                                                    )}
                                                    {activeStep === 3 && (
                                                        <div style={stepperStyle} >
                                                            <Script props={props} />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            </div>
                                        </form>
                                        {newLoading && (
                                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                <LoadingScreen />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    }
                </Formik >
            </div >
        );
    }
    return (
        <div onClose={closeCampaignInboundForm}
            // onEdit={onClickEdit}
            mode={mode}
        >
            {mode === ADMIN_FORM_MODES.add && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeCampaignInboundForm}>{'Campaign / ' + mode + ' Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                            }} flexGrow={1} alignSelf='center'>{mode + ' Inbound Campaign'}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} onClick={closeCampaignInboundForm} alignSelf='center'>{'Campaign /  Inbound Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center' >{props.data.campaignName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.edit && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeCampaignInboundForm}>{'Campaign / Inbound Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.campaignName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && <DataForm data={props.data} />}
            {mode === ADMIN_FORM_MODES.add && <DataForm />}
            {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}

        </div>
    );
};

export default CampaignInboundForm;
