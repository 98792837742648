import ListItemButton from '@mui/material/ListItemButton';
import Popper from '@mui/material/Popper';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import './menu.css'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { checkRoles } from '../../../utils/util';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { MenuList, Grow, ClickAwayListener, Paper } from '@mui/material';
import { getUserModules } from '../../../services/api-service';

export const listItems = [
    { name: 'Accounts', status: '', route: '/admin/accounts', menu: 'People' },//people
    { name: 'Users Profile', status: '', route: '/admin/userProfile', menu: 'People' },//people
    { name: 'Sub Users', status: '', route: '/admin/subUsers', menu: 'People' },//people
    { name: 'Plans', status: '', route: '/admin/plans', menu: 'People' },//people
    { name: 'Agents', status: '', route: '/admin/agent', menu: 'People' },//people
    { name: 'Groups', status: '', route: '/admin/groups', menu: 'People' },//people
    { name: 'IVR Flows', status: '', route: '/admin/ivr', menu: 'Configurations' }, //conf
    { name: 'Pre & Post Message', status: '', route: '/admin/addCallWork', menu: 'Configurations' },//conf
    { name: 'Manage Numbers', status: '', route: '/admin/manageNumbers', menu: 'Configurations' },//conf
    { name: 'Locations', status: '', route: '/admin/locations', menu: 'Configurations' },//conf
    { name: 'Skills', status: '', route: '/admin/skills', menu: 'Configurations' },//conf
    { name: 'Pause Reasons', status: '', route: '/admin/pauseReasons', menu: 'Configurations' },//conf
    { name: 'Dispositions', status: '', route: '/admin/dispositions', menu: 'Configurations' },//conf
    { name: 'Mappings', status: '', route: '/admin/Mappings', menu: 'Configurations' },//conf
    { name: 'SIP Location', status: '', route: '/admin/siplocation', menu: 'Configurations' },//conf
    { name: 'Music File', status: '', route: '/admin/music', menu: 'Configurations' },//conf
    { name: 'SIP Number', status: '', route: '/admin/sipnumber', menu: 'Configurations' },//conf
    { name: 'Holidays', status: '', route: '/admin/holidays', menu: 'Configurations' },//conf
    { name: 'Bulk Update', status: '', route: '/admin/bulkUpdate', menu: 'Configurations' },//conf

    //{ name: 'Templates', status: '', route: '/admin/EmailTemplate', menu: 'Configurations' },//conf
    // { name: 'DNC Regulations', status: '', route: '/admin/dnc', menu: 'Configurations' },//conf
    // { name: 'DID Addition', status: '', route: '/admin/didaddition', menu: 'Configurations' },//conf

    { name: 'Integrations', status: '', route: '/admin/integrations', menu: 'Settings' }, //settt
    { name: 'Admin settings', status: '', route: '/admin/AdminSettings', menu: 'Settings' },//settt
    { name: 'Agent settings', status: '', route: '/admin/AgentSettings', menu: 'Settings' },//settt
    { name: 'API Configuration', status: '', route: '/admin/widgetdetails', menu: 'Adminstration' },//Adminstaration

];

function AdminMenu(props) {

    const open = Boolean(props.anchorEl);
    const navigate = useNavigate();
    // const location = useLocation();
    // const [currentRoute, setCurrentRoute] = useState('');


    // useEffect(() => {
    //     if (location.pathname) {
    //         setCurrentRoute(location.pathname);
    //     }
    // }, [location]);

    const [superAdmin, setSuperAdmin]= useState(false);
    useEffect(()=>{
        getUserModules().then(resp => {
            let roles = resp.Data.roles;
            let isFound = false;
            if (roles != null && roles.length !== 0) {
                roles.forEach((role) => {
                    if (role.name === 'ROLE_ADMIN') {
                        isFound = true;
                        return
                    }
                });
            }
            setSuperAdmin(isFound);
        })
    },[])

    const handleClose = () => {
        props.handleClose();
    };

    const [expanded, setExpanded] = useState('People');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0}  {...props} />
    ))(({ theme }) => ({
        border: '0px solid white',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '35px',
            maxHeight: '35px'
        }
    }));


    const AccordionDetails = styled((props) => (
        <MuiAccordionDetails  {...props} />
    ))(({ theme }) => ({
        paddingTop: 0,
        paddingBottom: '10px'
    }));

    const navigateTo = (menuName: any) => {
        // if (currentRoute.includes('users') || currentRoute.includes('agent')) {
        //     navigate('/admin/');
        //     navigate(-1);
        // }
        if (menuName === 'Accounts' || menuName === 'Sub Users' || menuName === 'Plans' || menuName === 'Agents' || menuName === 'Admin settings' || menuName === 'Users Profile') {
            navigate('/admin/');
            navigate(-1);
        }
    }

    const AccordionMenuItems = (props) => {
        let activeStyle = {
            color: "#3D8BF8",
        };
        let menu = props.menu;

        // let filteredMenuValues = listItems.filter((component, index) => {
        //     switch (component.name) {
        //         case "Dispositions":
        //             return authPage(AdminPages.DISPOSITIONS_VIEW);
        //         case "IVR Flows":
        //             return authPage(AdminPages.IVR_FLOWS_VIEW);
        //         case "Pause Reasons":
        //             return authPage(AdminPages.PAUSE_REASONS_VIEW);
        //         case "Skills":
        //             return authPage(AdminPages.SKILLS_VIEW);
        //         case "Integrations":
        //             return authPage(AdminPages.INTEGRATIONS_VIEW);
        //         case "Mappings":
        //             return authPage(AdminPages.MAPPINGS_LIST_VIEW);
        //         case "Locations":
        //             return authPage(AdminPages.LOCATIONS_VIEW);
        //         case "Groups":
        //             return (authPage(AdminPages.GROUPS_VIEW) && (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_USER")));
        //         case "Agents":
        //             return (authPage(AdminPages.AGENTS_VIEW) && (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_USER")));
        //         case "Manage Numbers":
        //             return (authPage(AdminPages.AGENT_NUMBERS_VIEW) || authPage(AdminPages.DAILOUT_NUMBERS_VIEW) || authPage(AdminPages.TRANSFER_NUMBERS_VIEW) || authPage(AdminPages.BLOCKED_NUMBERS_VIEW));
        //         case "Agent settings":
        //             return (authPage(AdminPages.USER_SETTINGS));
        //         case "Users":
        //             return (authPage(AdminPages.USERS_VIEW) && checkRoles('ROLE_ADMIN') && superAdmin);
        //         case "Sub Users":
        //             return (authPage(AdminPages.USERS_VIEW) && localStorage.getItem('parentUser') === 'null' && !superAdmin);
        //         case "Plans":
        //             return (authPage(AdminPages.USERS_VIEW) && checkRoles('ROLE_ADMIN') && superAdmin);
        //         case "SIP Location":
        //             return (authPage(AdminPages.SIPLOCATIONS_VIEW))
        //         case "Music File":
        //             return true;
        //         case "SIP Number":
        //             return (checkRoles("ROLE_ADMIN"));
        //         case "Pre & Post Message":
        //             // return (sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') ? (sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') === true || sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') === "true") ? true : false : false)
        //             return (authPage(AdminPages.PREPOST_VIEW))
        //         default: return true
        //     }
        // })

        let filteredMenuValues = listItems.filter((component, index) => {
            switch (component.name) {
                case "Dispositions":
                    return (newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'View'));
                case "IVR Flows":
                    return (newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'View'));
                case "Pause Reasons":
                    return (newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'View'));
                case "Skills":
                    return (newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'View'));
                case "Mappings":
                    return (newAuthPages(NewAdminPages.MAPPING) || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Full') || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'View'));
                case "Locations":
                    return (newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'View'));
                case "Groups":
                    return ((newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'View')) && (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_USER")));
                case "Agents":
                    return newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'dataUpload') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full');
                case "Manage Numbers":
                    return (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'View') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full'));
                case "Accounts":
                    return (checkRoles('ROLE_ADMIN') && superAdmin);
                case "Sub Users":
                    return ((newAuthPages(NewAdminPages.SUB_USERS) || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'View')) && localStorage.getItem('parentUser') === 'null' && !superAdmin);
                case "Plans":
                    return (checkRoles('ROLE_ADMIN') && superAdmin);
                case "SIP Location":
                    return (newAuthPages(NewAdminPages.SIP_LOCATION) || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'View'))
                case "Music File":
                    return (newAuthPages(NewAdminPages.MUSIC_FILE) || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Full') || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'View'));
                case "SIP Number":
                    return ((newAuthPages(NewAdminPages.SIP_NUMBER) || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'View')) && checkRoles("ROLE_ADMIN"));
                case "Pre & Post Message":
                    return (newAuthPages(NewAdminPages.PREPOST_PAGE) || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'View'))
                case "Users Profile":
                    return ((localStorage.getItem('parentUser') === 'null' || localStorage.getItem('parentUser' === null)) && !checkRoles('ROLE_ADMIN'))
                case "Holidays":
                    return (newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'View'));
                case "Integrations":
                    return (newAuthPages(NewAdminPages.SETTINGS) || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'Full') || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View'));
                case "Admin settings":
                    return (newAuthPages(NewAdminPages.SETTINGS) || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'Full') || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View'));
                case "Agent settings":
                    return (newAuthPages(NewAdminPages.SETTINGS) || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'Full') || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SETTINGS, 'View'));
                case 'Bulk Update':
                    return newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'dataUpload') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full');
                default: return true
            }
        })


        let confList = filteredMenuValues.filter(item => item.menu === props.menu)

        const AccordionSummary = styled((props) => (
            <MuiAccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.7rem' }} />}
                {...props}
            />
        ))(({ theme }) => ({
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
                // color: '#3D8BF8'
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(90deg)',
                color: '#212121'
            },
            '& .MuiAccordionSummary-content': {

                padding: '0px',
                margin: '0px',
                marginLeft: theme.spacing(1),

            },
        }));
        return (
            <Accordion expanded={expanded === menu} onChange={handleChange(menu)} >
                <AccordionSummary
                    aria-label={"Expand" + menu}
                    aria-controls={menu + "-content"}
                    id={menu + "-header"}
                >
                    <Typography sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        paddingBottom: '0px',
                        // color: expanded === menu ? "#3D8BF8" : '#212121'
                    }}>{menu}</Typography>
                </AccordionSummary>
                <AccordionDetails className='admin-menu-list'>
                    {confList.map((item, index) => (
                        <div key={item.name} onClick={() => { localStorage.removeItem("ps"); navigateTo(item.name) }}>
                            <NavLink to={item.route} key={item.name + index}
                                onClick={(event) => handleClose(event)} style={({ isActive }) =>
                                    isActive ? activeStyle : undefined
                                }>
                                <ListItemButton key={item.name} sx={{ height: '30px' }}>
                                    {item.name}
                                </ListItemButton>
                            </NavLink>
                        </div>
                    ))}
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <Popper
            open={open}
            anchorEl={props.anchorEl}
            role={undefined}
            placement="right"
            transition
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'left right' : 'left bottom',
                    }}
                >
                    <Paper
                        sx={{
                            marginLeft: '8px', borderRadius: '8px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
                            border: '1px solid #E6E5E6',
                            maxHeight: '70vh !important',
                            overflowY: 'auto',
                        }}
                        onMouseLeave={handleClose}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                id="adminMenu"
                                aria-labelledby="adminModule"
                            >
                                {listItems.map((item, index) => (

                                    !item.menu && <ListItemButton key={item.name} className='admin-menu-list'>
                                        <NavLink to={item.route} onClick={(event) => handleClose(event)}>
                                            {item.name}
                                        </NavLink>
                                    </ListItemButton>


                                ))}
                                <AccordionMenuItems menu='People' />
                                <AccordionMenuItems menu='Configurations' />
                                <AccordionMenuItems menu='Settings' />
                                {checkRoles("ROLE_ADMIN") && <AccordionMenuItems menu='Adminstration' />}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}


export default AdminMenu;